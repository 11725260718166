import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import TrustPilotBlock from './Trustpilot/TrustpilotBlock';
import intlShape from '../../schemas/intl';
import { brand } from '../../localizations/current-locale';
import devices from '../../styles/Devices';

const Wrapper = styled.div`
  && {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
  }
`;

const TrustPilot = styled(TrustPilotBlock)`
  && {
    width: 175px;

    @media ${devices.downFromMobile} {
      width: 100px;
    }
  }
`;

const SafeSeal = styled.div`
  && {
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    border: 1px solid #f8bc37;
    padding: 0.5rem;
    padding-right: 1rem;
    color: #f8bc37;
    border-radius: 1000px;

    @media ${devices.downFromMobile} {
      font-size: 12px;
    }

    svg{
      margin-right: 0.5rem;
      width: 1.5rem;
      height: 1.5rem;
    }
  }
`;

const isDigifinans = brand === 'digifinans';

const FormBottom = ({
  data,
  intl: { formatMessage },
}) => (
  <Wrapper>
    <TrustPilot data={data}>
      <a href="https://se.trustpilot.com/review/sambla.se" target="_blank" rel="noopener noreferrer">Trustpilot</a>
    </TrustPilot>
    {!isDigifinans
    && (
    <SafeSeal>
      <svg viewBox="0 0 24 24">
        <path fill="currentColor" d="M12,17C10.89,17 10,16.1 10,15C10,13.89 10.89,13 12,13A2,2 0 0,1 14,15A2,2 0 0,1 12,17M18,20V10H6V20H18M18,8A2,2 0 0,1 20,10V20A2,2 0 0,1 18,22H6C4.89,22 4,21.1 4,20V10C4,8.89 4.89,8 6,8H7V6A5,5 0 0,1 12,1A5,5 0 0,1 17,6V8H18M12,3A3,3 0 0,0 9,6V8H15V6A3,3 0 0,0 12,3Z" />
      </svg>
      {formatMessage(data.label)}
    </SafeSeal>
    )}
  </Wrapper>
);

FormBottom.propTypes = {
  data: PropTypes.shape(),
  intl: intlShape.isRequired,
};

FormBottom.defaultProps = {
  data: {},
};

export default injectIntl(FormBottom);
