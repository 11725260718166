const LOAN_LIMITS = {
  FROM: 500,
  UP_TO: 70000,
  YEARS_FROM: 1,
  YEARS_TO: 20,
  UP_TO_HARD_LIMIT: 100000,
  STEP: 500,
  AUTO_ACCEPT_LIMIT: 19000,
};

export default LOAN_LIMITS;
