import textResource from '../../../../utils/text-resources/rahalaitos/FI/messages';

const HOME_TYPE_CATEGORIES = [

  {
    label: textResource.homeTypeCategorieTerraced,
    value: 'terracedHouse',
  },
  {
    label: textResource.homeTypeCategorieDetached,
    value: 'detachedHouse',
  },
  {
    label: textResource.homeTypeCategorieTownHouse,
    value: 'townHouse',
  },
  {
    label: textResource.homeTypeCategorieBlock,
    value: 'blockOfFlats',
  },
  {
    label: textResource.homeTypeOther,
    value: 'other',
  },
];

export default HOME_TYPE_CATEGORIES;
