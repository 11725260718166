// external
import { region } from '../localizations/current-locale';
import { dynamicBrand } from '../utils/brand';

// internal
import Config from '../utils/config';
import { valid, invalid } from '../utils/validators/resolutions';

let messages;
import(`../utils/text-resources/${dynamicBrand()}/${region}/messages`).then((m) => {
  messages = m.default;
});

const validateRepaymentTime = (input = '') => {
  const config = Config.getValues();

  if (!/^(\d+)$/.test(input)) {
    return invalid(messages.repaymentTimeUseOnlyNumbers);
  }

  const value = parseInt(input, 10);
  const loanMinYears = config.RESTRICTIONS.LOAN_MIN_YEARS;
  const loanMaxYears = config.RESTRICTIONS.LOAN_MAX_YEARS;

  if (value < loanMinYears) {
    return invalid(messages.tooShortRepaymentTime, { loanMinYears });
  }

  if (value > loanMaxYears) {
    return invalid(messages.tooLongRepaymentTime, { loanMaxYears });
  }

  return valid();
};

export default validateRepaymentTime;
