import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import replaceHostName from '../../utils/replace-host-name';
import { ImageProp, SVGImageProp } from '../../../models/Section';

const processSvgImageUrl = (svgImageUrl) => (
  process.env.NODE_ENV === 'development'
    ? svgImageUrl
    : replaceHostName(svgImageUrl, process.env.GATSBY_FRONTEND_BASE_URL)
);

const ImageContent = ({
  image, src, alt, className, style,
}) => (
  <>
    <GatsbyImage
      image={image}
      alt={alt}
      className={className}
      style={style}
    />
    <noscript>
      <img src={src} alt={alt} />
    </noscript>
  </>
);

const ImageBlock = ({
  imageObject,
  className,
  svgImageProps,
  sources,
}) => {
  let svgImage;
  let image = null;
  let altText;

  if (imageObject) {
    svgImage = imageObject.svgImage;
    image = imageObject.image;
    altText = imageObject.altText;
  }

  const imageData = image !== null ? image.node.gatsbyImage : sources;

  const gatsbyImageData = imageData && getImage(imageData);

  const svgImageUrl = svgImage?.node?.sourceUrl && processSvgImageUrl(svgImage.node.sourceUrl);

  const altAttribute = altText || '';

  return (
    (gatsbyImageData || svgImageUrl) && (
      <div className={className}>
        {gatsbyImageData ? (
          <ImageContent
            image={gatsbyImageData}
            alt={altAttribute}
            className={className}
            style={svgImageProps}
          />
        ) : (
          <img
            src={svgImageUrl}
            alt={altAttribute}
            className={className}
            style={svgImageProps}
          />
        )}
      </div>
    )
  );
};

ImageContent.propTypes = {
  image: PropTypes.shape().isRequired,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.shape(),
};

ImageContent.defaultProps = {
  className: '',
  style: {},
};

ImageBlock.propTypes = {
  className: PropTypes.string,
  imageObject: PropTypes.shape({
    altText: PropTypes.string,
    image: ImageProp,
    svgImage: SVGImageProp,
  }),
  svgImageProps: PropTypes.shape({
    url: PropTypes.shape({
      source_url: PropTypes.string,
    }),
  }),
  sources: PropTypes.shape(),
};

ImageBlock.defaultProps = {
  className: '',
  imageObject: null,
  svgImageProps: {},
  sources: null,
};

export default ImageBlock;

export const imageBlockQuery = graphql`
  fragment imageBlockFieldsWithoutImage on Image {
    altText
    image {
      node {
        sourceUrl
      }
    }
    svgImage {
      node {
        sourceUrl
      }
    }
  }
`;
