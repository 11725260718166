/* eslint-disable no-mixed-operators */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import styled from 'styled-components';
import intlShape from '../../../schemas/intl';
import ValidatedForm from '../../../../components/molecules/ValidatedForm';
import style from './style';
import LanguageContext from '../../../../context/language';

const LabelWrapper = styled.label`
  && {
    margin: ${(props) => (props.lead ? '0.5em 0' : style.creditCheckWrapperMargin)};
    display: flex;
    flex-direction: column;
  }
`;

const Text = styled.p`
  && {
    margin-top: 0;
    cursor: pointer;
    font-size: ${style.checkMarkTextFontSize};
    font-weight: var(--has-no-font-weight);
    margin-bottom: 0.5rem;
  }
`;

const Link = styled.a`
  && {
    cursor: pointer;
  }
`;

const AcceptAgreement = ({
  validateOnRegister,
  data,
  showErrors,
  intl: { formatMessage },
}) => {
  const {
    linkUserAgreement, linkDataPolicy, linkInText, links,
  } = data;

  const { lang } = useContext(LanguageContext);

  const plusLink = (language) => {
    switch (language) {
      case 'en':
        return linkUserAgreement.EN;
      case 'fi':
        return linkUserAgreement.FI;
      default:
        return linkUserAgreement.FI;
    }
  };

  const plusLinkPolicy = (language) => {
    switch (language) {
      case 'en':
        return linkDataPolicy.EN;
      case 'fi':
        return linkDataPolicy.FI;
      default:
        return linkDataPolicy.FI;
    }
  };

  return (
    <LabelWrapper lead={data.lead}>
      <ValidatedForm.Checkbox
        data={data}
        type="checkbox"
        checked
        showErrors={showErrors}
        validateOnRegister={validateOnRegister}
      >
        {linkInText ? (
          <Text>
            {formatMessage(data.text, {
              a: (chunks) => (
                <Link
                  href={plusLink(lang)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {chunks}
                </Link>
              ),
              y: (chunks) => (
                <Link
                  href={plusLinkPolicy(lang)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {chunks}
                </Link>
              ),
            })}
          </Text>
        ) : (
          <Text>
            {formatMessage(data.text)}
            <Link
              href={links}
              target="_blank"
              rel="noopener noreferrer"
            >
              {formatMessage(data.linkText)}
            </Link>
          </Text>
        )}
      </ValidatedForm.Checkbox>
    </LabelWrapper>
  );
};

AcceptAgreement.propTypes = {
  data: PropTypes.shape(),
  intl: intlShape.isRequired,
  validateOnRegister: PropTypes.bool,
  showErrors: PropTypes.bool,
};

AcceptAgreement.defaultProps = {
  validateOnRegister: false,
  data: {},
  showErrors: false,
};

export default injectIntl(AcceptAgreement);
