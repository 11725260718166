import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import {
  change,
} from 'redux-form';
import axios from 'axios';

import { brand } from '../../../js/localizations/current-locale';
import sizes from '../../../js/styles/Sizes';
import smartEmailSuggestion from '../../../js/utils/smart-email-suggestion';
import ValidatedFormTextInput from './ValidatedFormTextInput';
import validateEmail from '../../../js/validators/email';
import { sendEvent } from '../../../js/utils/gtm';

const baseUrl = process.env.REACT_APP_API_ROOT_PAPI || process.env.REACT_APP_API_ROOT;

const SuggestionHint = styled.div`
  && {
    background: var(--light-grey);
    border-radius: ${sizes.defaultBorderRadius};
    padding: 0.5rem;
    transform: translateY(-1rem);
    display: flex;
    align-items: center;
    justify-content: space-between;

    button{
      background: transparent;
      outline: none;
      border: none;
      cursor: pointer;
    }
  }
`;

const InvalidEmailWarning = styled.div`
  && {
    background: var(--warning-bg);
    border-radius: ${sizes.defaultBorderRadius};
    padding: 0.5rem;
    transform: translateY(-1rem);
    display: flex;
    align-items: center;
    justify-content: space-between;

    button{
      background: transparent;
      outline: none;
      border: none;
      cursor: pointer;
    }
  }
`;

const invalidEmailListStatuses = [
  'risky',
  'undeliverable',
  'unknown',
];

const ValidatedFormEmailInput = ({
  changeField,
  descriptionText,
  invalidEmailWarningMessage,
  data,
  ...rest
}) => {
  const inputRef = useRef();
  const [emailSuggestion, setEmailSuggestion] = useState(null);
  const [showEmailWarningMessage, setShowEmailWarningMessage] = useState(null);
  const [mostRecentValidEmail, setMostRecentValidEmail] = useState(null);

  const [hasSentGAEvent, setHasSentGAEvent] = useState(false);
  const [currentValidationCount, setCurrentValidationCount] = useState(0);

  const emailZeroBounceStatus = useSelector(
    (state) => state.form.application.values.emailZeroBounceStatus);
  const dispatch = useDispatch();
  const changeApplicationField = (...args) => change('application', ...args);

  const handleInput = (event) => {
    const suggestion = smartEmailSuggestion(rest.validate, event?.target?.value);
    setShowEmailWarningMessage(false);
    // check that only somewhat valid emails are suggested
    if (suggestion && suggestion.test !== event?.target?.value) {
      // eslint-disable-next-line no-useless-escape
      if (/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(suggestion.full)) {
        setEmailSuggestion(suggestion);
      }
    } else {
      setEmailSuggestion(null);
    }
  };

  const dismissSuggestion = () => {
    setEmailSuggestion(null);
  };

  const postValidateEmail = (manualInput = null) => {
    let isDisabledPath = false;
    if (brand !== 'advisa') {
      isDisabledPath = true;
    }
    window.location.href.split('/').forEach((subPaths) => {
      ['preappsms', 'preappemail'].forEach((disabledPath) => {
        if (subPaths.includes(disabledPath)) {
          isDisabledPath = true;
        }
      });
    });
    const email = manualInput || inputRef.current.value;
    if (!isDisabledPath
      && !validateEmail(email)
      && currentValidationCount < 2
    ) {
      dispatch(changeApplicationField('emailZeroBounceStatus', 'LOADING'));
      axios
        .post(`${baseUrl}/contacts/validate/email`, { email })
        .then((res) => {
          setCurrentValidationCount(currentValidationCount + 1);
          const { status } = res.data.data;

          if (invalidEmailListStatuses.includes(status)) {
            dispatch(changeApplicationField('emailZeroBounceStatus', 'INVALID'));
            setShowEmailWarningMessage(true);
            if (!hasSentGAEvent) {
              sendEvent({
                event: 'display_warning',
                eventCategory: 'Warning',
                eventAction: 'form-interaction',
                eventLabel: 'Bouncer email warning',
              });
              setHasSentGAEvent(true);
            }
          } else {
            dispatch(changeApplicationField('emailZeroBounceStatus', 'VALID'));
            setMostRecentValidEmail(email);
            setShowEmailWarningMessage(false);
          }
        })
        .catch(() => {
          dispatch(changeApplicationField('emailZeroBounceStatus', 'VALID'));
        });
    }
  };

  const handleBlur = () => {
    if (emailZeroBounceStatus === 'READY' || emailZeroBounceStatus === 'INVALID') {
      postValidateEmail();
    } else if (emailZeroBounceStatus === 'VALID') {
      if (
        inputRef.current.value !== mostRecentValidEmail
        && !validateEmail(inputRef.current.value)
      ) {
        dispatch(changeApplicationField('emailZeroBounceStatus', 'INVALID'));
        postValidateEmail();
      }
    }
  };

  const handleSuggestionClick = () => {
    setShowEmailWarningMessage(false);
    if (!validateEmail(emailSuggestion?.full)) {
      postValidateEmail(emailSuggestion?.full);
    }
    dispatch(changeField(inputRef.current.name, emailSuggestion?.full));
    setEmailSuggestion(null);
  };

  return (
    <ValidatedFormTextInput
      {...rest}
      type="email"
      tooltip={data.tooltip}
      inputRef={inputRef}
      onChange={handleInput}
      normalize={(value) => value && value.toLowerCase()}
      onBlur={handleBlur}
      data={data}
    >

      { emailSuggestion
        && (
          <SuggestionHint>
            <button
              onClick={handleSuggestionClick}
              type="button"
            >
              {descriptionText
                && (
                <i>{`${descriptionText} `}</i>
                )}
              <strong>
                {`${emailSuggestion.address}`}
                <wbr />
                {`@${emailSuggestion.domain}`}
              </strong>
              {' '}
              ?
            </button>
            <button
              type="button"
              onClick={dismissSuggestion}
            >
              ✕
            </button>
          </SuggestionHint>
        )}

      { showEmailWarningMessage
        && (
          <InvalidEmailWarning>
            {invalidEmailWarningMessage}
          </InvalidEmailWarning>
        )}
    </ValidatedFormTextInput>
  );
};

ValidatedFormEmailInput.propTypes = {
  changeField: PropTypes.func,
  descriptionText: PropTypes.string,
  validate: PropTypes.func,
  data: PropTypes.shape(),
  invalidEmailWarningMessage: PropTypes.string,
};

ValidatedFormEmailInput.defaultProps = {
  changeField: () => {},
  descriptionText: '',
  validate: () => {},
  data: {},
  invalidEmailWarningMessage: '',
};

export default ValidatedFormEmailInput;
