/* eslint-disable */
import { SET_CONTEXT_VALUE } from "../actions/api/set-context-value";

export const initialState = {
  application: {
    values: {},
  },
};

const reducer = (state = initialState, action) => {
  const { payload: { parent, value } = {} } = action;

  switch (action.type) {
    case SET_CONTEXT_VALUE:
      const tempState = JSON.parse(JSON.stringify(state));

      parent.length === 1
        ? (tempState.values[parent[0]] = value)
        : (tempState.values[parent[0]][parent[1]] = value);

      return {
        ...tempState,
      };
    default:
      return state;
  }
};

export default reducer;
