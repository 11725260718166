import { configureStore } from '@reduxjs/toolkit';

import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { reducer as formReducer } from 'redux-form';
import genericValues from './reducers/context-form-values';
import authReducer from './reducers/auth';
import { apiCall } from './reducers/api-call';
import combinePlugins from './reducers/plugins/combine-plugins';
import dirtyState from './reducers/plugins/dirty-state';
import formState from './reducers/plugins/form-state';
import formSubmitErrors from './reducers/form-submit-errors';
import genericFormValues from './reducers/generic-form-values';

const store = configureStore({
  reducer: {
    form: formReducer.plugin({
      application: combinePlugins([
        dirtyState,
        formState,
        formSubmitErrors,
        genericValues,
      ]),
      genericFormValues,
    }),
    authBankId: authReducer,
    [apiCall.reducerPath]: apiCall.reducer,
  },
  middleware: (gDM) => {
    const defaults = gDM({
      immutableCheck: false,
      serializableCheck: false,
    });
    return defaults.concat(apiCall.middleware);
  },
});

setupListeners(store.dispatch);

export default store;
