/* eslint-disable react/prop-types */
/**
 * A wrapper around a simple <button type='submit'> with access to form
 * validation data. Should be used as <ValidatedForm.SubmitButton>, not imported
 * directly.
 *
 * Usage:
 * ```js
 * <ValidatedForm ...>
 *   <ValidatedForm.SubmitButton
 *     alwaysEnabled={true}
 *   >...</ValidatedForm.SubmitButton>
 * </ValidatedForm>
 * ```
 */

import React from 'react';
import PropTypes from 'prop-types';

const ValidatedFormSubmitButton = ({
  children,
  alwaysEnabled,
  formValid,
  ...rest
}) => (
  <button
    type="submit"
    disabled={!alwaysEnabled && !formValid}
    {...rest}
  >
    {children}
  </button>
);

ValidatedFormSubmitButton.propTypes = {
  alwaysEnabled: PropTypes.bool,

  /** Internal -- should not be modified. */
  formValid: PropTypes.bool,
};

ValidatedFormSubmitButton.defaultProps = {
  alwaysEnabled: false,
  formValid: false,
};

export default ValidatedFormSubmitButton;
