import React from 'react';
import PropTypes from 'prop-types';

import ValidatedForm from '../../../components/molecules/ValidatedForm';

const EmploymentSinceInputYear = ({
  validateOnRegister,
  whitelist,
  data,
  showErrors,
}) => (
  <ValidatedForm.TextInput
    data={data}
    placeholder={data.placeholder}
    type="tel"
    validateOnRegister={validateOnRegister}
    whitelist={whitelist}
    showErrors={showErrors}
  />
);

EmploymentSinceInputYear.propTypes = {
  validateOnRegister: PropTypes.bool,
  whitelist: PropTypes.bool,
  data: PropTypes.shape().isRequired,
  showErrors: PropTypes.bool,
};

EmploymentSinceInputYear.defaultProps = {
  validateOnRegister: false,
  whitelist: false,
  showErrors: false,
};

export default EmploymentSinceInputYear;
