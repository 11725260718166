/* eslint "import/prefer-default-export": "off" */

const MIME_TYPE_REGEX = /^[-\w]+\/[.+-\w]+$/;

export const getMimeType = (contentTypeHeader) => {
  if (!contentTypeHeader) {
    return null;
  }

  const [mimeType] = contentTypeHeader.split(';');
  if (mimeType && MIME_TYPE_REGEX.test(mimeType)) {
    return mimeType.trim();
  }
  return null;
};
