import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import intlShape from '../../schemas/intl';

const FormH2 = styled.p`
  && {
    color: inherit;
    padding-top: 2rem;
    margin-bottom: 0.43em;
    font-size: 1.5em;
    font-family: inherit;
    font-weight: 600;
    line-height: 1.2;
  }
`;

const CoAppFormH2 = styled.p`
  && {
    color: inherit;
    padding-top: 2rem;
    margin-top: 1.23em;
    margin-bottom: 0.43em;
    font-size: 1.5rem;
    font-family: inherit;
    font-weight: 600;
    line-height: 1.2;
    margin-left: 1rem;
  }
`;

const FormHeadline = ({
  intl: { formatMessage },
  data,
}) => (
  data.coApp ? (
    <CoAppFormH2>{formatMessage(data.label)}</CoAppFormH2>
  )
    : (<FormH2>{formatMessage(data.label)}</FormH2>)
);

FormHeadline.propTypes = {
  data: PropTypes.shape(),
  intl: intlShape.isRequired,
};

FormHeadline.defaultProps = {
  data: {},
};

export default injectIntl(FormHeadline);
