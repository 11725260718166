// external
import { nationalId } from '@advisa/common-validators';
import startOfDay from 'date-fns/startOfDay';
import getYear from 'date-fns/getYear';
import isValid from 'date-fns/isValid';
import differenceInYears from 'date-fns/differenceInYears';
import { region } from '../localizations/current-locale';
import Config from '../utils/config';
import { dynamicBrand } from '../utils/brand';

// internal
import { valid, invalid } from '../utils/validators/resolutions';

let messages;
import(`../utils/text-resources/${dynamicBrand()}/${region}/messages`).then((m) => {
  messages = m.default;
});

const luhn = (input) => {
  const digits = input.split('').map((it) => parseInt(it, 10));

  const checksum = digits.reverse().reduce((accumulator, value, index) => {
    let product;
    const multiplier = 1 + (index % 2);
    product = multiplier * value;
    if (product > 9) {
      product -= 9;
    }
    return accumulator + product;
  });

  return checksum % 10;
};

const validateNationalId = (input = '', ...props) => {
  const config = Config.getValues();

  if (!/\S+/.test(input || '')) {
    return ((props[2] === 'applicant.nationalId' || props[0] === true)
      ? invalid(messages.nationalIdRequired)
      : invalid(messages.coApplicantNationalIdrequired));
  }

  const today = startOfDay(Date.now());
  const matches = /^(\d\d)?(\d\d)(\d\d)(\d\d)([-+])?(\d\d\d)(\d)$/.exec(input);

  if (typeof matches === 'undefined' || matches === null) {
    return ((props[2] === 'applicant.nationalId')
      ? invalid(messages.nationalIdWrongFormat)
      : invalid(messages.coApplicantNationalIdWrongFormat));
  }

  let century = matches[1];
  const yy = matches[2];
  const mm = matches[3];
  const dd = matches[4];
  const separator = matches[5];
  const serial = matches[6];
  const checkDigit = matches[7];

  if (typeof century === 'undefined' || century === null) {
    const todayCentury = Math.floor(getYear(today) / 100);
    const isOverHundredYearsOld = separator === '+';

    if (getYear(today) < `${todayCentury}${yy}`) {
      century = todayCentury - 1;
    } else {
      century = todayCentury;
    }

    if (isOverHundredYearsOld) {
      century -= 1;
    }
  }

  const dateOfBirthString = `${century}${yy}-${mm}-${dd}`;
  const dateOfBirth = new Date(dateOfBirthString);

  if (!isValid(dateOfBirth)) {
    return ((props[2] === 'applicant.nationalId')
      ? invalid(messages.nationalIdWrongDate, { dateOfBirthString })
      : invalid(messages.coApplicantNationalIdWrongDate, { dateOfBirthString }));
  }

  const age = differenceInYears(today, dateOfBirth);

  if (age < 0) {
    return ((props[2] === 'applicant.nationalId')
      ? invalid(messages.nationalIdDateInTheFuture, { dateOfBirthString })
      : invalid(messages.coApplicantNationalIdDateInTheFuture, { dateOfBirthString }));
  }

  const minAge = config.RESTRICTIONS.MIN_AGE;
  const maxAge = config.RESTRICTIONS.MAX_AGE;

  if (age < minAge) {
    return invalid(messages.nationalIdTooYoung, { minAge });
  }

  if (age > maxAge) {
    return invalid(messages.nationalIdTooOld, { maxAge });
  }

  if (luhn(`${yy}${mm}${dd}${serial}${checkDigit}`) !== 0) {
    return ((props[2] === 'applicant.nationalId')
      ? invalid(messages.nationalIdInvalid)
      : invalid(messages.coApplicantNationalIdInvalid));
  }
  // Common validation check
  const validatedId = nationalId.parse(input, region.toLowerCase());
  if (validatedId.valid === 'invalid') {
    return invalid(messages.invalidNationalId);
  }

  return valid();
};

export default validateNationalId;
