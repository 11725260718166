import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import devices from '../../../styles/Devices';
import intlShape from '../../../schemas/intl';
import ValidatedForm from '../../../../components/molecules/ValidatedForm';
import stripLeadingZero from '../../../utils/strip-leading-zero';
import stripNonNumeric from '../../../utils/strip-non-numeric';
import style from './style';

const StoreValueWrapper = styled.p`
  && {
    display: flex;
    align-items: baseline;
    background: ${(props) => props.data.background};
    border-radius: ${(props) => props.data.borderRadius};
    font-weight: ${(props) => props.data.fontWeight};
    justify-content: ${(props) => props.data.position};
    padding: ${(props) => props.data.padding};
    margin: ${(props) => props.data.margin};
    color: ${(props) => props.data.color};
    font-size: ${(props) => props.data.fontSize};
    line-height: 1.3rem;
    width: 100%;

    @media ${devices.downFromMobile} {
      flex-direction: ${(props) => (props.data.collapseOnMobile ? 'column' : 'row')};
      align-items: ${(props) => (props.data.collapseOnMobile ? 'center' : 'baseline')};

      p {
        font-size: ${(props) => (props.data.collapseOnMobile ? '1rem' : '1.3rem')};
      }
    }
  }
`;

const DisplayWrapper = styled.div`
  && {
    display: flex;
    align-items: inherit;

    input{
      max-width: ${style.storeValueMaxWidth};
      text-align: right;
      width: 100%;
      background: transparent;
    }
  }
`;

const FormLabel = styled.p`
  && {
    font-size: inherit;
    color: inherit;
  }
`;

const StoreValueDisplay = ({
  intl: { formatMessage },
  data,
}) => {
  if (data.enabledPaths && typeof window !== 'undefined') {
    const path = window.location.pathname.split('/');
    const isPathIncluded = data.enabledPaths.some((disabledPath) => path.includes(disabledPath));

    if (!isPathIncluded) {
      return null;
    }
  }
  if (data.noLabel) {
    return (
      <div>
        <ValidatedForm.TextInput
          storeValueDisplay
          data={data.fieldDisplay}
          validate={data.validate}
          maxValue={data.fieldDisplay.maxValue}
          validateOnRegister={data.validateOnRegister}
          parse={data.fieldDisplay.type === 'tel' ? (inputValue) => stripLeadingZero(stripNonNumeric(inputValue)) : null}
          suffix={data.suffix}
        />
        {' '}
        {data.suffix}
      </div>
    );
  }

  return (
    <StoreValueWrapper
      data={data}
    >
      <FormLabel>
        {data.label && (
          <div>
            {formatMessage(data.label)}
            {'\u00A0'}
          </div>
        )}
      </FormLabel>

      <DisplayWrapper>
        {data.fieldDisplay
          && (
          <ValidatedForm.TextInput
            storeValueDisplay
            data={data.fieldDisplay}
            validate={data.validate}
            maxValue={data.fieldDisplay.maxValue}
            validateOnRegister={data.validateOnRegister}
            parse={data.fieldDisplay.type === 'tel' ? (inputValue) => stripLeadingZero(stripNonNumeric(inputValue)) : null}
          />
          )}
        {' '}
        {data.suffix}
      </DisplayWrapper>
    </StoreValueWrapper>
  );
};

StoreValueDisplay.propTypes = {
  data: PropTypes.shape(),
  intl: intlShape.isRequired,
};

StoreValueDisplay.defaultProps = {
  data: {},
};

export default injectIntl(StoreValueDisplay);
