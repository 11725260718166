import { region } from '../localizations/current-locale';
import { dynamicBrand } from '../utils/brand';
import { valid, invalid } from '../utils/validators/resolutions';
import stripDelimiter from '../utils/strip-delimiter-from-currency';

let messages;
import(`../utils/text-resources/${dynamicBrand()}/${region}/messages`).then((m) => {
  messages = m.default;
});

const removeLeadingAndTrailingZeros = (stringValue) => {
  let [integer, decimal] = stringValue.split('.');
  integer = integer.replace(/^0+/, '') || '0';
  if (decimal) {
    decimal = decimal.replace(/0+$/, '');
  }
  return decimal ? [integer, decimal].join('.') : integer;
};

const validateNumericality = ({
  required,
  min,
  max,
  isCurrency,
} = {}) => (value = '') => {
  const strippedValue = isCurrency ? stripDelimiter(value) : (value).trim();
  const parsedValue = parseFloat(strippedValue, 10);

  if (required && !/\S+/.test(strippedValue)) {
    return invalid(messages.mortgageValidateNumericalityIsRequired);
  }
  if (strippedValue !== '' && removeLeadingAndTrailingZeros(strippedValue) !== String(parsedValue)) {
    return invalid(messages.mortgageValidateNumericalityOnlyNumbers);
  }
  if (!isCurrency && strippedValue.search(' ') !== -1) {
    return invalid(messages.mortgageValidateNumericalityOnlyNumbers);
  }
  if (typeof min === 'number' && parsedValue < min) {
    return invalid(messages.mortgageValidateNumericalityMustBeAbove, { min });
  }
  if ([null, undefined, false].includes(min) && parsedValue < 0) {
    return invalid(messages.mortgageValidateNumericalityMustBeAbove, { min: 0 });
  }
  if (typeof max === 'number' && parsedValue > max) {
    return invalid(messages.mortgageValidateNumericalityMustBeBelow, { max });
  }

  return valid();
};

export default validateNumericality;
