// external
import isValid from 'date-fns/isValid';
import isAfter from 'date-fns/isAfter';

// internal
import { region } from '../../localizations/current-locale';
import { dynamicBrand } from '../../utils/brand';
import { valid, invalid } from '../../utils/validators/resolutions';

let messages;

import(`../../utils/text-resources/${dynamicBrand()}/${region}/messages`).then((m) => {
  messages = m.default;
});

const validateMoveInDate = (yearInput = '', ...props) => {
  if (!/\S+/.test(yearInput || '')) {
    return (props[2] === 'applicant.moveInDate'
      ? invalid(messages.moveInDateRequiredYear)
      : invalid(messages.coAppMoveInDateYear));
  }

  if (!/^(\d+)$/.test(yearInput)) {
    return invalid(messages.moveInDateOnlyNumbers);
  }

  const year = parseInt(yearInput, 10);

  if (year < 1000) {
    return invalid(messages.moveInDateFourDigits);
  }

  if (year < 1900) {
    return (props[2] === 'applicant.moveInDate'
      ? invalid(messages.moveInDateTooFarBack)
      : invalid(messages.coApplicantMoveInDateTooFarBack));
  }

  const moveIndate = new Date(year, 0);

  if (!isValid(moveIndate)) {
    return invalid(messages.moveInDateFourDigits);
  }

  if (isAfter(moveIndate, Date.now())) {
    return (props[2] === 'applicant.moveInDate'
      ? invalid(messages.moveInDateInTheFuture)
      : invalid(messages.coAppMoveInDateInTheFuture));
  }
  return valid();
};

export default validateMoveInDate;
