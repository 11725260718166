import Poller from '../../../../utils/PollerAsync';
import BANK_ID_RESPONSES from '../../../../constants/BankID';
import { MINIMUM_BIDDING_POLLING_INTERVAL } from '../../../../constants/Time';
import SIGNING_ERRORS from '../../../../constants/ErrorsConstants';
import { loginSigningStatus, startLoginSigning, userLoginCancel } from '../../../../utils/API';
import {
  SIGN_LOGIN,
  SIGN_LOGIN_SUCCESS,
  SIGN_LOGIN_FAILED,
  SIGN_LOGIN_STOP,
  SIGN_LOGIN_CANCEL,
  SIGN_SET_QR_DATA,
} from '../../../reducers/auth';

let poller;

const loginInProgress = (
  dispatch,
  response,
) => dispatch(SIGN_LOGIN({
  autoStartToken: response.autoStartToken,
  cancelToken: response.token,
}));

const loginDone = (token, dispatch) => dispatch(SIGN_LOGIN_SUCCESS(token));

const loginFailed = (error = 'UnknownError', dispatch) => (
  dispatch(SIGN_LOGIN_FAILED({ error }))
);

const setQrCode = (qrData, dispatch) => (
  dispatch(SIGN_SET_QR_DATA({ qrData }))
);

export const loginStop = (dispatch, cancelToken) => {
  if (poller) {
    poller.stopPolling();
  }
  userLoginCancel(cancelToken)
    .then(() => dispatch(SIGN_LOGIN_CANCEL()))
    .catch(() => dispatch(SIGN_LOGIN_STOP()));
};

const startPollingForStatus = (data, dispatch) => {
  if (!data || !data.token) {
    throw new Error('No signing token to fetch status on!');
  }

  poller = new Poller(
    () => loginSigningStatus(data.token)
      .then((response = {}) => {
        if (!response) {
          return;
        }
        setQrCode(response.qrData, dispatch);
        if (response.status === BANK_ID_RESPONSES.FAILED) {
          poller.stopPolling();
          loginFailed(response.message || response.status, dispatch);
          // eslint-disable-next-line consistent-return
          return response;
        } if (response.status === BANK_ID_RESPONSES.COMPLETED) {
          poller.stopPolling();
          loginDone(response.token, dispatch);
          // eslint-disable-next-line consistent-return
          return response;
        } if (response.status === BANK_ID_RESPONSES.NOT_FOUND) {
          throw new Error('LoginNotFoundError');
        }
      })
      .catch((err) => {
        poller.stopPolling();
        const errorCode = err.status === 404
          ? SIGNING_ERRORS.USER_NOT_FOUND
          : SIGNING_ERRORS.STATUS_ERROR;
        loginFailed(errorCode, dispatch);
      }),
    MINIMUM_BIDDING_POLLING_INTERVAL,
  );

  poller.startPolling();

  if (data.autoStartToken) {
    return data.autoStartToken;
  }
  return null;
};

export const loginSign = async ({ input, dispatch }) => {
  try {
    const response = await startLoginSigning(input);
    loginInProgress(dispatch, response);
    return startPollingForStatus(response, dispatch);
  } catch {
    return loginFailed(SIGNING_ERRORS.START_ERROR, dispatch);
  }
};
