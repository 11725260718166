import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import SIGNING_ERRORS from '../../../constants/ErrorsConstants';
import ErrorMessage from '../../../../components/molecules/ErrorMessage';
import intlShape from '../../../schemas/intl';
import devices from '../../../styles/Devices'; // eslint-disable-line import/no-relative-packages
import style from './style';

const FormattedTextBlock = styled.div`
  && {
    background-color: ${(props) => (props.transparent ? 'transparent' : style.backgroundColor)};
    color: ${(props) => (props.transparent ? 'var(--link-color)' : style.color)};
    padding: ${style.padding};
    text-align: ${(props) => (props.center ? 'center' : 'left')};
    font-weight: ${(props) => (props.header ? 'bold' : 'normal')};
    margin-bottom: ${(props) => (props.margin ? '1rem' : '0')};
    border-radius: ${style.borderRadius};
    border: ${style.border};
    font-size: ${style.fontSize};
    padding: ${style.padding};
    display: ${(props) => (props.isHidden ? 'none' : 'block')};

    ${(props) => props.isHidden && css`
      display: none !important;
    `}

    ${(props) => !!props.backgroundColor && css`
      border: 1px solid ${props.backgroundColor};
    `}

    ${(props) => props.type === 'error' && css`
      border-color: var(--warning-bg);
      text-align: center;

      p {
        font-size: 14px !important;
        font-weight: 600;
      }
    `}

    @media ${devices.downFromSmallTablet} {
      text-align: ${style.mobileTextAlign};
    }

   ${(props) => props.isPCR && css`
      text-align: center;
      font-size: 0.8rem;
      font-family: inherit;
      color: inherit;
    `}
  }
`;

const FormTextBlock = ({
  data: {
    transparent,
    margin,
    header,
    center,
    special,
    PCR,
    text,
    type = 'info',
  },
  intl: { formatMessage },
}) => {
  const error = useSelector((state) => state.authBankId.error);
  const [isHidden, setIsHidden] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState('');

  useEffect(() => {
    switch (type) {
      case 'error':
        setBackgroundColor('#ff6700');
        setIsHidden(true);
        break;
      default:
        setBackgroundColor('');
        break;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (error) {
      const {
        USER_NOT_FOUND,
        STATUS_ERROR,
      } = SIGNING_ERRORS;
      if ([
        USER_NOT_FOUND, STATUS_ERROR,
      ].includes(error.toUpperCase())) {
        setIsHidden(false);
      } else {
        setIsHidden(true);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return (
    <FormattedTextBlock
      transparent={transparent}
      backgroundColor={backgroundColor}
      margin={margin}
      header={header}
      center={center}
      special={special}
      isHidden={isHidden}
      type={type}
      isPCR={PCR}
    >
      {type !== 'error'
        ? formatMessage(text) : <ErrorMessage error={error} formatMessage={formatMessage} />}
    </FormattedTextBlock>
  );
};

FormTextBlock.propTypes = {
  intl: intlShape.isRequired,
  data: PropTypes.shape().isRequired,
};

FormTextBlock.defaultProps = {};

export default injectIntl(FormTextBlock);
