// internal
import {
  GO_TO_SLIDE,
  VISIBLE_FIELD,
} from '../actions/api/set-generic-value';

export const initialState = {
  currentSlide: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GO_TO_SLIDE:
      return {
        ...state,
        currentSlide: action.payload.slideIndex,
      };

    case VISIBLE_FIELD: {
      const sectionName = action.payload.section;
      const fieldName = action.payload.name;
      let currentState;
      if (sectionName !== undefined) {
        currentState = {
          ...state,
          [sectionName]: {
            ...state[sectionName],
            [fieldName]: action.payload.value,
          },
        };
      } else {
        currentState = {
          ...state,
          [fieldName]: action.payload.value,
        };
      }

      return currentState;
    }

    default:
      return state;
  }
};
