import textResource from '../../../../utils/text-resources/rahalaitos/FI/messages';

const CITIZENSHIPS = [
  {
    label: textResource.citizenshipFIN,
    value: 'finnish',
  },
  {
    label: textResource.citizenshipSWE,
    value: 'swedish',
  },
  {
    label: textResource.citizenshipRUS,
    value: 'russian',
  },
  {
    label: textResource.citizenshipEST,
    value: 'estonian',
  },
  {
    label: textResource.citizenshipOther,
    value: 'other',
  },
];
export default CITIZENSHIPS;
