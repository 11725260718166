import { brand } from './current-locale';

import SAMBLA_LINK_URLS from './config/SE/sambla/link-urls';
import ADVISA_LINK_URLS from './config/SE/advisa/link-urls';

import PHONE_NUMBERS from './config/phone-numbers';
import EMAIL from './config/SE/shared/mail';
import INTEREST_LIMITS from './config/SE/shared/interest-limits';
import LOAN_LIMITS from './config/SE/shared/loan-limits';
import LOAN_CALCULATOR_LIMITS from './config/SE/shared/loan-calculator-limits';
import LOAN_RESTRICTIONS from './config/SE/shared/loan-restrictions';
import INTL_FORMATS from './config/intl-formats';
import TRACKING from './config/SE/shared/tracking';
import * as FORM_DEFAULT_VALUES from './config/SE/advisa/form-default-values';
import DETAILS from './config/SE/shared/details';

import * as COMPONENTS from './config/components';

let LINK_URLS; // eslint-disable-line import/no-mutable-exports
if (brand === 'sambla' || brand === 'lanaPengarna') {
  LINK_URLS = SAMBLA_LINK_URLS;
} else {
  LINK_URLS = ADVISA_LINK_URLS;
}

const localeData = {
  COMPONENTS,
  ...DETAILS,
  ...FORM_DEFAULT_VALUES,
  INTL_FORMATS,
  ...LINK_URLS,
  PHONE: PHONE_NUMBERS,
  EMAIL,
  INTEREST: INTEREST_LIMITS,
  LOAN: LOAN_LIMITS,
  LOAN_CALCULATOR_LIMITS,
  RESTRICTIONS: LOAN_RESTRICTIONS,
  TRACKING,
};

export default localeData;
export { PHONE_NUMBERS as PHONE };
export { EMAIL };
export { INTEREST_LIMITS as INTEREST };
export { LOAN_LIMITS as LOAN };
export { LOAN_CALCULATOR_LIMITS };
export { LOAN_RESTRICTIONS as RESTRICTIONS };
export { INTL_FORMATS };
export { FORM_DEFAULT_VALUES };
export { LINK_URLS };
export { DETAILS };
export { TRACKING };
