const sv = require('./sv');
const fi = require('./fi');
const no = require('./no');
const ar = require('./ar');

module.exports = {
  sv,
  fi,
  no,
  ar,
};
