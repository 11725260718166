export default {
  monthlyCostPosition: 'center',
  valueFontSize: '1.85rem',
  monthlyCostLabelFontSize: '1rem',
  labelColor: 'var(--black)',
  minimalBackgroundColor: 'var(--teal)',
  minimalTextAlign: 'center',
  minimalBorderRadius: '10px',
  minimalPadding: '2rem 0',
  minimalMargin: '0rem 1rem 1rem 0',
  minimalColor: 'var(--white)',
};
