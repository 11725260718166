import stripDelimiter from '../../utils/strip-delimiter-from-currency';

const submitCurrentLoans = (currentLoans) => currentLoans.map(
  (loan) => (
    {
      amount: parseInt(stripDelimiter(loan.amount), 10),
      monthlyPayment: parseInt(stripDelimiter(loan.monthlyPayment), 10) || null, // eslint-disable-line
      type: loan.type,
      transfer: !!loan.transfer,
      share: loan.type === 'car' || loan.type === 'mortgage' ? Number(loan.loanShare) : null,
    }
  ),
);

export default submitCurrentLoans;

