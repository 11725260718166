import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import intlShape from '../../schemas/intl';

const FormLabel = styled.p`
  && {
    font-size: bold;
    color: inherit;
    margin-bottom: ${(props) => (props.break === 'down' ? '2rem' : '0')};
  }
`;

const SmallFormLabel = ({
  intl: { formatMessage },
  data,
}) => (
  <>
    <FormLabel break={data.break}>
      {formatMessage(data.label)}
    </FormLabel>
  </>
);

SmallFormLabel.propTypes = {
  data: PropTypes.shape(),
  intl: intlShape.isRequired,
};

SmallFormLabel.defaultProps = {
  data: {},
};

export default injectIntl(SmallFormLabel);
