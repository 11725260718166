import { region, brand } from '../localizations/current-locale';

export const originCheck = (domain) => {
  if (!domain) { return ''; }

  const modifiedDomain = domain.replace(/(https?:\/\/)?(www.)?(staging.)?/i, '');
  const lowercaseRegion = region.toLowerCase();

  switch (modifiedDomain) {
    case 'lanemarknad.se/':
      return 'lanemarknad.se';
    case 'advisa.se/partner/':
      return 'partner.advisa.se';
    case 'lana-pengarna.se/':
      return 'sambla.se';
    case 'flyttabolan.se/':
      return 'sambla.se';
    case 'rahoitu.fi':
      return 'new.rahoitu.fi';
    default:
      return `${brand}.${lowercaseRegion}`;
  }
};

export default originCheck;
