/* eslint-disable react/prop-types */
/**
 * Validated autocomplete field to be used in a <ValidatedForm>. Should be used
 * as <ValidatedForm.Autocomplete>, not imported directly.
 *
 * Usage:
 * ```js
 * <ValidatedForm ...>
 *   <ValidatedForm.Autocomplete
 *     name="company"
 *     items={[...]}
 *     validate={[required, number]}
 *     warn={max(10)} />
 * </ValidatedForm>
 * ```
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import tooltipSchema from '../../../js/schemas/tooltip';
import Alert from '../../atoms/Alert';
import Autocomplete from '../../atoms/Autocomplete';
import TextInput from '../../atoms/TextInput';

const ValidatedFormAutocomplete = ({
  className,
  name,
  validate,
  warn,
  items,
  compact,
  suffix,
  validateOnRegister,
  onChange,
  onSelect,
  tooltip,
  data,
  ...rest
}) => (
  <Field
    name={data.name}
    label={data.label}
    className={className}
    // eslint-disable-next-line no-use-before-define
    component={ValidatedAutocomplete}
    validate={data.validate}
    warn={warn}
    onChange={onChange}
    onSelect={onSelect}
    items={items}
    compact={compact}
    suffix={suffix}
    hasValidateFunction={!!validate}
    validateOnRegister={validateOnRegister}
    tooltip={tooltip}
    {...rest}
    {...data}
  />
);

ValidatedFormAutocomplete.propTypes = {
  className: PropTypes.string,
  /** Used as key in redux. */
  name: PropTypes.string,
  /** Validation function(s). Takes a value and returns an error string or undefined. */
  validate: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.func),
  ]),
  /** Validation function(s). Takes a value and returns a warning string or undefined. */
  warn: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.func),
  ]),
  items: PropTypes.arrayOf(PropTypes.shape()),
  compact: PropTypes.bool,
  suffix: PropTypes.string,
  validateOnRegister: PropTypes.bool,
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  tooltip: tooltipSchema,
};

ValidatedFormAutocomplete.defaultProps = {
  className: '',
  validate: null,
  warn: () => {},
  items: [],
  compact: false,
  suffix: '',
  validateOnRegister: false,
  onChange: () => {},
  onSelect: () => {},
  tooltip: '',
  name: '',
};

export default ValidatedFormAutocomplete;

const ValidatedAutocomplete = ({
  className,
  wrapperClassName,
  input,
  meta: {
    valid,
    touched,
    error,
    warning,
  },
  label,
  suffix,
  items,
  compact,
  hasValidateFunction,
  validateOnRegister,
  tooltip,
  onSelect,
  getItemValue,
  showErrors,
  ...rest
}) => {
  const inputId = `validated-form-autocomplete--${input.name}`;
  const shouldValidate = valid || touched || validateOnRegister;

  const select = (...args) => {
    if (onSelect) {
      onSelect(...args);
    }
    if (input.onChange) {
      input.onChange(...args);
    }
  };

  return (
    <div className={wrapperClassName} data-formfield={input.name}>
      <Autocomplete
        items={items}
        getItemValue={getItemValue}
        {...input}
        {...rest}
        onSelect={select}
        inputProps={{
          onFocus: input.onFocus,
          onBlur: input.onBlur,
        }}
        renderInput={({ ref, ...inputProps }) => (
          <TextInput
            inputRef={ref}
            {...rest}
            {...inputProps}
            id={inputId}
            name={input.name}
            label={label}
            suffix={suffix}
            compact={compact}
            validated={hasValidateFunction}
            valid={shouldValidate ? valid : null}
            className={className}
            tooltip={tooltip}
          />
        )}
      />
      {(shouldValidate || showErrors) && (
        (error && <Alert display={error} />)
        || (warning && <Alert type="warning" display={warning} />)
      )}
    </div>
  );
};

export { ValidatedAutocomplete };
