// from: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/freeze
const deepFreeze = (object) => {
  const propNames = Object.getOwnPropertyNames(object);

  propNames.forEach((propName) => {
    const value = object[propName];
    if (value && typeof value === 'object') {
      deepFreeze(value);
    }
  });

  return Object.freeze(object);
};

export default deepFreeze;
