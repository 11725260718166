import queryString from 'query-string';

const isClient = typeof window !== 'undefined';

const redirectToCustomerPortal = (url, queryParams) => {
  let redirectUrl = url;

  if (queryParams) {
    redirectUrl += `?${queryString.stringify(queryParams)}`;
  }

  window.top.location = redirectUrl;
};

if (isClient) {
  window.redirectToCustomerPortal = redirectToCustomerPortal;
}

export default {
  redirectToCustomerPortal,
};
