import { phone } from '@advisa/common-validators';
import { valid, invalid } from '../../utils/validators/resolutions';
import { normalizePhoneNumber } from '../../utils/normalize-phone-number';
import { region } from '../../localizations/current-locale';
import { dynamicBrand } from '../../utils/brand';

// eslint-disable-next-line import/no-dynamic-require
let messages;
import(`../../utils/text-resources/${dynamicBrand()}/${region}/messages`).then((m) => {
  messages = m.default;
});

const validatePhoneNumberFi = (input = '', ...props) => {
  if (!/\S+/.test(input || '')) {
    return (props[2] === 'applicant.phoneNumber'
      ? invalid(messages.phoneNumberRequired)
      : invalid(messages.coApplicantPhoneNumberRequired));
  }

  const normalizedInput = normalizePhoneNumber(input);
  const validatedPhone = phone.parse(normalizedInput, region);

  // Common validation check
  if (validatedPhone.valid === 'invalid') {
    return invalid(messages?.phoneNumberWrongNumber);
  }

  if (!/^((00|\+)?358|0)/.test(normalizedInput)) {
    return invalid(messages.wrongPhoneNumberBeginning);
  }

  if (!/^((00|\+)358|0)(?:(4\d)|(457)|(50))/.test(normalizedInput)) {
    return invalid(messages.phoneNumberMobilePrefixError);
  }

  if (!/^((00|\+)?358|0)(?:(4\d)|(457)|(50))\d{7}$/.test(normalizedInput)) {
    return invalid(messages.wrongPhoneNumberNumber);
  }

  return valid();
};

export default validatePhoneNumberFi;

