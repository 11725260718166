// external
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import intlShape from '../../schemas/intl';
import { FormattedNumberWithFallback } from '../../utils/FormattedNumberWithFallback';
import { addThousandSeparator } from '../../utils/formatters/add-thousand-separator';

const Wrapper = styled.div`
  && {
    display: flex;
    justify-content: space-between;
  }
`;

const AmountDisplay = styled.div`
  && {
    font-size: 1rem;
    font-weight: 600;

    strong {
      font-size: 0.8rem;
      display: block;
      color: var(--sg-grey-700);
    }
  }
`;

const RepaymentTimeDisplay = ({
  intl,
  data,
}) => {
  const values = useSelector((state) => state.form.application.values.repaymentTime);
  const repaymentTime = values;

  return (
    <>
      <Wrapper>
        <FormattedNumberWithFallback
          value={parseInt(repaymentTime, 10)}
        >
          {() => (
            <AmountDisplay>
              {intl.formatMessage(data.repaymentTimeLabel, {
                strong: (chunks) => (
                  <strong>
                    {addThousandSeparator(chunks)}
                    {' '}
                  </strong>
                ),
                repaymentTime,
              })}
            </AmountDisplay>
          )}

        </FormattedNumberWithFallback>
      </Wrapper>
      <br />
    </>
  );
};

RepaymentTimeDisplay.propTypes = {
  intl: intlShape.isRequired,
  data: PropTypes.shape(),
  values: PropTypes.shape({}),
};

RepaymentTimeDisplay.defaultProps = {
  data: {},
  values: {},
};

const RepaymentTimeDisplayWithIntl = injectIntl(RepaymentTimeDisplay);

export { RepaymentTimeDisplayWithIntl as RepaymentTimeDisplay };

export default RepaymentTimeDisplayWithIntl;
