// external
import { region } from '../localizations/current-locale';
import { dynamicBrand } from '../utils/brand';
import store from '../store';

// internal
import { valid, invalid } from '../utils/validators/resolutions';

let messages;
import(`../utils/text-resources/${dynamicBrand()}/${region}/messages`).then((m) => {
  messages = m.default;
});

const validateConsolidationLoan = (input, totalAmountInput) => {
  const state = store.getState();
  if (!/^(\d+)$/.test(input)) {
    return invalid(messages.consolidateLoanUseOnlyNumber);
  }

  const totalAmountValue = totalAmountInput ? parseInt(totalAmountInput, 10) : 0;
  const value = parseInt(input, 10);

  if (value > totalAmountValue) {
    return invalid(messages.tooHighConsolidateLoan, { maxAllowedAmount: totalAmountValue });
  }

  if (value > state?.form?.application?.values?.totalAmount) {
    return invalid(messages.tooHighConsolidateLoan, { maxAllowedAmount: totalAmountValue });
  }

  return valid();
};

export default validateConsolidationLoan;
