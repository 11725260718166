import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import devices from '../../js/styles/Devices';
import sizes from '../../js/styles/Sizes';

const Wrapper = styled.div`
  && {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 1;

    &:not([data-tooltip-trigger2]):hover,
    &[data-tooltip-trigger2~='hover']:hover,
    &[data-tooltip-trigger2~='focus']:focus,
    &[data-tooltip-trigger2='focus']:focus-within {
      > div {
        display: inline;
        border-radius: ${sizes.defaultBorderRadius};
        box-shadow: 0 2px 6px 0 var(--transparent-black-20);
        color: var(--tooltip-color);
        font-family: var(--font-family);
        font-size: 0.9rem;
        font-weight: normal;
        line-height: 1.2rem;
        padding: 0.75rem;
        text-decoration: none;
        text-transform: none;
        z-index: 3;
        box-sizing: border-box;

        &::after {
          position: absolute;
          width: 0;
          height: 0;
          content: ' ';
          z-index: 2;
        }
      }
    }
  }
`;

const TooltipBox = styled.div`
  && {
    display: none;
    position: absolute;
    bottom: calc(100% + 1rem);
    left: 50%;
    width: auto;
    max-width: 90vw;
    background: var(--tooltip-bg);
    transform: translateX(-50%);

    &::after {
      bottom: -1rem;
      left: 50%;
      border: 0.5rem solid transparent;
      border-top-color: var(--tooltip-bg);
      transform: translateX(-50%);
      filter: drop-shadow(0 6px 6px var(--transparent-black-20));
    }

    &[data-tooltip-trigger2='focus']:focus-within {
      display: inline;
      border-radius: ${sizes.defaultBorderRadius};
      box-shadow: 0 2px 6px 0 var(--transparent-black-20);
      color: var(--tooltip-color);
      font-family: var(--font-family);
      font-size: 0.9rem;
      font-weight: normal;
      line-height: 1.2rem;
      padding: 0.75rem;
      text-decoration: none;
      text-transform: none;
      z-index: 3;
      box-sizing: border-box;

      &::after {
        position: absolute;
        width: 0;
        height: 0;
        content: ' ';
        z-index: 2;
      }
    }

    &[data-tooltip-width2~='nowrap'] {
      white-space: nowrap;
    }

    &[data-tooltip-width2~='small'] {
      width: ${devices.isMobileWidth} * 0.2;

      @media ${devices.upFromTablet} {
        width: 12rem;
      }
    }

    &[data-tooltip-width2~='medium'] {
      width: ${devices.isMobileWidth} * 0.4;

      @media ${devices.upFromTablet} {
        width: 22rem;
      }
    }

    &[data-tooltip-width2~='large'] {
      width: ${devices.isMobileWidth} * 0.6;

      @media ${devices.upFromTablet} {
        width: 32rem;
      }
    }

    &[data-tooltip-width2~='big'] {
      width: ${devices.isMobileWidth} * 0.6;
      white-space: pre-wrap;

      @media ${devices.upFromTablet} {
        width: 34rem;
      }
    }

    &[data-tooltip-width2~='fill'] {
      width: 100%;
      max-width: 100%;
    }

    &[data-tooltip-text-align2~='center'] {
      text-align: center;
    }

    &[data-tooltip-color2~='dark-teal'] {
      background: var(--dark-teal);
      border-top-color: var(--dark-teal);
    }

    &[data-tooltip-desktop-width2~='small'] {
      @media ${devices.upFromTablet} {
        width: 12rem;
      }
    }

    &[data-tooltip-position2~='bottom'] {
      top: calc(100% + 1rem);
      bottom: auto;

      &::after {
        top: auto;
        bottom: -1rem;
        border: 0.5rem solid transparent;
        border-bottom-color: var(--teal);
        filter: none;
      }
    }

    &[data-tooltip-position2~='bottom-slider'] {
      top: calc(100% + 4rem);
      bottom: auto;

      &::after {
        top: auto;
        bottom: -4rem;
        border: 0.5rem solid transparent;
        border-bottom-color: var(--teal);
        filter: none;
      }
    }

    &[data-tooltip-position2~='left'] {
      left: 0;
      transform: none;

      &::after {
        left: 1rem;
        transform: none;
      }
    }

    &[data-tooltip-position2~='right'] {
      right: 0;
      left: 0;
      transform: none;

      &::after {
        right: 0.6rem;
        left: auto;
        transform: none;
      }
    }

    &[data-tooltip-position-desktop2~='left'] {
      @media ${devices.upFromTablet} {
        right: 0;
        left: 0;
        transform: none;
      }

      &::after {
        right: auto;
        left: 1rem;
        transform: none;
      }
    }

    &[data-tooltip-position-desktop2~='center'] {
      @media ${devices.upFromTablet} {
        right: auto;
        left: 50%;
        transform: translateX(-50%);
      }

      &::after {
        @media ${devices.upFromTablet} {
          right: auto;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }

    &[data-tooltip-position-desktop2~='right'] {
      @media ${devices.upFromTablet} {
        right: 0;
        left: auto;
        transform: none;
      }

      &::after {
        @media ${devices.upFromTablet} {
          right: 1rem;
          left: auto;
          transform: none;
        }
      }
    }
  }
`;

const Tooltips = ({ tooltipObject }) => (
  <Wrapper
    data-tooltip-trigger2={tooltipObject.trigger}
    tabIndex="0"
  >
    <TooltipBox
      data-tooltip-width2={tooltipObject.width}
      data-tooltip-desktop-width2={tooltipObject.desktopWidth}
      data-tooltip-position2={tooltipObject.position}
      data-tooltip-position-desktop2={tooltipObject.positionDesktop}
      data-tooltip-color2={tooltipObject.color}
      data-tooltip-text-align2={tooltipObject.textAlign}
    >
      {tooltipObject.text}
    </TooltipBox>
    <svg
      style={{
        float: 'right',
        width: '1.3rem',
        height: '1.3rem',
      }}
      viewBox="0 0 24 24"
    >
      <path
        fill="currentColor"
        d="M11,9H13V7H11M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M11,17H13V11H11V17Z"
      />
    </svg>
  </Wrapper>
);

Tooltips.propTypes = {
  tooltipObject: PropTypes.shape({
    text: PropTypes.string,
    trigger: PropTypes.string,
    width: PropTypes.string,
    desktopWidth: PropTypes.string,
    position: PropTypes.string,
    positionDesktop: PropTypes.string,
    color: PropTypes.string,
    textAlign: PropTypes.string,
  }),
};

Tooltips.defaultProps = {
  tooltipObject: {
    text: 'text',
    trigger: 'focus',
    width: 'small',
    desktopWidth: '',
    position: '',
    positionDesktop: '',
    color: '',
    textAlign: '',
  },
};

export default Tooltips;
