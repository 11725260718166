import { email } from '@advisa/common-validators';
import { region } from '../localizations/current-locale';
import { dynamicBrand } from '../utils/brand';
import { valid, invalid } from '../utils/validators/resolutions';

let messages;

import(`../utils/text-resources/${dynamicBrand()}/${region}/messages`).then((m) => {
  messages = m.default;
});

const validateEmail = (input = '', ...props) => {
  const { error } = email.parse(input);
  const hasError = error?.type;

  if (!/\S+/.test(input || '')) {
    return (props[2] === 'applicant.email'
      ? invalid(messages?.emailRequired)
      : invalid(messages?.coApplicantEmailRequired));
  }

  if (/\s/.test(input || '')) {
    return invalid(messages?.emailNoSpaces);
  }

  if (input.indexOf('@') === -1) {
    return invalid(messages?.emailNoAt);
  }

  if (/@.*@/.test(input)) {
    return invalid(messages?.emailTooManyAts);
  }

  if (!/.@/.test(input)) {
    return invalid(messages?.emailNoName);
  }

  if (!/@./.test(input)) {
    return invalid(messages?.emailNoDomain);
  }

  if (!/@.+\.[^.]{2,}$/.test(input)) {
    return invalid(messages?.emailNoTopLevelDomain);
  }

  if (/[.]+[.]+/.test(input)) {
    return invalid(messages?.emailTwoDots);
  }

  if (/\.@/.test(input)) {
    return invalid(messages?.emailDotBeforeAtSign);
  }

  if (hasError) {
    return invalid(messages?.emailRequired);
  }

  if (!/@[-a-zA-Z0-9]+\.[a-zA-Z]{2,}$/.test(input)) {
    return invalid(messages?.emailNoDomain);
  }

  // Saving this here because we will need it later, when the AB test for UseBouncer is complete
  // if (props[0]?.emailZeroBounceStatus === 'INVALID') {
  //   return invalid(messages?.invalidEmailWarningMessage);
  // }

  return valid();
};

export default validateEmail;
