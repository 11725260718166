import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import intlShape from '../../../schemas/intl';

const SimpleValueWrapper = styled.div`
  && {
    background: white;
    margin: 1rem 0;
    padding: 0.5rem;
  }
`;
const SimpleValue = styled.div`
  && {
    font-size: 1.5em;
    font-family: inherit;
    font-weight: 600;
    line-height: 1.2;
  }
`;

const SimpleValueLabel = ({
  intl: { formatMessage },
  data,
}) => {
  const formState = useSelector((state) => state.form.application.values);
  const matchedVaL = data.value.split('.').reduce((m, n) => m[n], formState);

  return (
    <SimpleValueWrapper>
      {formatMessage(data.label)}
      <SimpleValue>
        {matchedVaL}
        {' '}
        {formatMessage(data.suffix)}
      </SimpleValue>
    </SimpleValueWrapper>
  );
};

SimpleValueLabel.propTypes = {
  data: PropTypes.shape(),
  intl: intlShape.isRequired,
};

SimpleValueLabel.defaultProps = {
  data: {},
};

export default injectIntl(SimpleValueLabel);
