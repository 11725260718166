import PropTypes from 'prop-types';

export const tooltipStringSchema = PropTypes.string;

export const tooltipObjectSchema = PropTypes.shape({
  width: PropTypes.oneOf(['small', 'medium', 'large', 'fill', 'nowrap']),
  position: PropTypes.oneOf(['center', 'left', 'right', 'bottom', 'bottom left', 'bottom right']),
  positionDesktop: PropTypes.oneOf(['left', 'center', 'right']),
  trigger: PropTypes.oneOf(['hover', 'focus']),
  color: PropTypes.oneOf(['blue', 'dark-grey']),
});

export default PropTypes.oneOfType([
  tooltipStringSchema,
  tooltipObjectSchema,
]);
