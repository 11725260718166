import { useViewport } from './viewport';
import devices from '../styles/Devices';

const useDevice = () => {
  const { width } = useViewport();
  const convertPixelsToRem = width * 0.0625;
  const currentPixels = convertPixelsToRem;

  return {
    isDesktop: currentPixels > devices.isLaptopWidth,
    isLaptop: currentPixels < devices.isLaptopWidth,
    isTabletWidth: currentPixels > devices.isTabletWidth,
    isTablet: currentPixels < devices.isTabletWidth,
    isTabletAndUp: currentPixels > devices.isLargeMobileWidth,
    isLargeTabletAndUp: currentPixels > devices.isLargeTablet,
    isLargeMobile: currentPixels < devices.isLargeMobileWidth,
    isMobile: currentPixels < devices.isMobileWidth,
    isLargeTabletAndDown: currentPixels > devices.isDownMobileWidthRahalaitos,
    isLargeMobileAndUp: currentPixels > devices.isMobileWidth,

    isLargeTabletAndDownHeader: currentPixels < devices.isLargeTablet,
    isHub: currentPixels < devices.isHub,
    isLargeHub: currentPixels < devices.isLargeHub && currentPixels > devices.isLargeTablet,
  };
};

export default useDevice;
