/* eslint-disable react/prop-types */
/**
 * Validated text-input field with slider to be used in a <ValidatedForm>.
 * Should be used as <ValidatedForm.Slider>, not imported directly.
 *
 * Usage:
 * ```js
 * <ValidatedForm ...>
 *   <ValidatedForm.Slider
 *     name='totalAmount'
 *     validate=[required, number]
 *     warn={max(10)} />
 * </ValidatedForm>
 * ```
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import tooltipSchema from '../../../js/schemas/tooltip';
import Alert from '../../atoms/Alert';
import TextInput from '../../atoms/TextInput';
import Slider from '../Slider';
import { addThousandSeparator } from '../../../js/utils/formatters/add-thousand-separator';

const ValidatedFormSlider = ({
  interestRate,
  className,
  name,
  validate,
  warn,
  compact,
  validateOnRegister,
  sliderConfig,
  withTextInput,
  tooltip,
  tooltipLink,
  label,
  showLabel,
  onChange,
  showErrors,
  data,
  ...rest
}) => (
  <Field
    name={name}
    className={className}
    // eslint-disable-next-line no-use-before-define
    component={ValidatedSlider}
    validate={validate}
    warn={warn}
    onChange={onChange}
    compact={compact}
    label={label}
    showLabel={showLabel}
    hasValidateFunction={!!validate}
    validateOnRegister={validateOnRegister}
    sliderConfig={sliderConfig}
    withTextInput={withTextInput}
    tooltip={tooltip}
    tooltipLink={tooltipLink}
    showErrors={showErrors}
    format={(value) => addThousandSeparator(value)}
    {...rest}
    {...data}
  />
);

ValidatedFormSlider.propTypes = {
  className: PropTypes.string,
  /** Used as key in redux. */
  name: PropTypes.string,
  /** Validation function(s). Takes a value and returns an error string or undefined. */
  validate: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.func),
  ]),
  /** Validation function(s). Takes a value and returns a warning string or undefined. */
  warn: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.func),
  ]),
  compact: PropTypes.bool,
  validateOnRegister: PropTypes.bool,
  sliderConfig: PropTypes.shape(),
  withTextInput: PropTypes.bool,
  label: PropTypes.shape(),
  showLabel: PropTypes.bool,
  tooltip: tooltipSchema,
  onChange: PropTypes.func,
  englishSuffix: PropTypes.bool,
};

ValidatedFormSlider.defaultProps = {
  className: '',
  validate: null,
  warn: () => {},
  label: {},
  showLabel: false,
  compact: false,
  validateOnRegister: false,
  sliderConfig: {},
  withTextInput: false,
  tooltip: '',
  onChange: () => {},
  name: '',
  englishSuffix: false,
};

export default ValidatedFormSlider;

const ValidatedSlider = ({
  interestRate,
  className,
  input,
  meta: {
    valid,
    touched,
    error,
    warning,
  },
  showButtons,
  label,
  showLabel,
  placeholder,
  suffix,
  compact,
  hasValidateFunction,
  validateOnRegister,
  sliderConfig,
  withTextInput,
  tooltip,
  tooltipLink,
  showErrors,
  englishSuffix,
  ...rest
}) => {
  const inputId = `validated-form-text-input-with-slider--${input.name}`;
  const shouldValidate = valid || touched || validateOnRegister;

  return (
    <>
      {
        withTextInput && (
          <TextInput
            {...input}
            {...rest}
            id={inputId}
            label={label}
            showLabel={showLabel}
            placeholder={placeholder}
            tooltip={tooltip}
            tooltipLink={tooltipLink}
            compact={compact}
            validated={hasValidateFunction}
            valid={shouldValidate ? valid : null}
            className={className}
            suffix={suffix}
          />
        )
      }
      <Slider
        {...sliderConfig}
        start={parseInt(input?.value?.replace(' ', '') || sliderConfig.range.min, 10) || 0}
        tabIndex={-1}
        showButtons={showButtons}
        onChange={input.onChange}
        className={sliderConfig.className}
        interestRate={interestRate}
        label={label}
        showLabel={showLabel}
      />
      {(shouldValidate || showErrors) && (
        (error && <Alert display={error} />)
        || (warning && <Alert type="warning" display={warning} />)
      )}
    </>
  );
};

export { ValidatedSlider };
