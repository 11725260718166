const tooltipToObject = (tooltip) => {
  if (!tooltip) {
    return {};
  }
  return {
    text: typeof tooltip === 'object' ? tooltip.text : tooltip,
    width: typeof tooltip === 'object' ? tooltip.width : undefined,
    position: typeof tooltip === 'object' ? tooltip.position : undefined,
    positionDesktop: typeof tooltip === 'object' ? tooltip.positionDesktop : undefined,
    trigger: typeof tooltip === 'object' ? tooltip.trigger : undefined,
    color: typeof tooltip === 'object' ? tooltip.color : undefined,
    textAlign: typeof tooltip === 'object' ? tooltip.textAlign : undefined,
    desktopWidth: typeof tooltip === 'object' ? tooltip.desktopWidth : undefined,
  };
};

export default tooltipToObject;
