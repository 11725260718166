import PropTypes from 'prop-types';

export const ImageProp = PropTypes.shape({
  localFile: PropTypes.shape({
    childImageSharp: PropTypes.shape({
      fluid: PropTypes.any, // eslint-disable-line react/forbid-prop-types
    }),
  }),
});

export const SVGImageProp = PropTypes.shape({
  url: PropTypes.shape({
    source_url: PropTypes.string,
  }),
});

export const TemplateProps = PropTypes.shape({
  template: PropTypes.string,
  acfGroups: PropTypes.arrayOf(),
  postID: PropTypes.string,
  acfTemplates: PropTypes.arrayOf(),
  location: PropTypes.shape(),
  isPost: PropTypes.bool,
});

export const SquishingProps = {
  initialSquished: PropTypes.bool.isRequired,
  windowWidth: PropTypes.number.isRequired,
  windowLocationSlug: PropTypes.string,
  formHeight: PropTypes.number,
};
