import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import styled, { css } from 'styled-components';
import intlShape from '../../../js/schemas/intl';
import createMarkup from '../../../js/utils/formatters/html-to-text-formatter';
import tooltipSchema from '../../../js/schemas/tooltip';
import tooltipToObject from '../../../js/helpers/tooltipToObject';
import Tooltips from '../../utils/tooltips';
import Toggle from '../Toggle';
import style from './style';

const Wrapper = styled.div`
 && {
  display: flex;
  position: relative;
  margin-right: ${style.wrapperMarginRight};
  margin-bottom: 1rem;
 }
`;

const Label = styled.label`
  display: flex;
  position: relative;
  flex-direction: row;
  margin-right: 0.5rem;
  margin-top: ${style.checkBoxMArginTop};
  margin-bottom: ${style.checkBoxMArginBottom}
  align-items: ${style.checkBoxAlignment};
  font-size: ${style.checkBoxFontSize};
  box-sizing: border-box;
  user-select: none;
  cursor: pointer;
`;

const CheckboxContainer = styled.div`
  && {
    display: flex;
    align-items: ${style.checkboxItemAlign};
  }
`;

const Input = styled.input`
  position: absolute;
  width: 0;
  height: 0;
  cursor: pointer;
  opacity: 0;
`;

const Mark = styled.span`
  position: relative;
  flex-shrink: 0;
  width: 1.4rem;
  height: 1.4rem;
  background: var(--white);
  border: ${style.unmarkedBorder};
  border-radius: ${style.checkboxBorderRadius};

  ${(props) => props.checked && css`
    background-color: ${style.checkedColor};
    border-color: ${style.checkedColor};
  `}

  &:focus {
    outline: 2px solid var(--transparent-blue-50);
  }

  &::after {
    display: none;
    position: absolute;
    padding-top: ${style.checkmarkPaddingTop};
    top: 50%;
    left: 50%;
    color: var(--checkMarkColor);
    content: "✓";
    font-size: calc(1.4rem * 0.8);
    transform: translate(-50%, -50%);
    opacity: 1;

    ${(props) => props.checked && css`
      display: block;
    `}
  }
`;

const LabelText = styled.div`
  flex: 1 1 auto;
  margin-left: 1rem;
`;

const Content = styled.div`
  font-size: 1rem;
  font-weight: 400;
  margin-left: 1rem;
`;

const Checkbox = ({
  intl: { formatMessage },
  children,
  id,
  name,
  label,
  tagline,
  valid,
  tooltip,
  toggle,
  checked,
  toggleCheckbox,
  linkText,
  linkTextPolicy,
  ...rest
}) => {
  const labelString = Array.isArray(label) ? label[0] : label;
  const checkboxId = id || `checkbox--${name || ''}--${labelString.replace(/\s/, '-')}`;

  const tooltipObject = tooltipToObject(tooltip);
  if (typeof tooltip === 'object') { tooltipObject.text = formatMessage(tooltip.text); }

  const toggleChecked = () => toggleCheckbox(checkboxId, !checked);
  // eslint-disable-next-line no-param-reassign
  rest.onFocus = undefined;

  return (
    <>
      <Wrapper>
        <Label htmlFor={checkboxId}>
          <CheckboxContainer>
            <Input
              id={checkboxId}
              name={name}
              type="checkbox"
              checked={checked}
              onChange={toggleChecked}
              tabIndex="0"
              {...rest}
            />
            {toggle ? (
              <Toggle checked={checked} />
            ) : (
              <Mark checked={checked} />
            )}
            {label && (
            <LabelText>
              {Array.isArray(label)
                ? label.map((labelItem, key) => (
                  <span
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={createMarkup(labelItem)}
                    // eslint-disable-next-line react/no-array-index-key
                    key={`label-${key}`}
                  />
                ))
                : formatMessage(label)}
            </LabelText>
            )}
          </CheckboxContainer>
          {children && (
          <Content>
            {children}
          </Content>
          )}
        </Label>
        { tooltipObject.text
      && (
      <Tooltips tooltipObject={tooltipObject} />
      )}
      </Wrapper>
      { tagline
        && <small>{formatMessage(tagline)}</small>}
    </>
  );
};

Checkbox.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string,
  name: PropTypes.string,
  tagline: PropTypes.node,
  label: PropTypes.node,
  valid: PropTypes.bool,
  tooltip: tooltipSchema,
  toggle: PropTypes.bool,
  checked: PropTypes.bool,
  toggleCheckbox: PropTypes.func,
  intl: intlShape.isRequired,
  linkText: PropTypes.shape(),
  linkTextPolicy: PropTypes.shape(),
};

Checkbox.defaultProps = {
  children: null,
  id: null,
  name: null,
  label: '',
  tagline: '',
  valid: null,
  tooltip: '',
  toggle: false,
  checked: false,
  toggleCheckbox: () => {},
  linkText: {},
  linkTextPolicy: {},
};

export default injectIntl(Checkbox);
