import mailcheck from 'mailcheck';

export default (validate, value) => {
  if (validate(value) !== undefined) return undefined;

  const secondLevelDomains = [
    'hotmail',
    'gmail',
    'telia',
    'yahoo',
    'outlook',
    'icloud',
    'live',
    'me',
    'mail',
    'bredband',
    'glocalnet',
    'spray',
    'comhem',
    'home',
    'tele2',
  ];

  const topLevelDomains = [
    'com',
    'dk',
    'fi',
    'fr',
    'net',
    'no',
    'org',
    'se',
  ];

  let result;

  mailcheck.run({
    email: value,
    secondLevelDomains,
    topLevelDomains,
    suggested(suggestion) {
      result = suggestion;
    },
    empty() {},
  });

  return result;
};
