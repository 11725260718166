import React, { useState } from 'react';
import ReactAutocomplete from 'react-autocomplete';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import TextInput from './TextInput';

const Menu = styled.div`
  position: absolute;
  width: 100%;
  background: var(--white);
  border: 2px solid var(--light-grey);
  overflow: hidden;
  z-index: 3;
  box-sizing: border-box;
  margin-top: -0.5em;
`;

const Item = styled.div`
  cursor: pointer;
  margin: 0.25rem 0;
  padding: 0.5em 0.75em;

  &:first-child{
    margin-top: 0;
  }

  &--active{
   &:hover{
      background: var(--lighter-grey);
    }
  }
`;

const Autocomplete = ({
  onChange,
  onSelect,
  items,
  getItemValue,
  ...props
}) => {
  const [value, setValue] = useState();

  // eslint-disable-next-line react/prop-types
  const renderInput = ({ ref, ...inputProps }) => (
    <TextInput
      inputRef={ref}
      {...inputProps}
    />
  );

  const renderMenu = (menuItems) => (
    (menuItems && menuItems.length)
      ? (
        <Menu>
          {menuItems}
        </Menu>
      )
      : <></>
  );

  const renderItem = (item) => (
    <Item key={getItemValue(item)}>
      {getItemValue(item)}
    </Item>
  );

  const shouldItemRender = (item, inputValue) => {
    const itemValue = getItemValue(item);
    return (itemValue || '').toLowerCase().indexOf(inputValue.toLowerCase()) > -1;
  };

  const isItemSelectable = (item) => !!getItemValue(item);

  const change = (e, val) => {
    setValue(val);
    if (onChange) {
      onChange(e, val);
    }
  };

  const select = (val, item) => {
    setValue(val);
    if (onSelect) {
      onSelect(val, item);
    }
  };

  return (
    <ReactAutocomplete
      items={items}
      wrapperStyle={null}
      menuStyle={null}
      getItemValue={getItemValue}
      renderInput={renderInput}
      renderItem={renderItem}
      renderMenu={renderMenu}
      shouldItemRender={shouldItemRender}
      isItemSelectable={isItemSelectable}
      value={value}
      onChange={change}
      onSelect={select}
      {...props}
    />
  );
};

Autocomplete.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape()),
  getItemValue: PropTypes.func,
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
};

Autocomplete.defaultProps = {
  items: [],
  getItemValue: (item) => item.value,
  onChange: null,
  onSelect: null,
};

export default Autocomplete;
