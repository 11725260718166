// external
import { region } from '../localizations/current-locale';
import { dynamicBrand } from '../utils/brand';
import Config from '../utils/config';

// internal
import { valid, invalid } from '../utils/validators/resolutions';
import stripDelimiter from '../utils/strip-delimiter-from-currency';

let messages;
import(`../utils/text-resources/${dynamicBrand()}/${region}/messages`).then((m) => {
  messages = m.default;
});

const validateHomeCost = (input = '', ...props) => {
  const cleanedInput = stripDelimiter(input);
  const config = Config.getValues();

  if (!/\S+/.test(cleanedInput || '')) {
    return (props[2] === 'applicant.homeCost'
      ? invalid(messages.homeCostRequired)
      : invalid(messages.coApplicantHomeCostRequired));
  }

  if (!/^(\d+)$/.test(cleanedInput)) {
    return (props[2] === 'applicant.homeCost'
      ? invalid(messages.homeCostOnlyDigits)
      : invalid(messages.coApplicantHomeCostOnlyDigits));
  }

  const value = parseInt(cleanedInput, 10);

  const maxCost = config.RESTRICTIONS.MAX_HOME_COST;

  if (value > maxCost) {
    return (props[2] === 'applicant.homeCost'
      ? invalid(messages.homeCostTooHigh, { maxCost })
      : invalid(messages.coApplicantHomeCostTooHigh, { maxCost }));
  }

  const minCost = config.RESTRICTIONS.MIN_HOME_COST;

  if (value < minCost) {
    return (props[2] === 'applicant.homeCost'
      ? invalid(messages.homeCostTooLow)
      : invalid(messages.coApplicantHomeCostTooHigh));
  }

  return valid();
};

export default validateHomeCost;
