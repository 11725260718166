/* eslint-disable react/prop-types */
/**
 * A wrapper around redux-form's reduxForm() higher-order component, used for
 * validating a form. When the form is submitted, the `onSubmit` prop is called
 * with the form values.
 *
 * The children of a ValidatedForm can be rendered regularly or as a function.
 * Rendering the children as a function makes it possible to make use of
 * redux-form's props
 * (https://redux-form.com/7.4.2/docs/api/props.md/#-code-props-code-) further
 * down in the component hierarchy.
 *
 * NOTE: That in the case you don't need to be aware of the props coming from
 * redux-form, then rendering your components as children within the
 * `ValidatedForm`-component would suffice. Rather than invoking a function that
 * would hold the props.
 *
 * Usage:
 * ```jsx
 * <ValidatedForm
 *   form='formName'
 *   onSubmit={this.submit}
 *   initialValues={{ firstName: 'First name' }};
 * >
 *   <ValidatedForm.TextInput name='firstName' ... />
 *   <ValidatedForm.SubmitButton>...</ValidatedForm.SubmitButton>
 * </ValidatedForm>
 * ```
 *
 * Child as a function:
 * ```jsx
 * <ValidatedForm
 *   form='formName'
 *   onSubmit={this.submit}
 *   initialValues={{ firstName: 'First name' }};
 * >
 *   {({ valid }) => (
 *     ...
 *     <button disabled={!valid}>Submit</button>
 *   )}
 * </ValidatedForm>
 */

import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';

const ValidatedForm = ({
  children,
  handleSubmit,
  onSubmit,
  className,
  forwardRef,
  ...props
}) => {
  if (children == null) {
    return null;
  }
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={className}
      ref={forwardRef}
    >
      {children instanceof Function
        ? children(props)
        : children}
    </form>
  );
};

ValidatedForm.propTypes = {
  form: PropTypes.string.isRequired,
  onSubmit: PropTypes.func,
};

ValidatedForm.defaultProps = {
  onSubmit: () => {},
};

export default reduxForm()(ValidatedForm);
