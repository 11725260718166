import Cookies from 'js-cookie';
import { region } from '../localizations/current-locale';
import hash from '../store/actions/api/application/hashFunction';

import {
  sendEvent,
} from './gtm';

export const defaultCookieOptions = {
  expires: 300,
};

const getDatalayerValueByKey = (key) => {
  let result = null;
  /* eslint-disable */
  if (window.dataLayer) {
    window.dataLayer.push(function () { // eslint-disable-line
      const value = this.get(key);
      if (value) {
        result = value;
      }
    });
  }
  /* eslint-enable */
  return result || 0;
};

const hashValueIfNeeded = (state) => {
  const pathParts = state.latestFieldChanged.split('.');
  let value = state[pathParts[0]];
  if (pathParts.length > 1) {
    value = value[pathParts[1]];
  }

  if (state.latestFieldChanged === 'applicant.email'
    || state.latestFieldChanged === 'applicant.nationalID'
    || state.latestFieldChanged === 'applicant.phoneNumber'
    || state.latestFieldChanged === 'coApplicant.email'
    || state.latestFieldChanged === 'coApplicant.nationalID'
    || state.latestFieldChanged === 'coApplicant.phoneNumber'
  ) {
    return hash(value);
  }
  return value;
};

export const saveState = (name, state, options) => {
  const newState = {
    ...state,
    applicant: {
      ...state.applicant,
      nationalId: null,
    },
    coApplicant: {
      ...state.coApplicant,
      nationalId: null,
    },
  };

  try {
    Cookies.set(name, JSON.stringify(newState), { ...defaultCookieOptions, ...options });
    sendEvent({
      event: 'Edit Form',
      edit_value: hashValueIfNeeded(state),
      eventCategory: `${region} PrivateLoan`,
      eventAction: 'Input',
      eventLabel: state.latestFieldChanged,
      currentSlide: getDatalayerValueByKey('currentSlide'),
      formType: getDatalayerValueByKey('formType') || 'mergeLoan',
    });
  } catch (err) {
    // Ignore write errors
  }
};

export const loadState = (name, options) => {
  try {
    const serializedState = Cookies.get(name, options);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const clearState = (name, options) => {
  Cookies.remove(name, options);
};
