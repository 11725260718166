import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import styled from 'styled-components';
import intlShape from '../../../schemas/intl';
import ValidatedForm from '../../../../components/molecules/ValidatedForm';
import style from './style';

const LabelWrapper = styled.label`
  && {
    margin: ${(props) => (props.lead ? '0.5rem 0' : style.creditCheckWrapperMargin)};
    display: flex;
    flex-direction: column;
  }
`;

const Text = styled.p`
  && {
    margin-top: 0;
    cursor: pointer;
    font-size: ${style.checkMarkTextFontSize};
    font-weight: var(--has-no-font-weight);
  }
`;

const Link = styled.a`
  && {
    cursor: pointer;
  }
`;

const CreditCheckConsent = ({
  validateOnRegister,
  data,
  showErrors,
  intl: { formatMessage },
}) => {
  const { links, linkInText } = data;

  return (
    <LabelWrapper
      lead={data.lead}
    >
      <ValidatedForm.Checkbox
        data={data}
        type="checkbox"
        checked
        showErrors={showErrors}
        validateOnRegister={validateOnRegister}
      >
        {linkInText ? (
          <Text>
            {formatMessage(data.text, {
              a: (chunks) => (
                <Link
                  href={links}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {chunks}
                </Link>
              ),
            })}
          </Text>
        ) : (
          <Text>
            {formatMessage(data.text)}
            <Link
              href={links}
              target="_blank"
              rel="noopener noreferrer"
            >
              {formatMessage(data.linkText)}
            </Link>
          </Text>
        )}
      </ValidatedForm.Checkbox>
    </LabelWrapper>
  );
};

CreditCheckConsent.propTypes = {
  data: PropTypes.shape(),
  intl: intlShape.isRequired,
  validateOnRegister: PropTypes.bool,
  showErrors: PropTypes.bool,
};

CreditCheckConsent.defaultProps = {
  validateOnRegister: false,
  data: {},
  showErrors: false,
};

export default injectIntl(CreditCheckConsent);
