/* eslint-disable react/prop-types */
/**
 * Form section to be used inside a <ValidatedForm> for simple reuse of field
 * collections. Should be used as <ValidatedForm.Section>, not imported
 * directly.
 *
 * The values of fields inside of a section are wrapped in an object with the
 * same name as the key of the section.
 *
 * Usage:
 * ```js
 * <ValidatedForm ...>
 *   <ValidatedForm.TextInput name='firstName' ... />
 *   <ValidatedForm.Section name='company'>
 *     <ValidatedForm.TextInput name='address' ... />
 *   </ValidatedForm.Section>
 * </ValidatedForm>
 * ```
 *
 * Resulting form value structure:
 * ```json
 * {
 *   firstName: '',
 *   company: {
 *     name: ''
 *   }
 * }
 * ```
 */

import { FormSection } from 'redux-form';

const ValidatedFormSection = FormSection;

export default ValidatedFormSection;
