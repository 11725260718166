// external
import { region } from '../localizations/current-locale';
import { dynamicBrand } from '../utils/brand';

// internal
import Config from '../utils/config';
import { valid, invalid } from '../utils/validators/resolutions';

let messages;
import(`../utils/text-resources/${dynamicBrand()}/${region}/messages`).then((m) => {
  messages = m.default;
});

const validateNewLoan = (input, otherInput) => {
  if (!/^(\d+)$/.test(input)) {
    return invalid(messages.newLoanUseOnlyNumbers);
  }

  const config = Config.getValues();
  const otherValue = otherInput ? parseInt(otherInput, 10) : 0;
  const maxAllowedAmount = config.LOAN.UP_TO - otherValue;
  const minAllowedAmount = config.LOAN.FROM - otherValue;
  const value = parseInt(input, 10);

  if (value < minAllowedAmount) {
    return invalid(messages.tooLowNewLoan, { minAllowedAmount });
  }

  if (value > maxAllowedAmount) {
    return invalid(messages.tooHighNewLoan, { maxAllowedAmount });
  }

  return valid();
};

export default validateNewLoan;
