// external
import { region } from '../localizations/current-locale';
import { dynamicBrand } from '../utils/brand';

// internal
import { valid, invalid } from '../utils/validators/resolutions';

let messages;
import(`../utils/text-resources/${dynamicBrand()}/${region}/messages`).then((m) => {
  messages = m.default;
});

const validateCivilStatus = (input = '') => {
  if (!/\S+/.test(input || '')) {
    return invalid(messages.requiredCivilStatus);
  }

  return valid();
};

export default validateCivilStatus;
