export default {
  titleTextAlign: 'left',
  titleFontSize: '1.5rem',
  titleFontFamily: 'var(--title-font)',
  subtitleColor: 'var( --black)',
  subtitleFontSize: '1rem',
  subtitleFontWeight: '400',
  formTitleColor: 'var(--dark-teal)',
  formTitlePadding: '1.6rem 1.5rem 0',
  marginBottom: '0.25rem',
  subtitlePadding: '0 1.5rem',
  subtitleMargin: '0',
  mobileTitleAlign: 'left',
  desktopTitleFontWeight: '800',
  userAgreementDataPolicyPadding: '0.5rem',
};
