import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
width: 2rem;
height: 2rem;
font-size: 1rem;
line-height: var(--circleBtnOffset);
background: var(--circleBtnBackground);
border: 2px solid var(--circleBtnBorderColor);
color: var(--circleBtnColor);
position: relative;
text-align: center;
border-radius: 1000px;
font-family: monospace;
font-weight: bold;
box-shadow: var(--circleBtnShadow);
cursor:pointer;
`;

const CircleBtn = ({
  callBack,
  children,
}) => (
  <Wrapper onClick={callBack}>
    {children}
  </Wrapper>
);

CircleBtn.propTypes = {
  children: PropTypes.node,
  callBack: PropTypes.func,
};

CircleBtn.defaultProps = {
  children: null,
  callBack: null,
};

export default CircleBtn;
