import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import BankIdLogo from '../../js/images/bank-id-logo.svg';

const Button = styled.button`
  && {
    background-color: ${(props) => props.backgroundColor};
    font-size: ${(props) => props.fontSize};
    padding: 0.7rem;
    border-radius: ${(props) => (props.borderRadius ? props.borderRadius : '3333rem')};
    border: none;
    font-weight: bold;
    text-decoration: none;
    margin: 0.5rem auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${(props) => (props.textColor ? props.textColor : 'var(--white)')};
    border: ${(props) => (props.border ? props.border : 'none')};
    cursor: ${(props) => props.cursor};
    height: 3rem;
    min-width: 12rem;
    width: 100%;
  }
`;

const BankIdLogoStyled = styled(BankIdLogo)`
  && {
    margin-left: ${(props) => (props.centercontent ? '-1rem' : '0')};
    display: inline-block;
    position: relative;
    line-height: normal;
    width: 1.5rem;
    filter: ${(props) => (props.isBlueLogo ? 'brightness(0) invert(0)' : 'brightness(0) invert(1)')};
  }
`;

const BankIdButton = ({
  children,
  disabled,
  onClick,
  validatedBrandColor,
  signInButton,
  showLogo,
  border,
  textColor,
  isBlueLogo,
  borderRadius,
}) => (

  <Button
    onClick={disabled ? () => {} : onClick}
    disabled={disabled}
    fontSize={signInButton ? '1.4rem' : '1rem'}
    cursor={disabled ? 'not-allowed' : 'pointer'}
    backgroundColor={disabled ? 'var(--light-grey)' : validatedBrandColor}
    type="button"
    border={border}
    textColor={textColor}
    borderRadius={borderRadius}
  >
    {showLogo && <BankIdLogoStyled isBlueLogo={isBlueLogo} />}
    {children}
  </Button>
);

BankIdButton.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  signInButton: PropTypes.bool,
  validatedBrandColor: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  showLogo: PropTypes.bool,
  border: PropTypes.string,
  textColor: PropTypes.string,
  isBlueLogo: PropTypes.bool,
  borderRadius: PropTypes.string,
};

BankIdButton.defaultProps = {
  disabled: false,
  signInButton: false,
  validatedBrandColor: null,
  showLogo: true,
  border: null,
  textColor: null,
  isBlueLogo: false,
  borderRadius: null,
};

export default BankIdButton;
