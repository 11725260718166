import React from 'react';
import PropTypes from 'prop-types';
import { FormattedNumber } from 'react-intl';

import { INTL_FORMATS } from '../localizations/locale-data';

const isValid = (value) => value != null && Number.isFinite(value);

const FormattedNumberWithFallback = ({
  value,
  formatAs,
  showPositive,
  children,
}) => {
  if (isValid(value)) {
    return (
      <>
        {showPositive && value > 0 ? '+' : ''}
        <FormattedNumber
          value={value}
          format={formatAs}
        >
          {children}
        </FormattedNumber>
      </>
    );
  }
  return '-';
};

FormattedNumberWithFallback.propTypes = {
  value: PropTypes.number,
  formatAs: PropTypes.oneOf(Object.keys(INTL_FORMATS.number)).isRequired,
  showPositive: PropTypes.bool,
  children: PropTypes.func,
};

FormattedNumberWithFallback.defaultProps = {
  value: null,
  showPositive: false,
  children: null,
};

// eslint-disable-next-line import/prefer-default-export
export { FormattedNumberWithFallback };
