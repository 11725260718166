import textResource from '../../../../utils/text-resources/rahalaitos/FI/messages';

const LOAN_TYPES = [
  {
    value: 'car',
    label: textResource.loanTypeCar,
  },
  {
    value: 'consumer',
    label: textResource.loanTypeConsumer,
  },
  {
    value: 'creditCard',
    label: textResource.loanTypeCreditCard,
  },
  {
    value: 'mortgage',
    label: textResource.loanTypeMortgage,
  },
  {
    value: 'student',
    label: textResource.loanTypeStudent,
  },
];
export default LOAN_TYPES;
