import format from 'date-fns/format';
import { FinnishSSN } from 'finnish-ssn';

import stripDelimiter from '../../utils/strip-delimiter-from-currency';
import { normalizePhoneNumber } from '../../utils/normalize-phone-number';
import submitCurrentLoans from './currentLoans';

import {
  isEmployed,
  isTempEmployed,
  hasSpouse,
} from './applicantFunctions';

const gender = (applicant) => FinnishSSN.validate(applicant.nationalId)
  && FinnishSSN.parse(applicant.nationalId).sex;

const boolify = (value) => {
  switch (value) {
    case 'true':
      return true;
    case 'false':
      return false;
    default:
      return value;
  }
};

const isCamelCase = (value) => {
  if (/^[a-z][a-zA-Z0-9]*$/.test(value)) {
    return true;
  }
  return false;
};

const mapSubmittedValue = (value) => {
  if (value.indexOf('_') > 0) {
    return value.toLowerCase().replace(/_([a-z])/g, (_, letter) => letter.toUpperCase());
  } if (isCamelCase(value)) {
    return value;
  }
  return value.toLowerCase();
};

export const submitApplicant = (applicant, visibleFields) => ({
  email: applicant.email || null,
  phoneNumber: normalizePhoneNumber(applicant.phoneNumber) || null,
  nationalId: applicant.nationalId || null,
  dependants: applicant.dependants === 'null' ? 0 : (String(parseInt(applicant.dependants, 10)) || 0),
  civilStatus: applicant.civilStatus || null,
  citizenship: applicant.citizenship || null,
  politicallyExposed: applicant.politicallyExposedPerson || false,
  monthlySalary: parseInt(stripDelimiter(applicant.monthlyIncomeBeforeTax), 10) || null, // eslint-disable-line
  monthlyNetSalary: parseInt(stripDelimiter(applicant.monthlyIncomeAfterTax), 10)|| null, // eslint-disable-line
  otherMonthlyIncomeBeforeTax: applicant.otherIncome
    ? parseInt(stripDelimiter(applicant.otherMonthlyIncomeBeforeTax), 10) || null : null,
  homeType: applicant.homeType || null,
  homeTypeCategory: mapSubmittedValue(applicant.homeTypeCategory) || null,
  homeCost: String(parseInt(stripDelimiter(applicant.homeCost), 10)) || null,
  otherLivingCost: String(parseInt(stripDelimiter(applicant.otherLivingCost), 10)) || null,
  spouseMonthlySalary: hasSpouse(applicant) && applicant.spouseMonthlyIncomeBeforeTax
    ? parseInt(stripDelimiter(applicant.spouseMonthlyIncomeBeforeTax), 10) : null,
  employmentType: applicant.employmentType || null,
  employerName: visibleFields.applicant.employerName
    ? ((isEmployed(applicant) && applicant.employerName) || null) : null,
  employmentSince: applicant.employmentSince
    ? format(
      new Date(applicant.employmentSince, (visibleFields.applicant.employmentSinceMonth
        ? (applicant.employmentSinceMonth || 0) : 0)),
      'yyyy-MM-dd\'T\'HH:mm:ss',
    )
    : null,
  employmentTo: visibleFields.applicant.employmentTo
    && (isTempEmployed(applicant)) && applicant.employmentTo
    ? format(
      new Date(applicant.employmentTo, (applicant.employmentToMonth || 0)),
      'yyyy-MM-dd\'T\'HH:mm:ss',
    )
    : null,
  militaryServiceStatus:
    (gender(applicant) === 'male')
      ? (applicant.militaryServiceStatus || null)
      : null,
  occupationCategory: visibleFields.applicant.occupationCategory
    ? (mapSubmittedValue(applicant.occupationCategory) || null) : null,
  occupationTitle: visibleFields.applicant.occupationTitle
    ? (applicant.occupationTitle || null) : null,
  educationLevel: mapSubmittedValue(applicant.educationLevel) || null,
  moveInDate: applicant.moveInDate
    ? format(
      new Date(applicant.moveInDate, 0),
      'yyyy-MM-dd\'T\'HH:mm:ss',
    ) : null,
  currentLoans: submitCurrentLoans(applicant.currentLoanList),
  hasCreditCard: boolify(applicant.hasCreditCard),
});

export const submitCoApplicant = (applicant, visibleFields) => ({
  email: applicant.email || null,
  phoneNumber: normalizePhoneNumber(applicant.phoneNumber) || null,
  nationalId: applicant.nationalId || null,
  dependants: String(parseInt(applicant.dependants, 10)) || 0,
  civilStatus: applicant.civilStatus || null,
  citizenship: applicant.citizenship || null,
  politicallyExposed: applicant.politicallyExposedPerson || false,
  monthlySalary: parseInt(stripDelimiter(applicant.monthlyIncomeBeforeTax), 10) || null, // eslint-disable-line
  monthlyNetSalary: parseInt(stripDelimiter(applicant.monthlyIncomeAfterTax), 10)|| null, // eslint-disable-line
  otherMonthlyIncomeBeforeTax: applicant.otherIncome
    ? parseInt(stripDelimiter(applicant.otherMonthlyIncomeBeforeTax), 10) || null : null,
  spouseMonthlySalary: hasSpouse(applicant) && applicant.spouseMonthlyIncomeBeforeTax
    ? parseInt(stripDelimiter(applicant.spouseMonthlyIncomeBeforeTax), 10) : null,
  homeType: applicant.homeType || null,
  homeTypeCategory: mapSubmittedValue(applicant.homeTypeCategory) || null,
  homeCost: String(parseInt(stripDelimiter(applicant.homeCost), 10)) || null,
  otherLivingCost: String(parseInt(stripDelimiter(applicant.otherLivingCost), 10)) || null,
  employmentType: applicant.employmentType || null,
  employerName: visibleFields.coApplicant.employerName
    ? ((isEmployed(applicant) && applicant.employerName) || null) : null,
  employmentSince: applicant.employmentSince
    ? format(
      new Date(applicant.employmentSince,
        (visibleFields.coApplicant.employmentSinceMonth
          ? (applicant.employmentSinceMonth || 0) : 0)),
      'yyyy-MM-dd\'T\'HH:mm:ss',
    )
    : null,
  employmentTo: visibleFields.coApplicant.employmentTo
    && (isTempEmployed(applicant)) && applicant.employmentTo
    ? format(
      new Date(applicant.employmentTo, (applicant.employmentToMonth || 0)),
      'yyyy-MM-dd\'T\'HH:mm:ss',
    )
    : null,
  militaryServiceStatus:
    (gender(applicant) === 'male')
      ? (applicant.militaryServiceStatus || null)
      : null,
  occupationCategory: visibleFields.coApplicant.occupationCategory
    ? (mapSubmittedValue(applicant.occupationCategory) || null) : null,
  occupationTitle: visibleFields.coApplicant.occupationTitle
    ? (applicant.occupationTitle || null) : null,
  educationLevel: mapSubmittedValue(applicant.educationLevel) || null,
  moveInDate: applicant.moveInDate
    ? format(
      new Date(applicant.moveInDate, 0),
      'yyyy-MM-dd\'T\'HH:mm:ss',
    ) : null,
  currentLoans: submitCurrentLoans(applicant.currentLoanList),
  hasCreditCard: boolify(applicant.hasCreditCard),
});
