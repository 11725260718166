/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, {
  useState, useEffect, useContext,
} from 'react'; import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import LanguageContext from '../../../context/language';
import style from './style';

const Wrapper = styled.div`
  && {
    margin-top: ${style.userAgreementMarginTop};
    font-size: .7rem;
    text-align: center;
    color: inherit;
  }
`;

const UserAgreementLink = styled.a`
  && {
    text-decoration: underline;
    color: var(--black);
    cursor: pointer;
  }
`;

const UserAgreementDataPolicy = ({
  data,
}) => {
  const { lang } = useContext(LanguageContext);

  const [language, setLanguage] = useState('sv');

  useEffect(() => {
    if (language !== lang) {
      setLanguage(lang);
    }
  }, [lang]); // eslint-disable-line react-hooks/exhaustive-deps

  const userAgreement = language === 'en' ? data?.userAgreementEng : data?.userAgreement;
  const dataPolicy = language === 'en' ? data?.dataPolicyEng : data?.dataPolicy;

  return (
    <Wrapper>
      <FormattedMessage
        id="applicationForm.info.termsAndIntegrityPolicyLinks"
        defaultMessage="By submitting the application, I accept {userAgreement} and confirm that I have read {dataPolicy}."
        values={{
          userAgreement: (
            <UserAgreementLink
              href={userAgreement}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage
                id="applicationForm.termsAndIntegrityPolicyLinks.terms"
                defaultMessage="the user agreement"
              />
            </UserAgreementLink>
          ),
          dataPolicy: (
            <UserAgreementLink
              href={dataPolicy}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage
                id="applicationForm.termsAndIntegrityPolicyLinks.integrityPolicy"
                defaultMessage="privacy policy"
              />
            </UserAgreementLink>
          ),
        }}
      />
    </Wrapper>
  );
};

UserAgreementDataPolicy.propTypes = {
  data: PropTypes.shape(),
};

UserAgreementDataPolicy.defaultProps = {
  data: {},
};

export default UserAgreementDataPolicy;
