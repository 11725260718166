import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import {
  change,
} from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';

import { MonthlyFixedPaymentLoan } from '@advisa/loan-calculator';
import intlShape from '../../../js/schemas/intl';
import { FormattedNumberWithFallback } from '../../../js/utils/FormattedNumberWithFallback';
import calculateMonthlyCost from '../../../js/utils/calculateMonthlyCost';
import devices from '../../../js/styles/Devices'; // eslint-disable-line import/no-relative-packages
import style from './style';
import CalculateInterestRate from '../CalculateInterest';

const EXAMPLE_ADMINISTRATION_FEE = 0;

const mapStateToProps = (state) => ({
  values: state.form.application.values,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  changeField: (...args) => change('application', ...args),
}, dispatch);

const MonthlyCostWrapper = styled.div`
  && {
    margin: ${(props) => props.margin};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--dark-teal);

    @media ${devices.downFromSmallTablet} {
      flex-direction: column;
    }
  }
`;

const RepaymentTimeWarning = styled.p`
  && {
    font-size: 0.8rem;
    @media ${devices.downFromSmallTablet} {
      text-align: center;
    }
  }
`;

const DarkTealText = styled.strong`
  && {
    color: var(--dark-teal);
  }
`;

const Value = styled.div`
  && {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--dark-teal);
    font-size: 1.85rem;
    font-family: var(--font-family);
  }
`;

const MonthlyCostButton = styled.button`
  && {
    background-color: transparent;
    background-image: none;
    border: 0;
    border-radius: var(--default-border-radius);
    color: var(--dark-teal);
    cursor: pointer;
    font-weight: bold;
    text-decoration: none;
    display: flex;
    justify-content: center;
    width: 1.2rem;
    height: 1.2rem;
    background-color: var(--white);
    border-radius: 50%;
    box-shadow: 0 0 0.3rem 0.01rem rgb(0 0 0 / 15%);
    color: ${style.monthlyCostButtonColor};
    font-weight: normal;
    line-height: 1.2rem;
    padding: 0;
    text-align: center;
    text-decoration: none;
    user-select: none;
    margin-right: ${(props) => props.marginRight};
    margin-left: ${(props) => props.marginRight};
  }
`;

const YearlyCost = ({
  values,
  loan,
  changeField,
  data,
  margin,
  intl: { formatMessage },
}) => {
  const { totalAmount, repaymentTime, administrationFee } = values;

  const increaseMonthlyCost = (repayment) => {
    if (repayment <= 1) {
      return null;
    }
    return changeField('repaymentTime', repayment - 1);
  };

  const decreaseMonthlyCost = (repayment) => {
    if (repayment >= 20) {
      return null;
    }
    return changeField('repaymentTime', repayment + 1);
  };

  let exampleMonthlyCost;
  if (loan instanceof MonthlyFixedPaymentLoan) {
    exampleMonthlyCost = loan.getMonthlyPayment();
  } else {
    exampleMonthlyCost = calculateMonthlyCost(
      totalAmount,
      repaymentTime * 12,
      administrationFee,
      CalculateInterestRate(),
    );
  }

  const increase = (time) => increaseMonthlyCost(time);
  const decrease = (time) => decreaseMonthlyCost(time);

  return (
    <MonthlyCostWrapper
      margin={margin || '2rem 0'}
    >
      <span>
        {formatMessage(data.costLabel)}
        <FormattedNumberWithFallback
          value={exampleMonthlyCost}
          formatAs="currency"
        >
          {(monthlyCost) => (
            <span>
              {formatMessage(data.approximateYearlyCostMobile, {
                monthlyCost,
                strong: (chunks) => <strong>{chunks}</strong>,
              })}
            </span>
          )}
        </FormattedNumberWithFallback>
      </span>
      <Value>
        <MonthlyCostButton
          marginRight="0.5em"
          type="button"
          tabIndex="-1"
          onClick={(e) => { e.preventDefault(); increase(repaymentTime); }}
        >
          <span>－</span>
        </MonthlyCostButton>
        <span>
          {formatMessage(data.approximateYearlyCostYears, {
            repaymentTime,
            strong: (chunks) => <DarkTealText>{chunks}</DarkTealText>,
          })}
        </span>
        <MonthlyCostButton
          marginRight="0.5em"
          type="button"
          tabIndex="-1"
          onClick={(e) => { e.preventDefault(); decrease(repaymentTime); }}
        >
          <span>+</span>
        </MonthlyCostButton>
      </Value>
      { repaymentTime > 15 && (
      <RepaymentTimeWarning>
        {formatMessage(data.repaymentTimeWarning)}
      </RepaymentTimeWarning>
      )}
    </MonthlyCostWrapper>
  );
};

YearlyCost.propTypes = {
  repaymentTime: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  administrationFee: PropTypes.number,
  loan: PropTypes.instanceOf(MonthlyFixedPaymentLoan),
  data: PropTypes.shape(),
  intl: intlShape.isRequired,
  values: PropTypes.shape(),
  margin: PropTypes.string,
  changeField: PropTypes.func,
};

YearlyCost.defaultProps = {
  repaymentTime: null,
  administrationFee: EXAMPLE_ADMINISTRATION_FEE,
  loan: null,
  changeField: () => {},
  data: {},
  values: {},
  margin: '',
};

const YearlyCostWithIntl = injectIntl(YearlyCost);

export { YearlyCostWithIntl as YearlyCost };

export default connect(mapStateToProps, mapDispatchToProps)(YearlyCostWithIntl);
