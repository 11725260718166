import { bankAccount } from '@advisa/common-validators';

import { valid, invalid } from '../../utils/validators/resolutions';

import { region } from '../../localizations/current-locale';
import { dynamicBrand } from '../../utils/brand';

// eslint-disable-next-line import/no-dynamic-require
let messages;
import(`../../utils/text-resources/${dynamicBrand()}/${region}/messages`).then((m) => {
  messages = m.default;
});

const validateBankAccount = (input = '') => {
  const validBankAccount = bankAccount.parse(input, region.toLowerCase());

  // Common validation check
  if (validBankAccount.valid === 'invalid') {
    return invalid(messages.invalidBankAccount);
  }

  if (!/\S+/.test(input || '')) {
    return invalid(messages.requiredBankAccount);
  }

  if (!/^FI\s*(\d\s*){16}$/.test(input)) {
    return invalid(messages.invalidBankAccountFormat);
  }

  return valid();
};

export default validateBankAccount;
