import { actionTypes } from 'redux-form';

const dirtyState = (state = {}, action) => {
  const isExpanded = [actionTypes.FOCUS, actionTypes.BLUR].includes(action.type)
    && action.meta.field === 'expanded';

  if (action.type === actionTypes.CHANGE || isExpanded) {
    return {
      ...state,
      dirty: true,
    };
  }
  return state;
};

export default dirtyState;
