import advisaSeConstants from '../localizations/config/brands/ADVISA_SE/constants';
import advisaNoConstants from '../localizations/config/brands/ADVISA_NO/constants';
import advisaFiConstants from '../localizations/config/brands/ADVISA_FI/constants';
import samblaSeConstants from '../localizations/config/brands/SAMBLA_SE/constants';
import samblaNoConstants from '../localizations/config/brands/SAMBLA_NO/constants';
import digifinansNoConstants from '../localizations/config/brands/DIGIFINANS_NO/constants';
import omalainaFiConstants from '../localizations/config/brands/OMALAINA_FI/constants';
import rahalaitosFiConstants from '../localizations/config/brands/RAHALAITOS_FI/constants';
import rahoituFiConstants from '../localizations/config/brands/RAHOITU_FI/constants';

import { brand } from '../localizations/current-locale';

const Symbol = require('es6-symbol/polyfill');

export const ADVISA_SE = Symbol('advisa');
export const ADVISA_NO = Symbol('advisa');
export const ADVISA_FI = Symbol('advisa');
export const SAMBLA_SE = Symbol('sambla');
export const SAMBLA_NO = Symbol('sambla');
export const DIGIFINANS_NO = Symbol('digifinans');
export const OMALAINA_FI = Symbol('omalaina');
export const RAHALAITOS_FI = Symbol('rahalaitos');
export const RAHOITU_FI = Symbol('rahoitu');
export const LOCALHOST = Symbol('localhost');

export const ALL_BRANDS = {
  ADVISA_SE,
  ADVISA_NO,
  ADVISA_FI,
  SAMBLA_SE,
  SAMBLA_NO,
  DIGIFINANS_NO,
  LOCALHOST,
  OMALAINA_FI,
  RAHALAITOS_FI,
  RAHOITU_FI,
};

export const getBrand = () => {
  const envBrand = process.env.REACT_APP_BRAND || 'advisa';
  const envRegion = process.env.REACT_APP_REGION || 'SE';
  const brandName = envBrand.toUpperCase();
  const env = `${brandName}_${envRegion}`;
  if (env) {
    return ALL_BRANDS[env];
  }
  return ADVISA_SE;
};

export const getBrandConstants = () => {
  switch (getBrand()) {
    case ADVISA_SE:
      return advisaSeConstants;
    case ADVISA_FI:
      return advisaFiConstants;
    case ADVISA_NO:
      return advisaNoConstants;
    case SAMBLA_SE:
      return samblaSeConstants;
    case SAMBLA_NO:
      return samblaNoConstants;
    case DIGIFINANS_NO:
      return digifinansNoConstants;
    case OMALAINA_FI:
      return omalainaFiConstants;
    case RAHALAITOS_FI:
      return rahalaitosFiConstants;
    case RAHOITU_FI:
      return rahoituFiConstants;
    default:
      return advisaSeConstants;
  }
};

export const dynamicBrand = () => (brand === 'lanaPengarna' ? 'sambla' : brand);

