export default {
  ctaButtonBackground: 'var(--mbc)',
  buttonBorderRadius: '9999px',
  buttonBorderRadiusLead: '0.25rem',
  buttonPadding: '1.2rem',
  boxShadow: '0 2px 1px #448500',
  buttonMarginTop: '2rem',
  opacity: '0.4',
  fontWeight: 'bold',
  transform: 'uppercase',
  ctaButtonColor: 'var(--white)',
};
