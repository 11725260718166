export default {
  // specific sizes
  mobile: '(min-width : 0rem) and (max-width : 32rem)',
  tablet: '(min-width : 32rem) and (max-width : 62rem)',
  smallDesktop: '(min-width : 62rem) and (max-width : 75rem)',
  laptop: '(min-width : 62rem) and (max-width : 114rem)',
  desktop: '(min-width : 114rem)',

  // sweeping sizes
  downFromMobile: '(max-width : 31.99rem)',
  upFromMobile: '(min-width : 32rem)',
  downFromSmallTablet: '(max-width : 44.99rem)',
  upFromSmallTablet: '(min-width : 45rem)',
  downFromTablet: '(max-width : 61.99rem)',
  upFromTablet: '(min-width : 62rem)',
  upFromSmallDesktop: '(min-width : 74rem)',
  downFromSmallDesktop: '(max-width: 74rem)',
  downFromDesktop: '(max-width: 79.99rem)',
  upFromDesktop: '(min-width: 80rem)',
  upFromLargeDesktop: '(min-width : 114rem)',
  downFromLargeDesktop: '(max-width : 113.99rem)',
  downFromLargeTablet: '(max-width : 63.99rem)',
  upFromLargeTablet: '(min-width : 64rem)',
  downFromHub: '(max-width : 67.5rem)',

  // constants
  isMobileWidth: 32,
  isLargeMobileWidth: 45,
  isTabletWidth: 62,
  isLaptopWidth: 80,
  isLargeTablet: 64,
  isDownMobileWidthRahalaitos: 37.5,

  isHub: 67.5,
  isLargeHub: 74,
};
