import { actionTypes } from 'redux-form';

import { getClientId } from '../../../utils/client-id';
import { saveState } from '../../../utils/form-state';

let timeout;
const debounce = (fn, delay) => {
  clearTimeout(timeout);
  timeout = setTimeout(fn, delay);
};

const formState = (state = {}, action) => {
  if (action.type === actionTypes.CHANGE) {
    debounce(() => {
      const applicationForm = {
        ...state.values,
        expanded: state.dirty,
        clientId: getClientId(),
        latestFieldChanged: action?.meta?.field,
      };
      saveState('application-form', applicationForm);
    }, 500);
  }
  return state;
};

export default formState;
