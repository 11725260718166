// internal
import { valid, invalid } from '../utils/validators/resolutions';
import stripDelimiter from '../utils/strip-delimiter-from-currency';
import messages from '../utils/messages';

const onlyNumbers = (input = '') => {
  const cleanedInput = stripDelimiter(input);

  if (!/^(-?\d+)$/.test(cleanedInput)) {
    return invalid(messages.genericOnlyNumbers);
  }

  return valid();
};

export default onlyNumbers;
