import 'requestidlecallback';
import axios from 'axios';
import { detect } from 'detect-browser';

import { getClientId } from './client-id';
import safeInterval from './safe-interval';
import loggerExposeError from './logger-expose-error';

const browser = detect();
const isClient = typeof window !== 'undefined';
let client;
let clientId;
let base;
let logContext;

const FLUSH_INTERVAL = 10 * 1000;
const LOG_LEVEL = {
  DEBUG: 'debug',
  INFO: 'info',
  WARN: 'warn',
  ERROR: 'error',
};
const AUTO_FLUSH_LEVEL = [
  LOG_LEVEL.ERROR,
];

let events = [];

const clearEvents = () => {
  events = [];
};

const initialize = ({
  baseURL,
  clientId: userClientId,
  service,
}) => {
  client = axios.create({ baseURL });
  clientId = userClientId || getClientId();
  base = baseURL;
  logContext = service;
};

const print = (level, event, payload) => {
  if (isClient || !window.console?.log) {
    return undefined;
  }

  try {
    // eslint-disable-next-line no-console
    return console[level](event, payload);
  } catch (e) {
    // eslint-disable-next-line no-console
    return console.log(event, payload);
  }
};

const immediateFlush = async () => {
  const payload = {
    browser,
    clientId,
    events,
    service: logContext,
  };

  if (typeof navigator.sendBeacon !== 'undefined') {
    navigator.sendBeacon(`${base}/logging`, JSON.stringify(payload));
  } else {
    await client.post('/logging', payload);
  }

  clearEvents();
};

const flush = () => {
  requestIdleCallback(async () => {
    if (!events.length) {
      return;
    }

    try {
      await immediateFlush();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  });
};

const enqueue = (level, event, payload) => {
  events.push({
    level,
    event,
    payload,
  });

  if (AUTO_FLUSH_LEVEL.indexOf(level) !== -1) {
    flush();
  }
};

const log = (level, event, payload) => {
  if (!client) {
    // eslint-disable-next-line no-use-before-define
    return logger;
  }

  const incomingPayload = level === 'error'
    ? loggerExposeError(payload)
    : payload;

  const logPayload = {
    ...incomingPayload,
    path: window.location.pathname,
    timestamp: Date.now(),
  };

  enqueue(level, event, logPayload);

  if (process.env.NODE_ENV === 'development') {
    print(level, event, logPayload);
  }

  return undefined;
};

const logger = () => {
  const debug = (...args) => log(LOG_LEVEL.DEBUG, ...args);
  const error = (...args) => log(LOG_LEVEL.ERROR, ...args);
  const info = (...args) => log(LOG_LEVEL.INFO, ...args);
  const warn = (...args) => log(LOG_LEVEL.WARN, ...args);

  if (isClient) {
    safeInterval(flush, FLUSH_INTERVAL);
  }

  return {
    clearEvents,
    debug,
    error,
    flush,
    immediateFlush,
    info,
    initialize,
    warn,
  };
};

export default logger();
