const { currentLocale, brand } = require('../../../current-locale');

const language = currentLocale.lang;
const { region } = currentLocale;

const locale = `${language}-${region}`;
const { title } = currentLocale;

const DETAILS = {
  WEBSITE_TITLE: title,
  LOCALE: locale,
  BANK_ACCOUNT_PLACEHOLDER: 'FI21 1234 5600 0007 85',
  NUM_OF_CREDITORS: 17,
  APPLICATION_EXPIRATION_DAYS: 30,
  CURRENCY: '€',
  LANGUAGE: language,
  REGION: region,
  BRAND: brand,
};

export default DETAILS;
