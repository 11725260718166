// external
import { region, brand } from '../localizations/current-locale';
import Config from '../utils/config';
import { dynamicBrand } from '../utils/brand';

// internal
import { valid, invalid } from '../utils/validators/resolutions';
import stripDelimiter from '../utils/strip-delimiter-from-currency';

let messages;

import(`../utils/text-resources/${dynamicBrand()}/${region}/messages`).then((m) => {
  messages = m.default;
});

const validateMonthlySalary = (input = '', ...props) => {
  const cleanedInput = stripDelimiter(input);
  const config = Config.getValues();

  if (!/\S+/.test(cleanedInput || '')) {
    return (props[2] === 'applicant.monthlySalary'
      ? invalid(messages.monthlySalaryRequired)
      : invalid(messages.coApplicantMonthlySalaryRequired));
  }

  if (!/^(-?\d+)$/.test(cleanedInput)) {
    return (props[2] === 'applicant.monthlySalary'
      ? invalid(messages.monthlySalaryOnlynumbers)
      : invalid(messages.coApplicantMonthlySalaryOnlynumbers));
  }

  const value = parseInt(cleanedInput, 10);

  const minSalary = config.RESTRICTIONS.MIN_SALARY;

  if (value < minSalary) {
    return (props[2] === 'applicant.monthlySalary'
      ? invalid(messages.monthlySalaryTooLow, { minSalary, brand })
      : invalid(messages.coApplicantMonthlySalaryTooLow, { minSalary, brand }));
  }

  return valid();
};

export default validateMonthlySalary;
