export const shouldHavePadding = (acfGroups) => {
  if (acfGroups[0].__typename === 'WordPressAcf_hero' //eslint-disable-line
    && acfGroups[1].__typename === 'WordPressAcf_page_title') { //eslint-disable-line
    return false;
  }
  if (acfGroups[0].__typename === 'WordPressAcf_page_title' //eslint-disable-line
    && acfGroups[0].supertitle !== '') {
    return 'supertitle';
  }
  if (acfGroups[0].__typename === 'WordPressAcf_page_title' //eslint-disable-line
    && acfGroups[0].supertitle === '') {
    return 'pagetitle';
  }
  return false;
};

export const gradiantBackground = (acfGroups) => {
  if (acfGroups[0].__typename !== 'WordPressAcf_aboveFoldWithForm') { //eslint-disable-line
    return false;
  }
  const imageSrcSet = acfGroups[0]?.mobileHero?.backgroundImage?.image?.localFile?.childImageSharp?.gatsbyImageData.srcSet || []; //eslint-disable-line
  const imageName = 'skiers.and_.background.without.pole-mobile.smaller';
  return imageSrcSet.includes(imageName);
};

export const getFontFamily = (fontFamily) => {
  switch (fontFamily) {
    case 'advisaIvar': return 'var(--font-family)';
    case 'advisaRoboto': return 'var(--font-family)';
    case 'samblaNunito': return 'Nunito, sans-serif';
    case 'samblaBeVietnamPro': return 'BeVietnamPro, sans-serif';
    case 'samblaSubjectivity': return 'Subjectivity, serif';
    case 'omalainaPoppins': return 'Poppins, sans-serif';
    case 'BwGradualLight': return 'BwGradualLight, sans-serif';
    case 'BwGradualBold': return 'BwGradualBold, sans-serif';
    case 'rahalaitosCoreSansC': return 'CoreSansC, sans-serif';
    case 'flyttabolanNoirPro': return 'NoirPro, sans-serif';
    case 'digifinansLexend': return 'Lexend, sans-serif';
    case 'rahoituGellix': return 'Gellix, sans-serif';
    default: return null;
  }
};

export const getTitleFontFamily = (fontFamily) => {
  switch (fontFamily) {
    case 'advisaIvar': return 'var(--title-font)';
    case 'advisaRoboto': return 'var(--font-family)';
    case 'samblaNunito': return 'Nunito, sans-serif';
    case 'samblaBeVietnamPro': return 'BeVietnamPro, sans-serif';
    case 'samblaSubjectivity': return 'Subjectivity, serif';
    case 'omalainaPoppins': return 'Poppins, sans-serif';
    case 'BwGradualLight': return 'BwGradualLight, sans-serif';
    case 'BwGradualBold': return 'BwGradualBold, sans-serif';
    case 'rahalaitosCoreSansC': return 'CoreSansC, sans-serif';
    case 'flyttabolanNoirPro': return 'NoirPro, sans-serif';
    case 'digifinansLexend': return 'Lexend, sans-serif';
    case 'rahoituGellix': return 'Gellix, sans-serif';

    default: return null;
  }
};
