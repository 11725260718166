// external
import React from 'react';
import PropTypes from 'prop-types';
import ValidatedForm from '../../../components/molecules/ValidatedForm';
import Config from '../../utils/config';

// internal
import repaymentTimeValidator from '../../validators/repaymentTime';
import parseNumericInput from '../../utils/parse-numeric-input';

const RepaymentTimeInput = ({
  data,
  showErrors,
  formType,
}) => {
  const config = Config.getValues();

  return (
    <ValidatedForm.Slider
      name={data.name}
      placeholder={data.placeholder}
      label={data.label}
      suffix={data.suffix}
      type="tel"
      parse={parseNumericInput}
      maxLength={String(config.RESTRICTIONS.LOAN_MAX_YEARS).length}
      validate={repaymentTimeValidator}
      showErrors={showErrors}
      formType={formType}
      totalAmountField
      repaymentTimeInput
      sliderConfig={{
        range: {
          min: config.RESTRICTIONS.LOAN_MIN_YEARS,
          max: config.RESTRICTIONS.LOAN_MAX_YEARS,
        },
        step: 1,
      }}
      withTextInput
      labelOutside
      tooltip={{
        text: data.tooltip.text,
        width: 'fill',
        trigger: 'focus',
        position: 'right',
      }}
      whitelist={data.whitelist}
    />
  );
};

RepaymentTimeInput.propTypes = {
  data: PropTypes.shape(),
  whitelist: PropTypes.bool,
  showErrors: PropTypes.bool,
  formType: PropTypes.string,
};

RepaymentTimeInput.defaultProps = {
  data: {},
  whitelist: false,
  showErrors: false,
  formType: '',
};

export default RepaymentTimeInput;
