import { MonthlyFixedPaymentLoan } from '@advisa/loan-calculator';
import {
  LOAN,
  RESTRICTIONS,
} from '../localizations/locale-data';

const calculateMonthlyCost = (
  amount,
  repaymentTime,
  administrationFee,
  interest,
) => {
  let monthlyCost = 0;

  if (
    amount >= LOAN.FROM
    && amount <= LOAN.UP_TO
    && repaymentTime >= RESTRICTIONS.LOAN_MIN_YEARS * 12
    && repaymentTime <= RESTRICTIONS.LOAN_MAX_YEARS * 12
  ) {
    const loan = new MonthlyFixedPaymentLoan({
      amount,
      repaymentTime,
      administrationFee,
      interest,
    });
    monthlyCost = typeof loan?.getMonthlyPayment === 'function'
      ? loan.getMonthlyPayment()
      : 0;
  }

  return Number.isFinite(monthlyCost) ? monthlyCost : 0;
};

export default calculateMonthlyCost;
