import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

const TrustBox = ({
  data,
}) => {
  const ref = useRef(null);

  useEffect(() => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);

  return (
    <div
      ref={ref}
      className="trustpilot-widget"
      data-locale={data.trustPilotData.locale}
      data-template-id="53aa8807dec7e10d38f59f32"
      data-businessunit-id={data.trustPilotData.buData}
      data-style-height="100px"
      data-style-width="150px"
      data-theme="light"
      data-stars="4,5"
      data-no-reviews="hide"
      data-scroll-to-list="true"
      data-allow-robots="true"
    />
  );
};

TrustBox.propTypes = {
  data: PropTypes.shape(),
};

TrustBox.defaultProps = {
  data: {},
};

export default TrustBox;
