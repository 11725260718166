// external
import { region } from '../localizations/current-locale';
import { dynamicBrand } from '../utils/brand';

// internal
import Config from '../utils/config';
import { valid, invalid } from '../utils/validators/resolutions';

let messages;
import(`../utils/text-resources/${dynamicBrand()}/${region}/messages`).then((m) => {
  messages = m.default;
});

const validateTotalLoan = (input) => {
  if (!/^(\d+)$/.test(input)) {
    return invalid(messages.totalLoanUseOnlyNumbers);
  }

  const config = Config.getValues();
  const maxAllowedAmount = config.LOAN.UP_TO;
  const minAllowedAmount = config.LOAN.FROM;
  const value = parseInt(input, 10);

  if (value < minAllowedAmount) {
    return invalid(messages.tooLowTotalLoan, { minAllowedAmount });
  }

  if (value > maxAllowedAmount) {
    return invalid(messages.tooHighTotalLoan, { maxAllowedAmount });
  }

  return valid();
};

export default validateTotalLoan;
