/* eslint-disable max-len */
import { feesCost } from '../../../../validators';

import textResource from '../../../../utils/text-resources/rahalaitos/FI/messages';

const tooltip = (text) => {
  const temp = {
    trigger: 'focus',
    text,
    width: 'fill',
    position: 'right',
  };
  return temp;
};

const data = {
  general: {},
  links: {
  },
  gridColumns: '1fr',
  slides: [
    {
      showBackButton: false,
      fields: [
        {
          component: 'TotalAmountInput',
          props: {
            name: 'totalAmount',
            placeholder: textResource.loanCalculatorTotalLoanAmountPlaceholder,
            label: textResource.loanCalculatorTotalLoanAmountLabel,
            suffix: textResource.loanCalculatorCurrencySuffix,
            whitelist: true,
            tooltip: tooltip(textResource.loanCalculatorTotalLoanAmountTooltip),
            fieldStyle: 'calculator',
            loanCalculatorStyle: true,
          },
        },
        {
          component: 'InterestRateInput',
          props: {
            name: 'interestRate',
            label: textResource.loanCalculatorInterestRateLabel,
            placeholder: textResource.loanCalculatorInterestRatePlaceholder,
            suffix: textResource.loanCalculatorInterestRateSuffix,
            whitelist: true,
            tooltip: tooltip(textResource.loanCalculatorInterestRateTooltip),
            fieldStyle: 'calculator',
            loanCalculatorStyle: true,
          },
        },
        {
          component: 'RepaymentTimeInput',
          props: {
            name: 'repaymentTime',
            label: textResource.loanCalculatorRepaymentTimeLabel,
            placeholder: textResource.loanCalculatorRepaymentTimePlaceholder,
            suffix: textResource.loanCalculatorRepaymentTimeSuffix,
            whitelist: true,
            tooltip: tooltip(textResource.loanCalculatorRepaymentTimeTooltip),
          },
        },
        {
          component: 'ValidatedForm',
          form: 'FeesInput',
          props: {
            name: 'setUpFee',
            isNumber: true,
            label: textResource.loanCalculatorSetUpFeeLabel,
            validate: feesCost,
            placeholder: textResource.loanCalculatorSetUpFeePlaceholder,
            hasValidateFunction: true,
            suffix: textResource.loanCalculatorCurrencySuffix,
            type: 'tel',
            tooltip: tooltip(textResource.loanCalculatorSetUpFeeTooltip),
          },
        },
        {
          component: 'ValidatedForm',
          form: 'FeesInput',
          props: {
            name: 'aviaFee',
            isNumber: true,
            label: textResource.loanCalculatorAviaFeeLabel,
            validate: feesCost,
            placeholder: textResource.loanCalculatorAviaFeePlaceholder,
            hasValidateFunction: true,
            suffix: textResource.loanCalculatorCurrencySuffix,
            type: 'tel',
            tooltip: tooltip(textResource.loanCalculatorAviaFeeTooltip),
          },
        },
        {
          component: 'SummarySection',
          props: {
            name: 'summarySection',
            loanCalculatorAmortizationTypeLabel: textResource.loanCalculatorAmortizationTypeLabel,
            loanCalculatorAmortizationTypeAnnuity: textResource.loanCalculatorAmortizationTypeAnnuity,
            loanCalculatorAmortizationTypeStraight: textResource.loanCalculatorAmortizationTypeStraight,
            loanCalculatorTotalCostsForLoan: textResource.loanCalculatorTotalCostsForLoan,
            loanCalculatorTotalCostsAndInterestAndFeesForLoan: textResource.loanCalculatorTotalCostsAndInterestAndFeesForLoan,
            loanCalculatorMonthlyCost: textResource.loanCalculatorMonthlyCost,
            loanCalculatorMonthlyCostOverviewButtonText: textResource.loanCalculatorMonthlyCostOverviewButtonText,
            loanCalculatorSummaryTablePageDescription: textResource.loanCalculatorSummaryTablePageDescription,
            loanCalculatorEffectiveInterest: textResource.loanCalculatorEffectiveInterest,
            loanCalculatorSummaryTableColumnMonth: textResource.loanCalculatorSummaryTableColumnMonth,
            loanCalculatorSummaryTableColumnDebtBeforeAmortization: textResource.loanCalculatorSummaryTableColumnDebtBeforeAmortization,
            loanCalculatorSummaryTableColumnAmortization: textResource.loanCalculatorSummaryTableColumnAmortization,
            loanCalculatorSummaryTableColumnInterestCost: textResource.loanCalculatorSummaryTableColumnInterestCost,
            loanCalculatorSummaryTableColumnFeeCost: textResource.loanCalculatorSummaryTableColumnFeeCost,
            loanCalculatorSummaryTableColumnToPay: textResource.loanCalculatorSummaryTableColumnToPay,
            loanCalculatorMonthlyCostMonthOne: textResource.loanCalculatorMonthlyCostMonthOne,
            currencySuffix: textResource.loanCalculatorCurrencySuffix,
            loanCalculatorInterest: textResource.loanCalculatorInterest,
            loanCalculatorAmortizationTypeTooltip: tooltip(textResource.loanCalculatorAmortizationTypeTooltip),
            loanCalculatorTotalCostsForLoanTooltip: tooltip(textResource.loanCalculatorTotalCostsForLoanTooltip),
            loanCalculatorTotalCostsAndInterestAndFeesForLoanTooltip: tooltip(textResource.loanCalculatorTotalCostsAndInterestAndFeesForLoanTooltip),
            loanCalculatorMonthlyCostTooltip: tooltip(textResource.loanCalculatorMonthlyCostTooltip),
            loanCalculatorInterestRateTooltip: tooltip(textResource.loanCalculatorInterestRateTooltip),
          },
        },
        {
          component: 'Teleporter',
          props: {
            title: textResource.loanCalculatorNextSlideButtonHeading,
            subtitle: textResource.loanCalculatorNextSlideButtonSubHeading,
            targetSlideIndex: 1,
            label: textResource.loanCalculatorNextSlideButton,
            ctaBtnStyle: true,
            centered: true,
            destinationPath: '/hae-lainaa/',
          },
        },
      ],
    },
  ],
};

export default data;
