import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { MonthlyFixedPaymentLoan } from '@advisa/loan-calculator';
import styled from 'styled-components';
import intlShape from '../../js/schemas/intl';
import { FormattedNumberWithFallback } from '../../js/utils/FormattedNumberWithFallback';
import CalculateInterestRate from './CalculateInterest';
import devices from '../../js/styles/Devices'; // eslint-disable-line import/no-relative-packages

const TotalCostWrapper = styled.div`
  && {
    margin: ${(props) => props.margin};
    display: flex;
    flex-direction: column;
    color: var(--dark-teal);

    @media ${devices.downFromTablet} {
      flex-direction: column;
      align-items: center;
    }

    @media ${devices.downFromSmallTablet} {
      align-items: center;
    }
  }
`;

const Value = styled.div`
  && {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--dark-teal);
    font-size: 1.85rem;
    font-weight: bolder;
    font-family: var(--font-family);
    padding: ${(props) => (props.lead ? '0' : '0.6rem')};
  }
`;

const Label = styled.div`
  && {
    position: relative;
    margin-top: ${(props) => (props.lead ? '-2rem' : ' -1.5rem;')};
    font-family: var(--font-family);
    font-size: ${(props) => (props.lead ? '0.8rem' : '1.2rem')};
    white-space: nowrap;
    color: var(--black);
    z-index: 1;
  }
`;

const TotalCost = ({
  data,
  margin,
  intl: { formatMessage },
}) => {
  const values = useSelector((state) => state.form.application.values);
  const { totalAmount, repaymentTime } = values;

  const EXAMPLE_INTEREST = 0.083;
  const interestRate = CalculateInterestRate() || EXAMPLE_INTEREST;

  const calculatedLoan = new MonthlyFixedPaymentLoan({
    amount: totalAmount,
    repaymentTime: repaymentTime * 12,
    interest: interestRate,
  });

  const totalCost = calculatedLoan.getTotalCost();

  return (
    <TotalCostWrapper
      margin={margin || '2rem 0'}
    >
      <Label
        lead={data.lead}
      >
        {formatMessage(data.label)}
      </Label>

      <Value>
        <span>
          <FormattedNumberWithFallback
            value={totalCost}
            formatAs="currency"
          >
            <span>
              {formatMessage(data.approximateTotalCost, {
                totalCost,
                strong: (chunks) => <strong>{chunks}</strong>,
              })}
            </span>

          </FormattedNumberWithFallback>
        </span>
      </Value>

    </TotalCostWrapper>
  );
};

TotalCost.propTypes = {
  data: PropTypes.shape(),
  intl: intlShape.isRequired,
  margin: PropTypes.string,
};

TotalCost.defaultProps = {
  data: {},
  margin: '',
};

export default injectIntl(TotalCost);
