const replaceHostName = (originalUrl = null, baseUrl = null) => {
  if (!originalUrl && !baseUrl) return null;
  if (originalUrl && !baseUrl) return originalUrl;
  if (!originalUrl && baseUrl) return baseUrl;

  return originalUrl
    .replace(/https?:\/\/(www[0-9]?\.)?(.[^/:]+)/,
      baseUrl);
};

export default replaceHostName;
