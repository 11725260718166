/* eslint-disable import/no-extraneous-dependencies */
import '@formatjs/intl-pluralrules';
import '@formatjs/intl-locale/polyfill';
import '@formatjs/intl-relativetimeformat/polyfill';
import svLocaleData from '@formatjs/intl-relativetimeformat/locale-data/sv';
import fiLocaleData from '@formatjs/intl-relativetimeformat/locale-data/fi';
import noLocaleData from '@formatjs/intl-relativetimeformat/locale-data/no';
import arLocaleData from '@formatjs/intl-relativetimeformat/locale-data/ar';
import ltLocaleData from '@formatjs/intl-relativetimeformat/locale-data/lt';
import plLocaleData from '@formatjs/intl-relativetimeformat/locale-data/pl';

/* eslint-disable */
import messagesSv from '../localizations/translations/sv-compiled';
import messagesFi from '../localizations/translations/fi-compiled';
import messagesEn from '../localizations/translations/en-compiled';
import messagesNo from '../localizations/translations/no-compiled';
import messagesAr from '../localizations/translations/ar-compiled';
import messagesLt from '../localizations/translations/lt-compiled';
import messagesPl from '../localizations/translations/pl-compiled';
/* eslint-enable */

import { region } from '../localizations/current-locale';

const languages = {
  sv: messagesSv,
  fi: messagesFi,
  en: messagesEn,
  no: messagesNo,
  ar: messagesAr,
  lt: messagesLt,
  pl: messagesPl,
};

const localeData = {
  SE: svLocaleData,
  FI: fiLocaleData,
  NO: noLocaleData,
  AR: arLocaleData,
  LT: ltLocaleData,
  PL: plLocaleData,
};

const getLocalization = (language) => {
  const langAndRegion = {
    region,
    lang: language,
    localeData: localeData[region],
    messages: languages[language],
  };
  return langAndRegion;
};

export const getMessages = (language) => {
  if (languages[language]) {
    return languages[language];
  }
  return languages.sv;
};

export default getLocalization;
