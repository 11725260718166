import * as Sentry from '@sentry/browser';

// eslint-disable-next-line import/no-extraneous-dependencies
import { AdvisaError } from '../constants/Errors';
import { VISIBILITY_STATE } from '../constants/states';
import { MINIMUM_ONLINE_POLL_INTERVAL } from '../constants/Time';

const pollerShouldRun = () => (
  window.navigator.onLine
  && document.visibilityState === VISIBILITY_STATE.VISIBLE
);

export default class PollerAsync {
  /* eslint-disable no-underscore-dangle */

  constructor(callback, interval = Infinity) {
    this._interval = interval;
    this.timer = null;
    this.shouldPoll = false;
    this.isPolling = false;
    this.startTime = Date.now();
    this.lastPollTime = null;
    this.callback = async () => {
      try {
        await callback();
      } catch (error) {
        if (!(error instanceof AdvisaError)) {
          Sentry.captureException(error);
        }
      }
    };
    this._poll = this._poll.bind(this);
    this._resumePoller = this._resumePoller.bind(this);
    this._pausePoller = this._pausePoller.bind(this);
    this.forcePoll = this.forcePoll.bind(this);
    this.callback = this.callback.bind(this);

    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === VISIBILITY_STATE.VISIBLE) {
        this._resumePoller(true);
      } else {
        this._pausePoller();
      }
    }, false);
    window.addEventListener('offline', () => this._pausePoller());
    window.addEventListener('online', () => this._resumePoller(true));
  }

  startPolling(pollDirectly = false) {
    this.shouldPoll = true;
    return this._resumePoller(pollDirectly);
  }

  stopPolling() {
    this.shouldPoll = false;
    this._pausePoller();
  }

  _resumePoller(pollDirectly = false) {
    if (!this.isPolling && this.shouldPoll && Number.isFinite(this._interval)) {
      this.isPolling = pollerShouldRun();
      this.timer = this._poll();
      if (pollDirectly) {
        return this.forcePoll();
      }
    }
    return Promise.resolve();
  }

  _pausePoller() {
    if (this.isPolling) {
      this.isPolling = false;
      clearTimeout(this.timer);
    }
  }

  _poll() {
    return setTimeout(async () => {
      this.lastPollTime = Date.now();
      await this.callback();
      clearTimeout(this.timer);
      // In case the polling is stopped in the callback
      if (this.isPolling) {
        this.timer = this._poll();
      }
    }, this._interval);
  }

  set interval(interval) {
    if (interval !== this._interval) {
      this._pausePoller();
      this._interval = interval;
      this._resumePoller();
    }
  }

  get interval() {
    return this._interval;
  }

  forcePoll() {
    // Poll DDoSing the server by enforcing a minimum polling interval
    if (this.getTimeSinceLastPoll() > MINIMUM_ONLINE_POLL_INTERVAL) {
      this.lastPollTime = Date.now();
      return this.callback();
    }
    return Promise.resolve();
  }

  getRunningTime() {
    return Date.now() - this.startTime;
  }

  getTimeSinceLastPoll() {
    if (!this.lastPollTime) return Infinity;
    return Date.now() - this.lastPollTime;
  }

  resetPollingTime() {
    this.startTime = Date.now();
    this.lastPollTime = null;
  }
}
