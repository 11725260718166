import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { defineMessages, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import intlShape from '../../js/schemas/intl';

const messages = defineMessages({
  tooHighIncome: {
    id: 'SalarySuggestion.tooHighIncome',
    defaultMessage: 'Is this monthly income correct?',
    description: 'warning for high income.',
  },
});

const SuggestionHint = styled.div`
  && {
    background: var(--light-grey);
    border-radius: 5px;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    button{
      background: transparent;
      outline: none;
      border: none;
      cursor: pointer;
    }
  }
`;

const SalarySuggestion = ({
  intl: { formatMessage },
  fieldName,
}) => {
  const [salarySuggestionToggle, setSalarySuggestion] = useState(false);

  const dismissSuggestion = () => {
    setSalarySuggestion(false);
  };

  const applicant = useSelector((state) => state.form.application.values.applicant);
  const coApplicant = useSelector((state) => state.form.application.values.coApplicant);

  const checkIncomeHigh = (individual, applicantType) => {
    const monthlySalary = String(individual?.monthlySalary).replaceAll(' ', '');
    const monthlyIncomeAfterTax = String(individual?.monthlyIncomeAfterTax).replaceAll(' ', '');
    const monthlyIncomeBeforeTax = String(individual?.monthlyIncomeBeforeTax).replaceAll(' ', '');
    const otherMonthlyIncomeBeforeTax = String(individual?.otherMonthlyIncomeBeforeTax).replaceAll(' ', '');
    const spouseMonthlyIncomeBeforeTax = String(individual?.spouseMonthlyIncomeBeforeTax).replaceAll(' ', '');

    const isSalaryHigh = Number(monthlySalary) >= 150000;
    const isIncomeBeforeTaxHigh = String(fieldName) === `${applicantType}.monthlyIncomeBeforeTax` && Number(monthlyIncomeBeforeTax) >= 15000;
    const isIncomeAfterTaxHigh = String(fieldName) === `${applicantType}.monthlyIncomeAfterTax` && Number(monthlyIncomeAfterTax) >= 15000;
    const isOtherMonthlyIncomeBeforeTax = String(fieldName) === `${applicantType}.otherMonthlyIncomeBeforeTax` && Number(otherMonthlyIncomeBeforeTax) >= 15000;
    const isSpouseMonthlyIncomeBeforeTax = String(fieldName) === `${applicantType}.spouseMonthlyIncomeBeforeTax` && Number(spouseMonthlyIncomeBeforeTax) >= 15000;
    return (
      isSalaryHigh
      || isIncomeBeforeTaxHigh
      || isIncomeAfterTaxHigh
      || isOtherMonthlyIncomeBeforeTax
      || isSpouseMonthlyIncomeBeforeTax
    );
  };

  useEffect(() => {
    if (window.location.pathname !== 'preappsms' && window.location.pathname !== 'preappemail') {
      const isApplicantIncomeHigh = checkIncomeHigh(applicant, 'applicant');
      const isCoApplicantIncomeHigh = checkIncomeHigh(coApplicant, 'coApplicant');

      setSalarySuggestion(isApplicantIncomeHigh || isCoApplicantIncomeHigh);
    }
  }, [applicant, coApplicant, fieldName]); // eslint-disable-line

  return (
    <div>
      {salarySuggestionToggle && (
      <SuggestionHint>
        <i>{formatMessage(messages.tooHighIncome)}</i>
        <button
          type="button"
          onClick={dismissSuggestion}
        >
          ✕
        </button>
      </SuggestionHint>
      )}
    </div>
  );
};

SalarySuggestion.propTypes = {
  intl: intlShape.isRequired,
  fieldName: PropTypes.string,
};

SalarySuggestion.defaultProps = {
  fieldName: '',
};

export default injectIntl(SalarySuggestion);

