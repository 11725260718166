import React from 'react';
import PropTypes from 'prop-types';

import useEffectOnce from '../hooks/useEffectOnce';

const ViewportContext = React.createContext({});

const ViewportProvider = ({ children }) => {
  const win = typeof window !== 'undefined' ? window : {};

  const [width, setWidth] = React.useState(win.innerWidth);
  const [height, setHeight] = React.useState(win.innerHeight);

  const handleWindowResize = () => {
    setWidth(win.innerWidth);
    setHeight(win.innerHeight);
  };

  useEffectOnce(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  });

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <ViewportContext.Provider value={{ width, height }}>
      {children}
    </ViewportContext.Provider>
  );
};

ViewportProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useViewport = () => {
  const { width, height } = React.useContext(ViewportContext);
  return { width, height };
};

export { ViewportProvider };
export { useViewport };
export default ViewportProvider;
