/* eslint-disable react/prop-types */
/**
 * Validated checkbox to be used in a <ValidatedForm>. Should be used as
 * <ValidatedForm.Checkbox>, not imported directly.
 *
 * Usage:
 * ```jsx
 * <ValidatedForm ...>
 *   <ValidatedForm.Checkbox
 *     name='preference'
 *     validate=[required]
 *     warn={not(2)}
 *   />
 * </ValidatedForm>
 * ```
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import Alert from '../../atoms/Alert';
import Checkbox from '../../atoms/CheckBox/Checkbox';

const ValidatedFormCheckbox = ({
  validate,
  warn,
  validateOnRegister,
  data,
  showErrors,
  ...rest
}) => (
  <Field
    name={data.name}
    className={data.className}
    // eslint-disable-next-line no-use-before-define
    component={ValidatedCheckbox}
    validate={data.validate}
    warn={warn}
    showErrors={showErrors}
    onChange={data.toggleCheckbox}
    hasValidateFunction={!!validate}
    validateOnRegister={validateOnRegister}
    tooltip={data.tooltip}
    {...data}
    {...rest}
  />
);

ValidatedFormCheckbox.propTypes = {
  /** Validation function(s). Takes a value and returns an error string or undefined. */
  validate: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.func),
  ]),
  /** Validation function(s). Takes a value and returns a warning string or undefined. */
  warn: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.func),
  ]),
  validateOnRegister: PropTypes.bool,
};

ValidatedFormCheckbox.defaultProps = {
  validate: null,
  warn: () => {},
  validateOnRegister: false,
};

export default ValidatedFormCheckbox;

const ValidatedCheckbox = ({
  className,
  input,
  meta: {
    valid,
    touched,
    error,
    warning,
  },
  label,
  hasValidateFunction,
  validateOnRegister,
  tooltip,
  toggle,
  data,
  showErrors,
  ...rest
}) => {
  const checkboxId = `validated-form-checkbox--${input.name}`;
  const shouldValidate = valid || touched || validateOnRegister;

  return (
    <>
      <Checkbox
        {...input}
        {...rest}
        id={checkboxId}
        checked={input.value === true}
        label={label}
        valid={shouldValidate ? valid : null}
        className={className}
        tooltip={tooltip}
        toggle={toggle}
      />
      {(shouldValidate || showErrors) && (
        (error && <Alert display={error} />)
        || (warning && <Alert type="warning" display={warning} />)
      )}
    </>
  );
};

export { ValidatedCheckbox };
