const LOAN_RESTRICTIONS = {
  MIN_AGE: 18,
  MAX_AGE: 99,
  LOAN_MIN_YEARS: 1,
  LOAN_MAX_YEARS: 20,
  MIN_NET_SALARY: 500,
  MIN_SALARY: 500,
  MIN_HOME_COST: 0,
  MAX_HOME_COST: 2000,
  MAX_OTHER_COST: 999,
  MIN_OTHER_COST: 0,
};

export default LOAN_RESTRICTIONS;
