import logger from './src/js/utils/logger';
import { getClientId } from './src/js/utils/client-id';

import { loadState } from './src/js/utils/form-state';
import './src/styles/importer.scss';
import wrapWithProvider from './src/js/utils/wrap-with-provider';
import wrapWithIntl from './src/js/utils/wrap-with-intl';

const cookieState = loadState('application-form') || {};

logger.initialize({
  baseURL: process.env.REACT_APP_API_ROOT_PAPI
  || process.env.REACT_APP_API_ROOT
  || process.env.REACT_APP_API_ROOT_ONBOARDING,
  clientId: cookieState.clientId || getClientId(),
  service: 'advisa',
});

export const onClientEntry = () => {
  // Polyfill for CSS scroll-behavior
  if (!('scrollBehavior' in document.documentElement.style)) {
    import('scroll-behavior-polyfill');
  }

  // Polyfill for Object.entries in IE 11
  if (!Object.entries) {
    import('core-js/modules/es.object.entries');
  }

  // Polyfill for Array.find in IE 11
  if (!Array.prototype.find) {
    import('core-js/modules/es.array.find');
  }
};

// eslint-disable-next-line import/prefer-default-export
export const wrapRootElement = wrapWithIntl(wrapWithProvider());
