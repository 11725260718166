export default {
  // content
  sectionMaxWidth: '62.5rem',
  sectionContentMaxWidth: '50rem',

  // border radius
  defaultBorderRadius: '5px',

  // padding
  sectionPadding: '2.5rem',
  negativeSectionPadding: '-2.5rem',
  containerPadding: '1.5rem',

  // height
  desktopHeaderHeight: '5rem',

  // width
  screenDesktopWidth: '80%',
  screenDesktopContain: '75rem',

  // gutter
  gutter: '0.2rem',
};
