export default {
  ctaButtonBackground: 'var(--teal)',
  buttonBorderRadius: '9999px',
  buttonBorderRadiusLead: '0.25rem',
  boxShadow: '0 2px 1px #448500',
  buttonMarginTop: '2rem',
  buttonWidth: '100%',
  buttonHeight: '3rem',
  checkMarkTextFontSize: '1rem',
  acceptNewsLetterMargin: '1em 0',
  storeValueMaxWidth: '5rem',
  buttonTextColor: 'var(--white)',
};
