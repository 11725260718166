export const SET_CONTEXT_VALUE = 'SET_CONTEXT_VALUE';
export const setContextValue = ({ context, value }) => {
  const parent = context.split('.');

  return ({
    type: SET_CONTEXT_VALUE,
    payload: {
      parent,
      value,
    },
  });
};
