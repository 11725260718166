import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import {
  change,
} from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { MonthlyFixedPaymentLoan } from '@advisa/loan-calculator';
import calculateMonthlyCost from '../../../js/utils/calculateMonthlyCost';

import intlShape from '../../../js/schemas/intl';
import { FormattedNumberWithFallback } from '../../../js/utils/FormattedNumberWithFallback';
import devices from '../../../js/styles/Devices'; // eslint-disable-line import/no-relative-packages
import style from './style';
import CircleBtn from '../../atoms/CircleBtn';
import CalculateInterestRate from '../CalculateInterest';
import { brand, region } from '../../../js/localizations/current-locale';
import { RESTRICTIONS } from '../../../js/localizations/locale-data';
import { useOptions } from '../../../context/options';
import { addThousandSeparator } from '../../../js/utils/formatters/add-thousand-separator';

const MonthlyCostWrapper = styled.div`
  && {
    margin: ${(props) => props.margin};
    display: flex;
    flex-direction: column;
    align-items: ${(props) => (props.lead ? style.leadPosition : style.monthlyCostPosition)};
    justify-content:  ${(props) => (props.lead ? style.leadPosition : style.monthlyCostPosition)};
    color: var(--dark-teal);

    @media ${devices.downFromTablet} {
      flex-direction: column;
      align-items: ${style.monthlyCostPosition};
    }

    @media ${devices.downFromMobile} {
      flex-direction: column;
      align-items: ${style.monthlyCostPosition};
    }
  }
`;

const DarkTealText = styled.strong`
  && {
    color: var(--dark-teal);
    font-family: var(--font-family);
  }
`;

const Value = styled.div`
  && {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--dark-teal);
    font-size: ${style.valueFontSize};
    font-family: var(--font-family);
    padding: ${(props) => (props.lead ? style.leadPadding : '0.6rem')};

    span {
      margin-right: 0.5em;
      margin-left: 0.5em;
    }
  }
`;

const MonthlyCostValueDisplay = styled.span`
  && {
    @media ${devices.downFromMobile} {
      word-break: break-word;
      text-align: center;
    }
  }
`;
const Label = styled.div`
  && {
    position: relative;
    font-size: ${style.monthlyCostLabelFontSize};
    z-index: 1;
    margin-top: ${(props) => (props.lead ? '-2rem' : ' -1.5rem;')};
    font-family: var(--font-family);
    z-index: 1;
    color: ${style.labelColor};
    white-space: nowrap;
  }
`;

const MinimalDisplay = styled.div`
  font-weight: 600;
  display: block;
  position: '';
  font-size: 1rem;
  padding: ${style.minimalPadding};
  margin: ${style.minimalMargin};
  color: ${style.minimalColor};
  line-height: 1.3rem;
  background: ${style.minimalBackgroundColor};
  text-align: ${style.minimalTextAlign};
  border-radius: ${style.minimalBorderRadius};
  width: 100%;

  @media ${devices.downFromMobile} {
    font-size: 1.3rem;
  }
`;

const MonthlyCost = ({
  data,
  margin,
  intl: { formatMessage },
  loan,
}) => {
  const changeField = (...args) => change('application', ...args);
  const values = useSelector((state) => state.form.application.values);
  const { totalAmount, repaymentTime, merge } = values;
  const dispatch = useDispatch();

  const { optionVariables } = useOptions();
  const { administrationFee } = optionVariables?.dynamicFormDisclaimer?.administrationFee
    ? optionVariables.dynamicFormDisclaimer : 0;

  useEffect(() => {
    if (region === 'NO' && !merge && merge !== null && repaymentTime > 5) {
      dispatch(changeField('repaymentTime', 5));
    }
  }, [dispatch, merge, repaymentTime]);
  let exampleMonthlyCost;
  if (loan instanceof MonthlyFixedPaymentLoan) {
    exampleMonthlyCost = loan.getMonthlyPayment();
  } else {
    exampleMonthlyCost = calculateMonthlyCost(
      totalAmount,
      repaymentTime * 12,
      administrationFee,
      CalculateInterestRate(),
    );
  }

  const increaseMonthlyCost = (repayment) => {
    if ((region === 'NO' && !merge && repayment <= 1) || repayment <= 1) {
      return null;
    }
    return dispatch(changeField('repaymentTime', repayment - 1));
  };

  const decreaseMonthlyCost = (repayment) => {
    if ((region === 'NO' && !merge && merge !== null && repayment >= 5) || repayment >= RESTRICTIONS.LOAN_MAX_YEARS) {
      return null;
    }
    return dispatch(changeField('repaymentTime', repayment + 1));
  };

  const increase = (time) => increaseMonthlyCost(time);
  const decrease = (time) => decreaseMonthlyCost(time);

  const isOmalaina = brand === 'omalaina';

  if (isOmalaina) {
    return (
      <MonthlyCostWrapper
        lead={data.lead}
        margin={margin || '2rem 0'}
      >
        <Label
          lead={data.lead}
        >
          {formatMessage(data.label)}
        </Label>
        <Value
          lead={data.lead}
        >
          <span>
            <FormattedNumberWithFallback
              value={exampleMonthlyCost}
              formatAs="currency"
            >
              {(monthlyCost) => (
                <span>
                  {formatMessage(data.approximateMonthlyCostMobile, {
                    monthlyCost,
                    strong: (chunks) => <strong>{chunks}</strong>,
                  })}
                </span>
              )}
            </FormattedNumberWithFallback>
          </span>
        </Value>
      </MonthlyCostWrapper>
    );
  }

  if (data.minimal) {
    return (
      <MinimalDisplay>
        <FormattedNumberWithFallback
          value={parseInt(exampleMonthlyCost, 10)}
        >
          {(monthlyCost) => (
            <span>
              {formatMessage(data.approximateMonthlyCostMobile, {
                strong: (chunks) => (
                  <strong>
                    {addThousandSeparator(chunks)}
                    {' '}
                  </strong>
                ),
                monthlyCost,
              })}
            </span>
          )}
        </FormattedNumberWithFallback>
      </MinimalDisplay>
    );
  }

  return (
    <MonthlyCostWrapper
      margin={margin || '2rem 0'}
    >
      <span>
        {formatMessage(data.costLabel)}
        &nbsp;
        {formatMessage(data.approximateMonthlyCostYears, {
          repaymentTime,
          strong: (chunks) => <DarkTealText>{chunks}</DarkTealText>,
        })}
      </span>
      <Value>
        <CircleBtn callBack={(e) => { e.preventDefault(); decrease(repaymentTime); }}>
          −
        </CircleBtn>

        <span>
          <FormattedNumberWithFallback
            value={exampleMonthlyCost}
            formatAs="currency"
          >
            {(monthlyCost) => (
              <MonthlyCostValueDisplay>
                {formatMessage(data.approximateMonthlyCostMobile, {
                  monthlyCost,
                  strong: (chunks) => <strong>{chunks}</strong>,
                })}
              </MonthlyCostValueDisplay>
            )}
          </FormattedNumberWithFallback>
        </span>

        <CircleBtn callBack={(e) => { e.preventDefault(); increase(repaymentTime); }}>
          +
        </CircleBtn>
      </Value>
    </MonthlyCostWrapper>
  );
};

MonthlyCost.propTypes = {
  data: PropTypes.shape(),
  intl: intlShape.isRequired,
  margin: PropTypes.string,
  loan: PropTypes.instanceOf(MonthlyFixedPaymentLoan),
};

MonthlyCost.defaultProps = {
  data: {},
  margin: '',
  loan: null,
};

const MonthlyCostWithIntl = injectIntl(MonthlyCost);

export { MonthlyCostWithIntl as MonthlyCost };

export default MonthlyCostWithIntl;
