// external
import { FinnishSSN } from 'finnish-ssn';
import Config from '../../utils/config';

// internal
import { valid, invalid } from '../../utils/validators/resolutions';

import { region } from '../../localizations/current-locale';
import { dynamicBrand } from '../../utils/brand';

// eslint-disable-next-line import/no-dynamic-require
let messages;
import(`../../utils/text-resources/${dynamicBrand()}/${region}/messages`).then((m) => {
  messages = m.default;
});

const validateNationalId = (input) => {
  const config = Config.getValues();
  const inputValue = input ? input.toUpperCase() : '';

  if (!/\S+/.test(inputValue)) {
    return invalid(messages.requiredNationalId);
  }

  let nationalId;
  try {
    nationalId = FinnishSSN.parse(inputValue);
  } catch (error) {
    if (error.message.match(/not valid ssn format/i)) {
      return invalid(messages.wrongNationalIdFormat);
    } if (error.message.match(/not valid ssn/i)) {
      return invalid(messages.invalidNationalId);
    }

    return invalid(messages.invalidNationalId);
  }

  if (!nationalId.valid) {
    return invalid(messages.invalidNationalId);
  }

  const minAge = config.RESTRICTIONS.MIN_AGE;
  const maxAge = config.RESTRICTIONS.MAX_AGE;

  if (nationalId.ageInYears < minAge) {
    return invalid(messages.tooYoungNationalId, { minAge });
  }

  if (nationalId.ageInYears > maxAge) {
    return invalid(messages.tooOldNationalId, { maxAge });
  }

  return valid();
};

export default validateNationalId;
