import React from 'react';
import styled from 'styled-components';
import { useOptions } from '../../context/options';
import ImageBlock from '../blocks/ImageBlock';

const Wrapper = styled.div`
  display: flex;
  margin-top: -1.5rem;
  border: 2px solid var(--sg-grey-300);
  border-radius: 2rem;
  background-color: var(--White);
  margin-top: 0.5rem;
`;

const Title = styled.p`
  font-size: var(--text-font-size);
  line-height: 1.5;
  color: var(--digi-blue-700);
  margin-bottom: -1rem;
`;

const Text = styled.p`
  font-size: var(--text-font-size);
  line-height: 1.5;
  color: var(--digi-blue-700);
  margin: 0rem !important;
`;

const TextWrapper = styled.div`
  display: column;
  padding: 2rem;
`;

const Image = styled(ImageBlock)`
  max-width: 5rem;
  max-height: 5rem;
  margin: 1rem;
`;

const FormQuestionsBox = () => {
  const { optionVariables } = useOptions();
  return (
    <Wrapper>
      <Image
        imageObject={optionVariables?.formQuestionsBox?.image}
      />
      <TextWrapper>
        <Title>{optionVariables?.formQuestionsBox?.title}</Title>
        <Text>{optionVariables?.formQuestionsBox?.text}</Text>
      </TextWrapper>
    </Wrapper>
  );
};

export default FormQuestionsBox;

