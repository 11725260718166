import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  background: var(--white);
  border-radius: 5px;
  color: var(--dark-teal);
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  padding: 0.3em;
`;

const ToggleValue = styled.div`
  padding: 0.3em 1em;

  ${({ isChecked }) => (isChecked
    && `
        background: var(--teal);
        border-radius: 5px;
        color: var(--white);
      `
  )};
`;

const Toggle = ({ className, checked, ...rest }) => {
  const [isChecked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(checked);
  }, [checked]);

  return (
    <Wrapper className={className} {...rest}>
      <ToggleValue isChecked={isChecked}>
        <FormattedMessage
          id="toggle.labelTrue"
          defaultMessage="Yes"
          description="Toggle switch label when 'true'"
        />
      </ToggleValue>
      <ToggleValue isChecked={!isChecked}>
        <FormattedMessage
          id="toggle.labelFalse"
          defaultMessage="No"
          description="Toggle switch label when 'false'"
        />
      </ToggleValue>
    </Wrapper>
  );
};

Toggle.propTypes = {
  className: PropTypes.string,
  checked: PropTypes.bool,
};

Toggle.defaultProps = {
  className: '',
  checked: false,
};

export default Toggle;
