const LINK_URLS = {
  WEBSITE: 'https://www.sambla.se',
  CUSTOMERSUPPORTLINK: 'https://www.sambla.se/kontakta-sambla/',
  EFFECTIVEINTERESTRATE: 'https://www.sambla.se/sambla-forklarar/effektiv-ranta/',
  AMORTIZATION: 'https://www.sambla.se/sambla-forklarar/amortering/',
  USER_AGREEMENT: 'https://sambla.se/om-sambla/anvandarvillkor/',
  DATA_POLICY_LINK: 'https://sambla.se/om-sambla/dataskyddspolicy/',
  FORM_DATA_CONSENT_LINK: '#',
};

export default LINK_URLS;
