/* eslint-disable react/prop-types */
/**
 * Validated number input field to be used in a <ValidatedForm>. Should be used as
 * <ValidatedForm.NumberInput>, not imported directly.
 *
 * Usage:
 * ```js
 * <ValidatedForm ...>
 *   <ValidatedForm.NumberInput
 *     name='dependants'
 *     validate=[required, number]
 *     warn={max(10)} />
 *     min={0}
 *     max={9}
 *     step={1}
 * </ValidatedForm>
 * ```
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import tooltipSchema from '../../../js/schemas/tooltip';
import Alert from '../../atoms/Alert';
import ButtonGroup from '../../atoms/ButtonGroup/ButtonGroup';

const ValidatedFormButtonGroup = ({
  className,
  name,
  validate,
  warn,
  compact,
  validateOnRegister,
  onChange,
  tooltip,
  data,
  ...rest
}) => (
  <Field
    name={data.name}
    className={className}
     // eslint-disable-next-line no-use-before-define
    component={ValidatedButtonGroup}
    validate={data.validate}
    warn={warn}
    onChange={onChange}
    compact={compact}
    hasValidateFunction={!!validate}
    validateOnRegister={validateOnRegister}
    tooltip={tooltip}
    {...rest}
    {...data}
  />
);

ValidatedFormButtonGroup.propTypes = {
  className: PropTypes.string,
  /** Used as key in redux. */
  name: PropTypes.string,
  /** Validation function(s). Takes a value and returns an error string or undefined. */
  validate: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.func),
  ]),
  /** Validation function(s). Takes a value and returns a warning string or undefined. */
  warn: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.func),
  ]),
  compact: PropTypes.bool,
  validateOnRegister: PropTypes.bool,
  onChange: PropTypes.func,
  tooltip: tooltipSchema,
};

ValidatedFormButtonGroup.defaultProps = {
  className: '',
  validate: null,
  warn: () => {},
  compact: false,
  validateOnRegister: false,
  onChange: () => {},
  tooltip: '',
  name: '',
};

export default ValidatedFormButtonGroup;

const ValidatedButtonGroup = ({
  className,
  input,
  meta: {
    valid,
    touched,
    error,
    warning,
  },
  label,
  compact,
  hasValidateFunction,
  validateOnRegister,
  tooltip,
  title,
  buttons,
  showErrors,
  ...rest
}) => {
  const inputId = `validated-form-input--${input.name}`;
  const shouldValidate = valid || touched || validateOnRegister;

  return (
    <div data-formfield={input.name}>
      <ButtonGroup
        {...input}
        {...rest}
        id={inputId}
        title={title}
        label={label}
        compact={compact}
        validated={hasValidateFunction}
        valid={shouldValidate ? valid : null}
        className={className}
        tooltip={tooltip}
        buttons={buttons}
      />
      {(shouldValidate || showErrors) && (
        (error && <Alert display={error} />)
         || (warning && <Alert type="warning" display={warning} />)
      )}
    </div>
  );
};

export { ValidatedButtonGroup };
