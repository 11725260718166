/* eslint-disable dot-notation */
import stripDelimiter from '../utils/strip-delimiter-from-currency';
import originCheck from '../utils/origin-check';
import { getCookie } from '../utils/cookies';

let url = 'advisa.se';
let internalCookie;

if (typeof window !== 'undefined' && typeof document !== 'undefined') {
  url = window.location.href;
  internalCookie = getCookie('external_id');
}
const siteDomain = originCheck(url);

const submitAdvancedCartAbandonment = (validFields) => ({
  consolidationAmount: validFields['consolidationAmount'] || null,
  loanPurpose: validFields['loanType'] || validFields['loanPurpose'] || null,
  civilStatus: validFields['applicant.civilStatus'] || null,
  numDependants: validFields['applicant.dependants'] || 0,
  nationalId: validFields['applicant.nationalId'] || null,
  homeType: validFields['applicant.homeType'] || null,
  homeCost: validFields['applicant.homeCost'] || 0,
  otherLivingCost: validFields['applicant.otherLivingCost'] || 0,
  monthlySalary: validFields['applicant.monthlySalary'] || null,
  monthlyIncomeBeforeTax: validFields['applicant.monthlyIncomeBeforeTax'] ? parseInt(stripDelimiter(validFields['applicant.monthlyIncomeBeforeTax']), 10) : null, // eslint-disable-line
  monthlyIncomeAfterTax: validFields['applicant.monthlyIncomeAfterTax'] ? parseInt(stripDelimiter(validFields['applicant.monthlyIncomeAfterTax']), 10) : null, // eslint-disable-line
  employmentType: validFields['applicant.employmentType'] || null,
  employmentSince: validFields['applicant.employmentSince'] || null,
  employmentSinceMonth: validFields['applicant.employmentSinceMonth'] || null,
  employmentTo: validFields['applicant.employmentTo'] || null,
  employmentToMonth: validFields['applicant.employmentToMonth'] || null,
  employerName: validFields['applicant.employerName'] || null,
  businessOrganizationNumber: validFields['applicant.businessOrganizationNumber'] || null,
  employmentIndustry: validFields['applicant.employmentIndustry'] || null,
  occupationCategory: validFields['applicant.occupationCategory'] || null,
  occupationTitle: validFields['applicant.occupationTitle'] || null,
  educationLevel: validFields['applicant.educationLevel'] || null,
  moveInDate: validFields['applicant.moveInDate'] || null,
  citizenship: validFields['applicant.citizenship'] || null,
  homeTypeCategory: validFields['applicant.homeTypeCategory'] || null,
  otherIncomeSource: validFields['applicant.otherIncomeSource'] || null,
  otherMonthlyIncomeAfterTax: validFields['applicant.otherMonthlyIncomeAfterTax'] || null,
  otherMonthlyIncomeBeforeTax: validFields['applicant.otherMonthlyIncomeBeforeTax'] || null,
  otherIncome: validFields['applicant.otherIncome'] || false,
  spouseMonthlySalary: validFields['applicant.spouseMonthlyIncomeBeforeTax'] || null,
  militaryServiceStatus: validFields['applicant.militaryServiceStatus'] || null,
  siteOrigin: siteDomain,
  internalCookie,
  bloomreachCookie: getCookie('__exponea_etc__'),
  phoneNumber: validFields['applicant.phoneNumber'] || null,
  email: validFields['applicant.email'] || null,
  totalAmount: validFields['totalAmount'] || 0,
  repaymentTime: validFields['repaymentTime'] || 0,
  rentalMonthlyIncomeBeforeTax: validFields['rentalMonthlyIncomeBeforeTax'] || null,
  mergeLoan: !!validFields['merge'],
  page: window.location.pathname,
});

export default submitAdvancedCartAbandonment;
