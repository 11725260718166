const TRACKING = {
  freshchat: {
    token: '32e0c2aa-b4ed-4350-b07c-35e7b3fc9f31',
    host: 'https://wchat.eu.freshchat.com',
    siteId: 'advisa',
    tags: ['FI'],
    locale: 'fi',
    config: {
      headerProperty: {
        hideChatButton: true,
        direction: 'ltr',
        backgroundColor: '#00ffff',
      },
    },
  },
};

export default TRACKING;
