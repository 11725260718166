// external
import { region } from '../localizations/current-locale';
import { dynamicBrand } from '../utils/brand';
import Config from '../utils/config';

// internal
import { valid, invalid } from '../utils/validators/resolutions';
import stripDelimiter from '../utils/strip-delimiter-from-currency';

let messages;

import(`../utils/text-resources/${dynamicBrand()}/${region}/messages`).then((m) => {
  messages = m.default;
});

const validateFeeCost = (input = '') => {
  const cleanedInput = stripDelimiter(input);
  const config = Config.getValues();

  if (!/\S+/.test(cleanedInput || '')) {
    return invalid(messages.feesCostRequired);
  }

  if (!/^(\d+)$/.test(cleanedInput)) {
    return invalid(messages.feesCostOnlyDigits);
  }
  if (input.length > 1 && !/^[1-9]\d*$/.test(cleanedInput)) {
    return invalid(messages.feesCostStartWithZero);
  }

  const value = parseInt(cleanedInput, 10);

  const maxCost = config.RESTRICTIONS.MAX_FEES_COST;

  if (value > maxCost) {
    return invalid(messages.feesCostTooHigh, { maxCost });
  }

  const minCost = config.RESTRICTIONS.MIN_FEES_COST;

  if (value < minCost) {
    return invalid(messages.feesCostTooLow);
  }

  return valid();
};

export default validateFeeCost;
