export default (value) => {
  if (!value) return value;
  switch (process.env.REACT_APP_LANGUAGE || 'sv') {
    case 'sv':
    case 'no':
    case 'pl':
    case 'lt':
    case 'en':
    case 'fi':
    case 'ar':
      return String(value).replace(/\s/g, '');
    case 'dk':
      return String(value).replace(/\./g, '');
    default:
      throw Error(`REACT_APP_LANGUAGE ${process.env.REACT_APP_LANGUAGE} is not implemented.`);
  }
};
