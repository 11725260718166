import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { LOAN } from '../../js/localizations/locale-data';
import { useOptions } from '../../context/options';
import { region } from '../../js/localizations/current-locale';

const CalculateInterestRate = () => {
  const { optionVariables } = useOptions();
  const values = useSelector((state) => state.form.application.values);
  const { totalAmount } = values;
  const formState = useSelector((state) => state.form.application);
  const [ranges, setRanges] = useState([]);
  const [interest, setInterest] = useState([]);
  const disclaimer = optionVariables?.dynamicFormDisclaimer;
  const isNO = region === 'NO';
  const mergeNoCheck = values.merge && isNO;

  const EXAMPLE_INTEREST = 0.0694;

  useEffect(() => {
    if (mergeNoCheck) {
      // eslint-disable-next-line
      const sortedRanges = disclaimer?.consolidationInterestRanges?.sort((a, b) => a.consolidationUpTo - b.consolidationUpTo);
      setRanges(sortedRanges);
    } else {
      const sortedRanges = disclaimer?.interestRanges?.sort((a, b) => a.upTo - b.upTo);
      setRanges(sortedRanges);
    }
  }, [disclaimer, values, isNO, mergeNoCheck]);

  useEffect(() => {
    if (ranges?.length !== 0) {
      const checkTotalAmount = (values.totalAmount > LOAN.UP_TO)
        ? LOAN.UP_TO
        : values.totalAmount;
      const interestRate = mergeNoCheck
        ? ranges?.find((range) => range.consolidationUpTo >= checkTotalAmount)
        : ranges?.find((range) => range.upTo >= checkTotalAmount);
      setInterest(interestRate);
    }
  }, [formState, ranges, totalAmount, values.totalAmount, mergeNoCheck]);

  return interest ? (mergeNoCheck ? interest.consolidationInterestRate
    : interest.interestRate) / 100 : EXAMPLE_INTEREST;
};

CalculateInterestRate.propTypes = {
  margin: PropTypes.string,
};

CalculateInterestRate.defaultProps = {
  loan: null,
};

export default CalculateInterestRate;
