const getMonthsForLocale = (
  lang,
  capitalizeFirstLetter = true,
) => {
  const monthFormatter = new Intl.DateTimeFormat(lang, { month: 'long' });
  return [...Array(12)].map((month, i) => {
    const newDate = new Date(Date.UTC(2000, i, 1, 0, 0, 0));
    const formattedMonth = monthFormatter.format(newDate);

    if (capitalizeFirstLetter) {
      return formattedMonth.charAt(0).toUpperCase() + formattedMonth.slice(1);
    }
    return formattedMonth;
  });
};

export default getMonthsForLocale;
