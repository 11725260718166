/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-properties */
/* eslint-disable prefer-exponentiation-operator */
/* eslint-disable max-len */
/* eslint-disable no-plusplus */
/* eslint-disable-next-line max-len */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import tooltipToObject from '../../helpers/tooltipToObject';
import { addThousandSeparator } from '../../utils/formatters/add-thousand-separator';
import localeData from '../../localizations/locale-data';
import SummaryTable from './SummaryTable';
import Tooltips from '../../../components/utils/tooltips';
import devices from '../../styles/Devices';
import style from './style';
import { brand } from '../../localizations/current-locale';

const Wrapper = styled.div`
  && {
    position: relative;
  }
`;

const Element = styled.div`
  && {
    display: flex;
    justify-content: space-between;
  }
`;

const ElementKeyTitle = styled.span`
  && {
    display: flex;
    justify-content: ${(props) => (props.justifyContent ? props.justifyContent : 'flex-start')};
  }
`;

const ElementKey = styled.span`
  && {
    display: flex;
    justify-content: ${(props) => (props.justifyContent ? props.justifyContent : 'flex-start')};
    padding: 0.2rem 0;
    align-items: center;

    @media ${devices.mobile} {
      font-size: 0.9rem;
    }
  }
`;
const ElementValue = styled.span`
  && {
    display: flex;
    justify-content: flex-end;
    margin-right: 2.2rem;
    color: ${style.loanCalculatorValueColor};
    padding: 0.2rem 0;
    align-items: center;

    @media ${devices.mobile} {
      font-size: 0.85rem;
    }
  }
`;

const ElementButton = styled.a`
  && {
    width: auto;
    text-align: center;
    text-decoration: underline;
    text-decoration-color: var(--black);
    cursor: pointer;
    margin-top: 1rem;
  }
`;

const ButtonContainer = styled.div`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const ButtonWrapper = styled.div`
  && {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    font-size: 1rem;
    margin: 0.5rem 0 2rem 0;

    @media screen and (min-width: 32rem) and (max-width: 62rem) {
      grid-template-columns: 'repeat(2, 1fr)';
      grid-template-columns: 'repeat(2, 1fr)';
    }
    @media screen and (min-width: 0rem) and (max-width: 32rem) {
      grid-template-columns: 'repeat(1, 1fr)';
      grid-template-columns: 'repeat(2, 1fr)';
    }
  }
`;

const StyledButton = styled.button`
  && {
    outline-color: ${(props) => (props.selected ? 'var(--teal)' : 'undefined')};
    border: ${(props) => (props.selected ? `${props.borderWidth} solid var(--teal)` : 'none')};
    cursor: pointer;
    height: 2.5rem;
    min-width: 1px;
    border-radius: 3px;
    justify-content: center;
    text-align: center;
    position: relative;
    box-shadow: 0 3px 5px 0 rgb(0 0 0 / 10%);
    transition: 0.3s all ease;
    background: ${(props) => (props.selected ? style.summaryButtonSelectedBackground : '#f5f5f5')};
    font-weight: ${(props) => (props.layout === 'toggle' && props.selected ? 'bold' : 'normal')};
    color: ${(props) => (props.selected ? style.summaryButtonSelectedColor : 'inherit')};

    &:before {
      content: ${style.summaryButtonContent};
      transition: 0.3s all ease;
      position: ${(props) => (props.selected ? 'relative' : 'absolute')};
      pointer-events: none;
      color: ${(props) => (props.selected ? style.summaryButtonSelectedColor : 'transparent')};
      width: ${(props) => (props.selected ? 'unset' : '0')};
    }
  }
`;

const NewLoanCalculatorSummary = ({
  data,
  // eslint-disable-next-line react/prop-types
  intl: { formatMessage },
}) => {
  const {
    loanCalculatorAmortizationTypeLabel,
    loanCalculatorAmortizationTypeAnnuity,
    loanCalculatorAmortizationTypeStraight,
    loanCalculatorTotalCostsForLoan,
    loanCalculatorTotalCostsAndInterestAndFeesForLoan,
    loanCalculatorMonthlyCostOverviewButtonText,
    loanCalculatorMonthlyCost,
    loanCalculatorSummaryTablePageDescription,
    loanCalculatorEffectiveInterest,
    loanCalculatorSummaryTableColumnMonth,
    loanCalculatorSummaryTableColumnDebtBeforeAmortization,
    loanCalculatorSummaryTableColumnAmortization,
    loanCalculatorSummaryTableColumnInterestCost,
    loanCalculatorSummaryTableColumnFeeCost,
    loanCalculatorSummaryTableColumnToPay,
    loanCalculatorMonthlyCostMonthOne,
    currencySuffix,
    loanCalculatorInterest,
    loanCalculatorAmortizationTypeTooltip,
    loanCalculatorTotalCostsAndInterestAndFeesForLoanTooltip,
    loanCalculatorTotalCostsForLoanTooltip,
    loanCalculatorMonthlyCostTooltip,
    loanCalculatorInterestRateTooltip,
  } = data;
  const [payments, setPayments] = useState([]);
  const [amortizationType, setAmortizationType] = useState('annuity');
  const [showTable, setShowTable] = useState(false);
  const {
    repaymentTime, aviaFee, setUpFee, interestRate, totalAmount,
  } = useSelector((state) => state.form.application.values);

  const WebsiteLink = (chunks) => <a href={localeData.AMORTIZATION} target="_blank" rel="noreferrer">{chunks}</a>;

  const toDecimal = (percentage) => parseFloat(percentage) / 100;

  const setStraightLinePayments = () => {
    const totalAmountOfMonths = parseInt(repaymentTime, 10) * 12;
    const amortizationEachMonth = parseInt(totalAmount, 10) / totalAmountOfMonths;
    const paymentsArray = [];
    let remainingDebt = parseInt(totalAmount, 10);

    for (let i = 1; i < totalAmountOfMonths + 1; i++) {
      const paymentObject = {
        month: i,
        debtBeforeAmortization: remainingDebt,
        amortization: amortizationEachMonth,
        interestCost: (remainingDebt * toDecimal(interestRate)) / 12,
        fees: parseInt(aviaFee?.toString().replace(/ /g, ''), 10),
      };
      remainingDebt -= amortizationEachMonth;
      paymentsArray.push(paymentObject);
    }

    return paymentsArray;
  };

  const setAnnuityPayments = () => {
    const decimalInterest = parseFloat(interestRate, 10) / 100;
    const monthlyRate = decimalInterest / 12;
    const totalAmountOfMonths = parseInt(repaymentTime, 10) * 12;
    const paymentsArray = [];
    let remainingDebt = parseFloat(totalAmount, 10);

    for (let i = 1; i < totalAmountOfMonths + 1; i++) {
      const interest = remainingDebt * monthlyRate;
      const r = 1 / (1 + monthlyRate);
      const paymentBeforeFees = remainingDebt / ((1 - Math.pow(r, totalAmountOfMonths + 2 - i)) / (1 - r) - 1);
      const amort = paymentBeforeFees - interest;
      const paymentObject = {
        month: i,
        debtBeforeAmortization: remainingDebt,
        amortization: amort,
        interestCost: interest,
        fees: parseInt(aviaFee?.toString().replace(/ /g, ''), 10),
      };
      remainingDebt -= amort;
      paymentsArray.push(paymentObject);
    }

    return paymentsArray;
  };

  useEffect(() => {
    if (amortizationType === 'annuity') {
      setPayments(setAnnuityPayments());
    } else if (amortizationType === 'straight') {
      setPayments(setStraightLinePayments());
    }
  }, [repaymentTime, aviaFee, setUpFee, interestRate, totalAmount, amortizationType]);

  const getTotalCostForLoan = () => Math.round(
    payments.reduce(
      (acc, item) => acc + (Math.round(item.fees) + Math.round(item.interestCost)),
      0,
    ) + parseInt(setUpFee.toString().replace(/ /g, ''), 10),
  );

  const formatedPaymentsForApr = () => {
    const summedPayments = payments.map((obj) => {
      const { amortization, fees, interestCost } = obj;
      const total = amortization + fees + interestCost;

      return total;
    });

    return summedPayments;
  };

  const getAPR = () => {
    const npv = (cashFlow, apr) => {
      let NPV = 0;
      for (let i = 0; i < cashFlow.length; i += 1) {
        NPV += cashFlow[i] / Math.pow(1 + apr, i);
      }
      return NPV;
    };

    const cashFlow = [
      -(
        parseInt(totalAmount, 10) - parseInt(setUpFee.toString().replace(/ /g, ''), 10)
      ),
    ].concat(formatedPaymentsForApr());
    let diff = 1;
    let x1 = 0;
    let x2 = interestRate;
    let i = 0;
    while (diff > 0.00001) {
      const npv1 = npv(cashFlow, x1);
      const npv2 = npv(cashFlow, x2);
      const x3 = Math.max(0, x2 - npv2 * ((x2 - x1) / (npv2 - npv1)));
      if (Number.isNaN(x3)) {
        return null;
      }
      diff = Math.abs(x3 - x1);
      x1 = x2;
      x2 = x3;

      i += 1;
      if (i > 1000) {
        return null;
      }
    }

    return Math.pow(1 + x2, 12) - 1;
  };

  const tooltipObjects = (message) => {
    const tooltipObject = tooltipToObject(message);
    tooltipObject.text = formatMessage(message.text, {
      a: WebsiteLink,
    });
    return (
      tooltipObject);
  };

  const isVisible = () => !!payments.length
    && !!repaymentTime
    && !!aviaFee
    && !!setUpFee
    && !!interestRate
    && !!totalAmount
    && parseFloat(interestRate, 10) > 0;

  return (
    isVisible() && (
      <>
        {brand !== 'omalaina'
      && (
      <>
        <Wrapper>
          <Tooltips tooltipObject={tooltipObjects(loanCalculatorAmortizationTypeTooltip,
          )}
          />
          <ElementKeyTitle
            justifyContent="center"
          >
            {formatMessage(loanCalculatorAmortizationTypeLabel)}
          </ElementKeyTitle>
        </Wrapper>
        <ButtonWrapper>
          <StyledButton
            onClick={() => setAmortizationType('annuity')}
            type="button"
            key="0"
            tabIndex="0"
            layout="toggle"
            selected={amortizationType === 'annuity'}
            borderWidth={style.loanCalculatorBorderWidth}
          >
            {formatMessage(loanCalculatorAmortizationTypeAnnuity)}
          </StyledButton>
          <StyledButton
            onClick={() => setAmortizationType('straight')}
            type="button"
            key="1"
            tabIndex="0"
            layout="toggle"
            selected={amortizationType === 'straight'}
            borderWidth={style.loanCalculatorBorderWidth}
          >
            {formatMessage(loanCalculatorAmortizationTypeStraight)}
          </StyledButton>
        </ButtonWrapper>
      </>
      )}
        <Wrapper>
          <Tooltips tooltipObject={tooltipObjects(loanCalculatorTotalCostsForLoanTooltip)} />

          <Element>
            <ElementKey>{formatMessage(loanCalculatorTotalCostsForLoan)}</ElementKey>
            <ElementValue>
              {addThousandSeparator(getTotalCostForLoan() + parseInt(totalAmount, 10))}
              {' '}
              {formatMessage(currencySuffix)}
            </ElementValue>
          </Element>
        </Wrapper>
        <Wrapper>
          <Tooltips tooltipObject={tooltipObjects(loanCalculatorTotalCostsAndInterestAndFeesForLoanTooltip)} />

          <Element>
            <ElementKey>
              {formatMessage(loanCalculatorTotalCostsAndInterestAndFeesForLoan)}
            </ElementKey>
            <ElementValue>
              {addThousandSeparator(getTotalCostForLoan())}
              {' '}
              {formatMessage(currencySuffix)}
            </ElementValue>
          </Element>
        </Wrapper>
        <Wrapper>
          <Tooltips tooltipObject={tooltipObjects(loanCalculatorMonthlyCostTooltip)} />

          <Element>
            <ElementKey>
              {amortizationType === 'straight'
                ? formatMessage(loanCalculatorMonthlyCostMonthOne)
                : formatMessage(loanCalculatorMonthlyCost)}
            </ElementKey>
            <ElementValue>
              {addThousandSeparator(
                Math.round(
                  payments[0].amortization + payments[0].fees + payments[0].interestCost,
                ),
              )}
              {' '}
              {formatMessage(currencySuffix)}
            </ElementValue>
          </Element>
          {(loanCalculatorInterest
     && (
     <Wrapper>
       <Tooltips tooltipObject={tooltipObjects(loanCalculatorInterestRateTooltip)} />

       <Element>
         <ElementKey>
           {formatMessage(loanCalculatorInterest)}
         </ElementKey>
         <ElementValue>
           {parseFloat(((Math.round(getAPR() * 10000) / 10000) * 100).toFixed(2))}
           {' '}
           %
         </ElementValue>
       </Element>
     </Wrapper>
     ))}
        </Wrapper>
        <ButtonContainer>
          <ElementButton onClick={() => setShowTable(true)}>
            {formatMessage(loanCalculatorMonthlyCostOverviewButtonText)}
          </ElementButton>
        </ButtonContainer>
        {showTable && (
          <SummaryTable
            effectiveInterest={((Math.round(getAPR() * 10000) / 10000) * 100).toFixed(2)}
            monthlyPayments={payments}
            totalCostsForLoan={addThousandSeparator(getTotalCostForLoan())}
            setUpFee={parseInt(setUpFee.toString().replace(/ /g, ''), 10)}
            setShowTable={setShowTable}
            loanCalculatorMonthlyCost={formatMessage(loanCalculatorMonthlyCost)}
            loanCalculatorSummaryTablePageDescription={formatMessage(
              loanCalculatorSummaryTablePageDescription,
            )}
            loanCalculatorEffectiveInterest={formatMessage(
              loanCalculatorEffectiveInterest,
            )}
            loanCalculatorSummaryTableColumnMonth={formatMessage(
              loanCalculatorSummaryTableColumnMonth,
            )}
            loanCalculatorSummaryTableColumnDebtBeforeAmortization={formatMessage(
              loanCalculatorSummaryTableColumnDebtBeforeAmortization,
            )}
            loanCalculatorSummaryTableColumnAmortization={formatMessage(
              loanCalculatorSummaryTableColumnAmortization,
            )}
            loanCalculatorSummaryTableColumnInterestCost={formatMessage(
              loanCalculatorSummaryTableColumnInterestCost,
            )}
            loanCalculatorSummaryTableColumnFeeCost={formatMessage(
              loanCalculatorSummaryTableColumnFeeCost,
            )}
            loanCalculatorSummaryTableColumnToPay={formatMessage(
              loanCalculatorSummaryTableColumnToPay,
            )}
            loanCalculatorTotalCostsForLoan={formatMessage(
              loanCalculatorTotalCostsForLoan,
            )}
            currencySuffix={formatMessage(currencySuffix)}
          />
        )}
      </>
    )
  );
};

NewLoanCalculatorSummary.propTypes = {
  data: {
    loanCalculatorAmortizationTypeLabel: PropTypes.string,
    loanCalculatorAmortizationTypeAnnuity: PropTypes.string,
    loanCalculatorAmortizationTypeStraight: PropTypes.string,
    loanCalculatorAmortizationTypeTooltip: PropTypes.string,
    loanCalculatorInterestRateTooltip: PropTypes.string,
    loanCalculatorTotalCostsForLoan: PropTypes.string,
    loanCalculatorTotalCostsForLoanTooltip: PropTypes.string,
    loanCalculatorTotalCostsAndInterestAndFeesForLoan: PropTypes.string,
    loanCalculatorTotalCostsAndInterestAndFeesForLoanTooltip: PropTypes.string,
    loanCalculatorMonthlyCostTooltip: PropTypes.string,
    loanCalculatorMonthlyCostOverviewButtonText: PropTypes.string,
    loanCalculatorMonthlyCost: PropTypes.string,
    loanCalculatorSummaryTablePageDescription: PropTypes.string,
    loanCalculatorEffectiveInterest: PropTypes.string,
    loanCalculatorSummaryTableColumnMonth: PropTypes.string,
    loanCalculatorSummaryTableColumnDebtBeforeAmortization: PropTypes.string,
    loanCalculatorSummaryTableColumnAmortization: PropTypes.string,
    loanCalculatorSummaryTableColumnInterestCost: PropTypes.string,
    loanCalculatorSummaryTableColumnFeeCost: PropTypes.string,
    loanCalculatorSummaryTableColumnToPay: PropTypes.string,
    loanCalculatorMonthlyCostMonthOne: PropTypes.string,
    currencySuffix: PropTypes.string,
    loanCalculatorInterest: PropTypes.string,
  },
};

NewLoanCalculatorSummary.defaultProps = {
  data: {
    loanCalculatorAmortizationTypeLabel: '',
    loanCalculatorAmortizationTypeAnnuity: '',
    loanCalculatorAmortizationTypeStraight: '',
    loanCalculatorAmortizationTypeTooltip: '',
    loanCalculatorTotalCostsForLoan: '',
    loanCalculatorTotalCostsForLoanTooltip: '',
    loanCalculatorTotalCostsAndInterestAndFeesForLoan: '',
    loanCalculatorTotalCostsAndInterestAndFeesForLoanTooltip: '',
    loanCalculatorMonthlyCostTooltip: '',
    loanCalculatorMonthlyCostOverviewButtonText: '',
    loanCalculatorMonthlyCost: '',
    loanCalculatorSummaryTablePageDescription: '',
    loanCalculatorEffectiveInterest: '',
    loanCalculatorSummaryTableColumnMonth: '',
    loanCalculatorSummaryTableColumnDebtBeforeAmortization: '',
    loanCalculatorSummaryTableColumnAmortization: '',
    loanCalculatorSummaryTableColumnInterestCost: '',
    loanCalculatorSummaryTableColumnFeeCost: '',
    loanCalculatorSummaryTableColumnToPay: '',
    loanCalculatorMonthlyCostMonthOne: '',
    currencySuffix: '',
    loanCalculatorInterest: '',
    loanCalculatorInterestRateTooltip: '',
  },
};

export default injectIntl(NewLoanCalculatorSummary);
