export default {
  number: '+46770175050',
  display: '0770 - 17 50 50',
  currency: 'SEK',
  tracking: {
    googleAnalyticsId: 'UA-49055014-4',
    hotjarId: 1251391,
    facebookIds: [
      '340735899953925',
    ],
  },
};
