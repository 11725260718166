// external
import addYears from 'date-fns/addYears';
import format from 'date-fns/format';
import isValid from 'date-fns/isValid';
import isBefore from 'date-fns/isBefore';
import getYear from 'date-fns/getYear';
import add from 'date-fns/add';
import { region } from '../localizations/current-locale';
import { dynamicBrand } from '../utils/brand';

// internal
import { valid, invalid } from '../utils/validators/resolutions';

let messages;
import(`../utils/text-resources/${dynamicBrand()}/${region}/messages`).then((m) => {
  messages = m.default;
});

export const validateEmploymentToYear = (yearInput = '') => {
  if (!/\S+/.test(yearInput || '')) {
    return invalid(messages.employedToRequiredYear);
  }

  const nextYear = format(addYears(Date.now(), 1), 'yyyy');

  if (!/^(\d+)$/.test(yearInput)) {
    return invalid(messages.employedToUseOnlyNumbers, { nextYear });
  }

  const year = parseInt(yearInput, 10);

  if (year < 1000) {
    return invalid(messages.employedToFormat, { nextYear });
  }

  if (year >= getYear(add(Date.now(), { years: 80 }))) {
    return invalid(messages.employedToDateTooFarInTheFuture);
  }

  const employmentToYear = addYears(new Date(year, 0), 1);

  if (!isValid(employmentToYear)) {
    return invalid(messages.employedToFormat, { nextYear });
  }

  if (isBefore(employmentToYear, Date.now())) {
    return invalid(messages.employedToDateIsInThePast);
  }

  return valid();
};

export const validateEmploymentToMonth = (yearInput = '', monthInput = '') => {
  if (!/\S+/.test(yearInput || '')) {
    return invalid(messages.employedToRequiredMonth);
  }

  if (!/\S+/.test(monthInput || '')) {
    return invalid(messages.employedToRequiredMonth);
  }

  const year = parseInt(yearInput, 10);
  const month = parseInt(monthInput, 10);
  const employmentTo = new Date(year, month);

  if (isBefore(employmentTo, Date.now())) {
    return invalid(messages.employedToDateIsInThePast);
  }

  return valid();
};
