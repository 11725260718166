let clientId;

export const generateClientId = () => {
  if (!clientId) {
    clientId = Math.random().toString(36).substr(2, 9);
  }

  return clientId;
};

export const getClientId = () => generateClientId();
