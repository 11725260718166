const SECOND_IN_MS = 1000;
const MINUTE_IN_S = 60;
const MINUTE_IN_MS = MINUTE_IN_S * SECOND_IN_MS;

export const MINIMUM_ONLINE_POLL_INTERVAL = 3 * SECOND_IN_MS;
export const MINIMUM_BIDDING_POLLING_INTERVAL = 1 * SECOND_IN_MS;
export const MAXIMUM_BIDDING_POLLING_INTERVAL = MINUTE_IN_MS;
export const BIDDING_POLLING_DECREASE_THRESHOLD = 4 * MINUTE_IN_MS;
export const INITIAL_APPLICATION_POLLING_INTERVAL = 1 * SECOND_IN_MS;
export const MAXIMUM_APPLICATION_POLLING_INTERVAL = 1 * MINUTE_IN_MS;
export const EXTEND_TIMEOUT_POLLING_INTERVAL = 2 * MINUTE_IN_MS;

export const MINIMUM_ACCEPTED_BID_POLLING_INTERVAL = SECOND_IN_MS;
export const ACCEPTED_BID_POLLING_TIMEOUT = 7 * SECOND_IN_MS;

export const SHORT_EXPIRY_TIME = 14;
