import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { PHONE } from '../../js/localizations/locale-data';
import devices from '../../js/styles/Devices';
import { brand, region } from '../../js/localizations/current-locale';
/* eslint-disable-next-line import/no-extraneous-dependencies */
import intlShape from '../../js/schemas/intl';
import messageDescriptorSchema from '../../js/schemas/message-descriptor';
import getElementOffsetPosition from '../../js/utils/get-element-offset-position';
import TextInput from './TextInput';
import { dynamicBrand } from '../../js/utils/brand';

let messages;
import(`../../js/utils/text-resources/${dynamicBrand()}/${region}/messages`).then((m) => {
  messages = m.default;
});

const ErrorWrapper = styled.div`
  && {
    background: var(--error-bg);
    margin-top: -1rem;
    margin-bottom: 1rem;
    border-radius: 5px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    color: var(--white);
    padding: 0.5em;
    hyphens: auto;
    word-break: break-word;
    z-index: 2;
    position: relative;
    transform: translateY(-5px);

    ${brand === 'omalaina' && css`
      @media ${devices.upFromTablet} {
       max-width: ${(props) => (props.wrapperWidth ? '100%' : '14rem')};
      }

    @media ${devices.upFromSmallDesktop} {
      max-width: ${(props) => (props.wrapperWidth ? '100%' : '15.125rem')};
    }
    `}

    ${(props) => props.emailSubmissionAlert && css`
     background: var(--mpc);
    `}
  }
`;

const SubmitErrorWrapper = styled.div`
  && {
    border-radius: 5px;
    padding: 1.25em;
    hyphens: auto;
    word-break: break-word;
    z-index: 2;
    position: relative;
    transform: translateY(-5px);
    border: 1px solid black;
    background-color: var(--white);

    ${brand === 'omalaina' && css`
      max-width: 100%;
    `}
  }
`;

const SubmitErrorTitle = styled.p`
  font-size: 16px;
  font-weight: 700;

`;

const CustomerSupportButton = styled.button`
  && {
    border: none;
    cursor: pointer;
    line-height: 0;
    text-align: center;
    text-transform: uppercase;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    padding: 1.5rem;
    color: var(--black);
    width: 100%;
    background: var(--cta-color);
    border-radius: 4px;
    margin-top: 1rem;

    ${brand === 'omalaina' && css`
      background: var(--orange);
      color: var(--white);
    `}

    ${brand === 'rahalaitos' && css`
      background: var(--green);
      color: var(--white);
    `}
  }
`;

const customerSupportSites = [
  'fi-omalaina',
  'fi-rahalaitos',
];
const shouldDisplayCustomerSupportButton = customerSupportSites.includes(`${region.toLowerCase()}-${brand.toLowerCase()}`);

const Alert = ({
  type,
  display,
  intl: { formatMessage },
  children,
  emailSubmissionAlert,
}) => {
  const shouldDangerouslySetInnerHTML = typeof display === 'string';
  const errorName = useSelector((state) => state.form.application?.error?.errorName);

  useEffect(() => {
    if (type === 'error') {
      if (typeof window.hj === 'function') {
        window.hj('tagRecording', ['Form Error']);
      }
      if (window.document.querySelector('.fullview')) {
        window.document.querySelector('#error,#neutral').scrollIntoView();
        window.document.querySelector('.fullview').scrollTop -= 145;
      } else {
        window.scrollTo({
          top: getElementOffsetPosition(window.document.querySelector('#error,#neutral')).top - 145,
        });
      }
    }
  }, [type]);

  const displayAlert = (
    typeof display === 'string'
    || display.constructor.name === 'Array'
  )
    ? display
    : formatMessage(display.message, display.values);

  /* eslint-disable react/no-danger */

  if (errorName && errorName === 'ApplicantHasRecentApplicationError'
    && shouldDisplayCustomerSupportButton) {
    return (
      <SubmitErrorWrapper
        id={type}
      >
        <SubmitErrorTitle>{formatMessage(messages.errorMessageTitle)}</SubmitErrorTitle>
        {shouldDangerouslySetInnerHTML && (
        <span dangerouslySetInnerHTML={{ __html: displayAlert }} />
        )}

        {!shouldDangerouslySetInnerHTML && displayAlert}

        {children}
        {
        shouldDisplayCustomerSupportButton
          && <CustomerSupportButton>{PHONE.DISPLAY}</CustomerSupportButton>
        }
      </SubmitErrorWrapper>
    );
  }
  return (
    <ErrorWrapper
      wrapperWidth={TextInput?.wrapperWidth}
      emailSubmissionAlert={emailSubmissionAlert}
      id={type}
    >
      {shouldDangerouslySetInnerHTML && (
        <span dangerouslySetInnerHTML={{ __html: displayAlert }} />
      )}

      {!shouldDangerouslySetInnerHTML && displayAlert}

      {children}
    </ErrorWrapper>
  );
  /* eslint-enable react/no-danger */
};

Alert.propTypes = {
  type: PropTypes.oneOf([
    'error',
    'warning',
    'info',
    'neutral',
    'dark-grey',
  ]),
  display: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      message: messageDescriptorSchema,
      values: PropTypes.shape(),
    }),
  ]).isRequired,
  intl: intlShape.isRequired,
  children: PropTypes.node,
  emailSubmissionAlert: PropTypes.bool,
};

Alert.defaultProps = {
  type: 'error',
  children: null,
  emailSubmissionAlert: false,
};

export default injectIntl(Alert);
