import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import QRCode from 'react-qr-code';

import intlShape from '../../../js/schemas/intl';
import BankIdAnimationLogo from '../../../js/images/advisa-bank-id-animation-bankid.svg';
import BankIdOvalAnimationLogo from '../../../js/images/advisa-bank-id-animation-oval.svg';
import SectionWrapper from '../../common/SectionWrapper';
import devices from '../../../js/styles/Devices';
import getDeviceType from '../../utils/device-type';
import useDevice from '../../../js/utils/use-device';

const messages = defineMessages({
  closeButtonLabel: {
    id: 'signingInProgress.bankId.closeButtonLabel',
    defaultMessage: 'Cancel signing',
    description: 'Aria label for the cancel cross for bankId signing process',
  },
  startBankIdApp: {
    id: 'signingInProgress.bankId.startBankIdApp',
    defaultMessage: 'Start your BankID-app',
    description:
      'Describing title of the bankid loading state awaiting identification to be succeeded.',
  },
  openBankIdButtonText: {
    id: 'signingInProgress.bankId.openBankIdButtonText',
    defaultMessage: 'Open your BankID',
    description:
      'Text for the open bank id button',
  },
  openButtonLabel: {
    id: 'signingInProgress.bankId.openBankIdButtonLabel',
    defaultMessage: 'Open bank id app with button above',
    description: 'Label for open bank id button',
  },
  cancelSigningButtonText: {
    id: 'signingInProgress.bankId.cancelSigningButtonText',
    defaultMessage: 'Cancel',
    description: 'Text for cancel bank id signing button',
  },
});

const Wrapper = styled.div`
  && {
    font-family: var(--font-family);
    padding: 1.2rem 3rem 1.5rem;
    margin: ${(props) => props.marginTop} auto auto;
    max-width: 30rem;
    text-align: center;
    background: var(--white);
    position: relative;
    border-radius: 0.5rem;

    h1 {
      font-family: var(--title-font);
    }
  }
`;

const CancelSigningButton = styled.a`
  && {
    text-decoration: underline;
    color: black;
    cursor: pointer;
  }
`;

const Title = styled.div`
  && {
    font-weight: bold;
    font-size: 1.2rem;
  }
`;

const LogoContainer = styled.div`
  && {
    position: relative;
    padding: 2rem 0;

    @media ${devices.upFromTablet} {
      padding: 2rem 0;
    }
  }
`;

const Spinner = styled(BankIdOvalAnimationLogo)`
  && {
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 13.5rem;
    color: #173C4D;
    animation: spin 1200ms cubic-bezier(0.5, 0.25, 0.5, 0.8) infinite;
    width: 11rem;

    @media ${devices.upFromTablet} {
      max-width: 19rem;
    }
  }
`;

const OpenBankIdButton = styled.button`
  && {
    border: none;
    cursor: pointer;
    line-height: 0;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    min-width: 12.5rem;
    padding: 2rem;
    color: var(--white);
    width: 100%;
    background: var(--cta-color);
    border-radius: 4px;
    height: 2rem;
    margin-bottom: 1rem;

    &:disabled {
      opacity: 0.4;
      pointer-events: none;
    }
  }
`;

const SigningProgressBlockWrapper = styled(SectionWrapper)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const SigningInProgress = ({
  onClose,
  intl: { formatMessage },
  fontFamily,
  autoStartToken,
  marginTop,
  qrData,
}) => {
  const {
    isLargeMobile,
  } = useDevice();
  const openBankId = () => {
    const deviceType = getDeviceType();
    if (deviceType === 'ios') {
      window.location.href = `https://app.bankid.com/?autostarttoken=${autoStartToken}&redirect=https://advisa.se/returning-application/`;
    }
    if (deviceType === 'ipad') {
      window.location.href = `https://app.bankid.com/?autostarttoken=${autoStartToken}&redirect=https://advisa.se/returning-application/`;
    }
    if (deviceType === 'mobile') {
      window.location.href = `https://app.bankid.com/?autostarttoken=${autoStartToken}&redirect=null`;
    }
    if (deviceType === 'tablet') {
      window.location.href = `https://app.bankid.com/?autostarttoken=${autoStartToken}&redirect=null`;
    }
    if (deviceType === 'desktop') {
      window.location.href = `bankid:///?autostarttoken=${autoStartToken}&redirect=null`;
    }
  };

  if (isLargeMobile) {
    return (
      <SigningProgressBlockWrapper noContainer>
        <Wrapper
          fontFamily={fontFamily}
          marginTop={marginTop}
        >
          <Title>{formatMessage(messages.startBankIdApp)}</Title>
          <LogoContainer>
            <BankIdAnimationLogo />
            <Spinner />
          </LogoContainer>
          <OpenBankIdButton onClick={openBankId}>
            {formatMessage(messages.openBankIdButtonText)}
          </OpenBankIdButton>
          <CancelSigningButton
            onClick={onClose}
          >
            {formatMessage(messages.cancelSigningButtonText)}
          </CancelSigningButton>
        </Wrapper>
      </SigningProgressBlockWrapper>
    );
  } if (qrData) {
    return (
      <SigningProgressBlockWrapper noContainer>
        <Wrapper
          fontFamily={fontFamily}
          marginTop={marginTop}
        >
          <Title>{formatMessage(messages.startBankIdApp)}</Title>
          <LogoContainer>
            <QRCode value={qrData} />
          </LogoContainer>
          <OpenBankIdButton onClick={openBankId}>
            {formatMessage(messages.openBankIdButtonText)}
          </OpenBankIdButton>

          <CancelSigningButton
            onClick={onClose}
          >
            {formatMessage(messages.cancelSigningButtonText)}
          </CancelSigningButton>
        </Wrapper>
      </SigningProgressBlockWrapper>
    );
  }
  return (
    <SigningProgressBlockWrapper noContainer>
      <Wrapper
        fontFamily={fontFamily}
        marginTop={marginTop}
      >
        <LogoContainer>
          <Spinner />
        </LogoContainer>
      </Wrapper>
    </SigningProgressBlockWrapper>
  );
};

SigningInProgress.propTypes = {
  onClose: PropTypes.func,
  intl: intlShape.isRequired,
  fontFamily: PropTypes.string,
  autoStartToken: PropTypes.string,
  marginTop: PropTypes.string,
  qrData: PropTypes.string,
};
SigningInProgress.defaultProps = {
  onClose: null,
  fontFamily: '',
  autoStartToken: '',
  marginTop: '6rem',
  qrData: null,
};

export default injectIntl(SigningInProgress);
