// external
import { region } from '../localizations/current-locale';
import { dynamicBrand } from '../utils/brand';

// internal
import { valid, invalid } from '../utils/validators/resolutions';

let messages;
import(`../utils/text-resources/${dynamicBrand()}/${region}/messages`).then((m) => {
  messages = m.default;
});

const validateEmployerName = (input = '', ...props) => {
  if (!/\S+/.test(input || '')) {
    return (props[2] === 'applicant.employerName'
      ? invalid(messages.employerNameRequired)
      : invalid(messages.coApplicantEmployerNameRequired));
  }

  return valid();
};

export default validateEmployerName;
