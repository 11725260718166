import React, { useEffect } from 'react';
import { Provider } from 'react-redux';

import { WalletProvider } from '@advisa/wallet';
import { MantineProvider } from '@mantine/core';
import { ViewportProvider } from './viewport';

import appPackage from '../../../package';

import { FloatingFormProvider } from '../../context/floating-form';
import store from '../store';
import ErrorBoundary from './error-boundary';
import initLogging from '../../../sentry-config';

import '@mantine/core/styles.css';

import { brand as importedBrand } from '../localizations/current-locale';

let brand = importedBrand;
if (brand === 'lanaPengarna') {
  brand = 'advisa';
}

const capitalizedBrand = brand.charAt(0).toUpperCase() + brand.slice(1);
const appVersion = appPackage.version;

const initializeSentry = () => {
  initLogging(appVersion);
};

const waitForCookiebot = (callback) => {
  const intervalId = setInterval(() => {
    if (window.Cookiebot) {
      clearInterval(intervalId);
      callback();
    }
  }, 100);
};

// eslint-disable-next-line react/prop-types
const wrapWithProvider = () => ({ element }) => {
  useEffect(() => {
    waitForCookiebot(() => {
      if (window.Cookiebot?.consent?.statistics) {
        initializeSentry();
      }
      const handleConsentChange = () => {
        if (window.Cookiebot?.consent?.statistics) {
          initializeSentry();
        }
      };
      window.addEventListener('CookiebotOnAccept', handleConsentChange);
      return () => {
        window.removeEventListener('CookiebotOnAccept', handleConsentChange);
      };
    });
  }, []);

  return (
    <MantineProvider>
      <WalletProvider brand={capitalizedBrand}>
        <ViewportProvider>
          <FloatingFormProvider>
            <Provider store={store}>
              <ErrorBoundary>
                {element}
              </ErrorBoundary>
            </Provider>
          </FloatingFormProvider>
        </ViewportProvider>
      </WalletProvider>
    </MantineProvider>
  );
};

export default wrapWithProvider;
