// external
import isValid from 'date-fns/isValid';
import isAfter from 'date-fns/isAfter';

// internal
import { region } from '../localizations/current-locale';
import { dynamicBrand } from '../utils/brand';
import { valid, invalid } from '../utils/validators/resolutions';

let messages;

import(`../utils/text-resources/${dynamicBrand()}/${region}/messages`).then((m) => {
  messages = m.default;
});

export const validateEmploymentSinceYear = (yearInput = '', ...props) => {
  if (!/\S+/.test(yearInput || '')) {
    return (props[2] === 'applicant.employmentSince'
      ? invalid(messages.employedSinceRequiredYear)
      : invalid(messages.coAppEmployedSinceRequiredYear));
  }

  if (!/^(\d+)$/.test(yearInput)) {
    return invalid(messages.employedSinceOnlyNumbers);
  }

  const year = parseInt(yearInput, 10);

  if (year < 1000) {
    return invalid(messages.employedSinceFourDigits);
  }

  if (year < 1900) {
    return (props[2] === 'applicant.employmentSince'
      ? invalid(messages.employedSinceTooFarBack)
      : invalid(messages.coApplicantEmployedSinceTooFarBack));
  }

  const employmentSince = new Date(year, 0);

  if (!isValid(employmentSince)) {
    return invalid(messages.employedSinceFourDigits);
  }

  if (isAfter(employmentSince, Date.now())) {
    return (props[2] === 'applicant.employmentSince'
      ? invalid(messages.employedSinceStartTimeInTheFuture)
      : invalid(messages.coAppEmployedSinceStartTimeInTheFuture));
  }
  return valid();
};

export const validateEmploymentSinceMonth = (yearInput = '', monthInput = '', ...props) => {
  if (!/\S+/.test(yearInput || '')) {
    return (props[1] === 'applicant.employmentSinceMonth'
      ? invalid(messages.employedSinceRequiredMonth)
      : invalid(messages.coAppEmployedSinceRequiredMonth));
  }

  if (!/\S+/.test(monthInput || '')) {
    return invalid(messages.employedSinceRequiredMonth);
  }

  const year = parseInt(yearInput, 10);
  const month = parseInt(monthInput, 10);
  const employmentSince = new Date(year, month);

  if (isAfter(employmentSince, Date.now())) {
    return invalid(messages.employedSinceStartTimeInTheFutureMonth);
  }
  return valid();
};
