import React from 'react';
import { useSelector } from 'react-redux';
import { injectIntl } from 'react-intl';
import styled from 'styled-components';
import { region } from '../../../localizations/current-locale';
import intlShape from '../../../schemas/intl';

const labelIndex = {
  SE: {
    totalAmount: { id: 'SubmitBarFiguresSETotalAmount', defaultMessage: 'Requested loan amount' },
    repaymentTime: { id: 'SubmitBarFiguresSERepaymentTime', defaultMessage: 'Loan period' },
    currency: { id: 'SubmitBarFiguresSECurrency', defaultMessage: 'kr' },
    years: { id: 'SubmitBarFiguresSEYears', defaultMessage: 'years' },
  },
  FI: {
    totalAmount: { id: 'SubmitBarFiguresFITotalAmount', defaultMessage: 'Requested loan amount' },
    repaymentTime: { id: 'SubmitBarFiguresFIRepaymentTime', defaultMessage: 'Loan period' },
    currency: { id: 'SubmitBarFiguresFICurrency', defaultMessage: '€' },
    years: { id: 'SubmitBarFiguresFIYears', defaultMessage: 'years' },
  },
  NO: {
    totalAmount: { id: 'SubmitBarFiguresTotalAmountNO', defaultMessage: 'Requested loan amount' },
    repaymentTime: { id: 'SubmitBarFiguresRepaymentTimeNO', defaultMessage: 'Loan period' },
    currency: { id: 'SubmitBarFiguresCurrencyNO', defaultMessage: 'kr' },
    years: { id: 'SubmitBarFiguresYearsNO', defaultMessage: 'years' },
  },
};

const StyledSubmitBarText = styled.div`
  && {
    margin: 0.2em;
  }
`;

const SubmitBarFigures = ({
  intl: { formatMessage },
}) => {
  const formState = useSelector((state) => state.form.application);
  return (
    <div className="submit-figures">
      <StyledSubmitBarText>
        {formatMessage(labelIndex[region].totalAmount)}
        <h4>
          {formState.values.totalAmount}
          {' '}
          {formatMessage(labelIndex[region].currency)}
        </h4>
      </StyledSubmitBarText>
      &nbsp;
      &nbsp;
      &nbsp;
      &nbsp;
      &nbsp;
      &nbsp;
      <StyledSubmitBarText>
        {formatMessage(labelIndex[region].repaymentTime)}
        <h4>
          {formState.values.repaymentTime}
          {' '}
          {formatMessage(labelIndex[region].years)}
        </h4>
      </StyledSubmitBarText>
    </div>
  );
};

SubmitBarFigures.propTypes = {
  intl: intlShape.isRequired,
};

SubmitBarFigures.defaultProps = {};

const SubmitBarFiguresWithIntl = injectIntl(SubmitBarFigures);

export default SubmitBarFiguresWithIntl;
