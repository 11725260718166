import { actionTypes } from 'redux-form';
import {
  sendEvent,
  isLatestEventLabel,
} from '../../utils/gtm';

const reducer = (state = {}, action) => {
  const errorMessages = [];

  const sendErrorMessage = (message) => {
    if (!isLatestEventLabel(message)) {
      sendEvent({
        event: 'Error',
        eventCategory: 'form-interaction',
        eventAction: 'submit-failure-field',
        eventLabel: message,
      });
    }
  };

  if (action.type === actionTypes.SET_SUBMIT_FAILED) {
    const recursiveSearch = (object = {}, errors) => {
      Object.keys(object).forEach((key) => {
        let item = object[key];
        if (item && item.constructor === Object && !item.message) {
          item = recursiveSearch(item, errors);
        }
        // eslint-disable-next-line no-unused-expressions
        item && item.message?.id && errors.push(item.message.id);
      });
    };

    recursiveSearch(state.syncErrors, errorMessages);

    const filteredErrorMessages = errorMessages.filter((value, index, self) => (
      self.indexOf(value) === index
    ));

    filteredErrorMessages.forEach((item) => sendErrorMessage(item));
  }

  return state;
};

export default reducer;
