import React, { useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { change } from 'redux-form';

import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import styled, { css } from 'styled-components';
import intlShape from '../../../schemas/intl';
import ValidatedForm from '../../../../components/molecules/ValidatedForm';
import LanguageContext from '../../../../context/language';
import style from './style';
import { brand } from '../../../localizations/current-locale';

const LabelWrapper = styled.label`
  && {
    margin: 1rem 0;
    display: flex;
    align-items: center;

    ${(props) => props.lead && css`
      margin: ${style.acceptNewsLetterMargin};
    `}
  }
`;

const Text = styled.p`
  && {
    margin-top: 0;
    cursor: pointer;
    font-size: ${style.checkMarkTextFontSize};
    font-weight: var(--has-no-font-weight);
  }
`;

const Link = styled.a`
  && {
    cursor: pointer;
  }
`;

const AcceptNewsLetter = ({
  data,
  intl: { formatMessage },
}) => {
  const { links } = data;
  const dispatch = useDispatch();

  const changeField = (...args) => change('application', ...args);

  const acceptNewsletter = useSelector((state) => state.form.application.values.acceptNewsletter);

  const { lang } = useContext(LanguageContext);

  const plusLink = (language) => {
    switch (language) {
      case 'sv':
        return links.SV;
      case 'no':
        return links.NO;
      case 'en':
        return links.EN;
      case 'fi':
        return links.FI;
      default:
        return links.SV;
    }
  };
  const disabledPaths = ['preappsms', 'preappemail', 'nopae', 'nopas', 'reapply'];
  const disabledBrands = ['sambla', 'advisa', 'omalaina', 'rahalaitos'];
  let isDisabledPath = false;

  if (typeof window !== 'undefined') {
    window.location.href.split('/').forEach((subPaths) => {
      disabledPaths.forEach((disabledPath) => {
        if (subPaths.includes(disabledPath) && disabledBrands.includes(brand)) {
          isDisabledPath = true;
        }
      });
    });
  }
  useEffect(() => {
    const currentPath = window.location.href;
    if (disabledBrands.includes(brand)
     && disabledPaths.some((path) => currentPath.includes(path))) {
      dispatch(changeField('acceptNewsletter', true));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    !isDisabledPath && (
    <LabelWrapper
      lead={data.lead}
    >
      <ValidatedForm.Checkbox
        data={data}
        type="checkbox"
        checked={acceptNewsletter || true}
        margin="u-margin--none"
      >
        <Text>
          {formatMessage(data.text)}
          {links
          && (
          <Link
            href={plusLink(lang)}
            target="_blank"
            rel="noopener noreferrer"
          >
            {formatMessage(data.linkText)}
          </Link>
          )}
        </Text>
      </ValidatedForm.Checkbox>
    </LabelWrapper>
    )
  );
};

AcceptNewsLetter.propTypes = {
  data: PropTypes.shape(),
  intl: intlShape.isRequired,
};

AcceptNewsLetter.defaultProps = {
  data: {},
};

export default injectIntl(AcceptNewsLetter);
