// external
import { defineMessages } from 'react-intl';
import Config from '../utils/config';

// internal
import { valid, invalid } from '../utils/validators/resolutions';
import stripDelimiter from '../utils/strip-delimiter-from-currency';

const messages = defineMessages({
  otherCostRequired: {
    id: 'validate.fi.OtherCostRequired',
    defaultMessage: 'Please enter your monthly other living cost',
    description: 'Inform the customer that they must pass in a monthly cost for their current home-situation (ex: rent)',
  },
  otherCostOnlyDigits: {
    id: 'validate.fi.OtherCostOnlyDigits',
    defaultMessage: 'Please write your other living cost in numbers only',
    description: 'Inform the customer that they may only pass in digits in the input for the monthly cost of their home-situation',
  },
  otherCostTooHigh: {
    id: 'validate.fi.OtherCostTooHigh',
    defaultMessage: 'Your other living cost cannot be more than {maxCost, number} €/mth for you to apply',
    description: 'Inform the customer that we may unfortunately only accept a monthly cost of "x" and that their cost is to high',
  },
  otherCostTooLow: {
    id: 'validate.fi.OtherCostTooLow',
    defaultMessage: 'Enter monthly cost for your other living costs.',
    description: 'Inform the customer that their passed in monthly cost for their other costs is set to low, for example "0"',
  },
  coApplicantOtherCostRequired: {
    id: 'validate.fi.coApplicantOtherCostRequired',
    defaultMessage: 'Please enter your co applicants monthly other living cost',
    description: 'Inform the customer that they must pass in a monthly cost for their current home-situation (ex: rent)',
  },
  coApplicantOtherCostOnlyDigits: {
    id: 'validate.fi.coApplicantOtherCostOnlyDigits',
    defaultMessage: 'Please write your co applicants other living cost in numbers only',
    description: 'Inform the customer that they may only pass in digits in the input for the monthly cost of their home-situation',
  },
  coApplicantOtherCostTooHigh: {
    id: 'validate.fi.coApplicantOtherCostTooHigh',
    defaultMessage: 'Your co applicants other living cost cannot be more than {maxCost, number} €/mth for you to apply',
    description: 'Inform the customer that we may unfortunately only accept a monthly cost of "x" and that their cost is to high',
  },
  coApplicantOtherCostTooLow: {
    id: 'validate.fi.coApplicantOtherCostTooLow',
    defaultMessage: 'Enter co applicants monthly cost for your other living costs.',
    description: 'Inform the customer that their passed in monthly cost for their other costs is set to low, for example "0"',
  },
});

const validateOtherCost = (input = '', ...props) => {
  const cleanedInput = stripDelimiter(input);
  const config = Config.getValues();

  if (!/\S+/.test(cleanedInput || '')) {
    return (props[2] === 'applicant.otherLivingCost'
      ? invalid(messages.otherCostRequired)
      : invalid(messages.coApplicantOtherCostRequired));
  }

  if (!/^(\d+)$/.test(cleanedInput)) {
    return (props[2] === 'applicant.otherLivingCost'
      ? invalid(messages.otherCostOnlyDigits)
      : invalid(messages.coApplicantOtherCostOnlyDigits));
  }

  const value = parseInt(cleanedInput, 10);

  const maxCost = config.RESTRICTIONS.MAX_OTHER_COST;

  if (value > maxCost) {
    return (props[2] === 'applicant.otherLivingCost'
      ? invalid(messages.otherCostTooHigh, { maxCost })
      : invalid(messages.coApplicantOtherCostTooHigh, { maxCost }));
  }

  const minCost = config.RESTRICTIONS.MIN_OTHER_COST;

  if (value < minCost) {
    return (props[2] === 'applicant.otherLivingCost'
      ? invalid(messages.otherCostTooLow)
      : invalid(messages.coApplicantOtherCostTooLow));
  }

  return valid();
};

export default validateOtherCost;
