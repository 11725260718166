export const GO_TO_SLIDE = 'GO_TO_SLIDE';
export const goToSlide = (slideIndex) => ({
  type: GO_TO_SLIDE,
  payload: slideIndex,
});

export const VISIBLE_FIELD = 'VISIBLE_FIELD';
export const setVisibleField = (section, field, value) => ({
  type: VISIBLE_FIELD,
  payload: {
    section,
    name: field,
    value,
  },
});
