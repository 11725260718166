// external
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { change } from 'redux-form';
import messageDescriptorSchema from '../../schemas/message-descriptor';
import intlShape from '../../schemas/intl';
import ValidatedForm from '../../../components/molecules/ValidatedForm';
import Config from '../../utils/config';

// internal
import totalLoanValidator from '../../validators/totalLoan';
import getConsolidatonAmount from '../../utils/consolidationAmount-select';

const messages = defineMessages({
  placeholderText: {
    id: 'consolidationLoanAmount.placeholder',
    defaultMessage: '0',
    description: 'Default text for "consolidate loan amount"-input',
  },
  labelText: {
    id: 'consolidationLoanAmount.label',
    defaultMessage: 'Loan amount consolidate loans',
    description: 'Label for "consolidate loan amount"-input',
  },
  tooltipText: {
    id: 'consolidationLoanAmount.tooltip',
    defaultMessage:
      "Enter {currency} how much loans/credits you have today, and we'll see if we can lower your existing loan costs.",
    description:
      'Tooltip for "consolidate loan amount"-input informing the applicant to fill in the amount of their current loans and see if we can lower their current costs',
  },
});

const ConsolidationLoanAmountDropdown = ({
  intl,
  placeholderText,
  labelText,
  tooltipText,
  data,
}) => {
  const { CURRENCY, LOAN } = Config.getValues();
  const dispatch = useDispatch();
  const changeField = (...args) => change('application', ...args);
  const totalAmount = useSelector((state) => state.form.application.values.totalAmount);
  const values = useSelector((state) => state.form.application.values.consolidationAmount);
  let consolidationAmount = values;

  if (consolidationAmount > totalAmount || consolidationAmount < LOAN.STEP) {
    consolidationAmount = totalAmount; // eslint-disable-line no-param-reassign
  }

  useEffect(() => {
    dispatch(changeField('consolidationAmount', totalAmount));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalAmount]);

  const consolidationAmountSelect = getConsolidatonAmount(totalAmount);

  return (
    <>
      <ValidatedForm.Select
        data={data}
        placeholder={intl.formatMessage(placeholderText)}
        label={intl.formatMessage(labelText)}
        tooltip={{
          text: intl.formatMessage(tooltipText, { currency: CURRENCY }),
          width: 'fill',
          trigger: 'focus',
        }}
        options={consolidationAmountSelect}
        validate={totalLoanValidator}
      />
    </>
  );
};

ConsolidationLoanAmountDropdown.propTypes = {
  placeholderText: messageDescriptorSchema,
  labelText: messageDescriptorSchema,
  intl: intlShape.isRequired,
  tooltipText: messageDescriptorSchema,
  data: PropTypes.shape(),
  values: PropTypes.shape({}),
};

ConsolidationLoanAmountDropdown.defaultProps = {
  placeholderText: { ...messages.placeholderText },
  labelText: { ...messages.labelText },
  tooltipText: { ...messages.tooltipText },
  data: {},
  values: {},
};

const ConsolidationLoanAmountDropdownWithIntl = injectIntl(ConsolidationLoanAmountDropdown);

export { ConsolidationLoanAmountDropdownWithIntl as ConsolidationLoanAmountDropdown };

export default ConsolidationLoanAmountDropdownWithIntl;
