import ValidatedForm from './ValidatedForm';
import ValidatedFormAutocomplete from './ValidatedFormAutocomplete';
import ValidatedFormCheckbox from './ValidatedFormCheckbox';
import ValidatedFormTextInput from './ValidatedFormTextInput';
import ValidatedFormFeesInput from './ValidatedFormFeesInput';
import ValidatedFormButtonGroup from './ValidatedFormButtonGroup';
import ValidatedFormSlider from './ValidatedFormSlider';
import ValidatedFormSection from './ValidatedFormSection';
import ValidatedFormFieldArray from './ValidatedFormFieldArray';
import ValidatedFormSelect from './ValidatedFormSelect';
import ValidatedFormSubmitButton from './ValidatedFormSubmitButton';
import ValidatedFormInputWithButton from './ValidatedFormInputWithButton';
import ValidatedFormEmailInput from './ValidatedFormEmailInput';
import ValidatedFormAddressAutocomplete from './ValidatedFormAddressAutocomplete';

ValidatedForm.FeesInput = ValidatedFormFeesInput;
ValidatedForm.Autocomplete = ValidatedFormAutocomplete;
ValidatedForm.Checkbox = ValidatedFormCheckbox;
ValidatedForm.TextInput = ValidatedFormTextInput;
ValidatedForm.ButtonGroup = ValidatedFormButtonGroup;
ValidatedForm.Slider = ValidatedFormSlider;
ValidatedForm.Section = ValidatedFormSection;
ValidatedForm.FieldArray = ValidatedFormFieldArray;
ValidatedForm.Select = ValidatedFormSelect;
ValidatedForm.InputWithButton = ValidatedFormInputWithButton;
ValidatedForm.SubmitButton = ValidatedFormSubmitButton;
ValidatedForm.EmailInput = ValidatedFormEmailInput;
ValidatedForm.AddressAutocomplete = ValidatedFormAddressAutocomplete;

export default ValidatedForm;
