import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { defineMessages, injectIntl } from 'react-intl';
import styled from 'styled-components';
import { change } from 'redux-form';

import { skipToken } from '@reduxjs/toolkit/dist/query';
import * as Sentry from '@sentry/browser';
import sizes from '../../styles/Sizes';
import { brand } from '../../localizations/current-locale';
import BankIdButton from '../../../components/atoms/BankIdButton';
import ErrorMessage from '../../../components/molecules/ErrorMessage';

import { nationalId as nationalIdValidator } from '../../validators';
import { loginSign, loginStop } from '../../store/actions/api/auth/login';
import SigningInProgressBlock from '../../../components/blocks/SigningInProgressBlock/SigningInProgressBlock';
import { getFontFamily, getTitleFontFamily } from '../../utils/sectionHelper';
import { sendEvent } from '../../utils/gtm';

import { useGetApplicationQuery } from '../../store/reducers/api-call';
import SIGNING_ERRORS from '../../constants/ErrorsConstants';
import { goToSlide } from '../../store/actions/api/set-generic-value';
import getDeviceType from '../../../components/utils/device-type';
import style from './style';

const messages = defineMessages({
  title: {
    id: 'returningCustomerSlide.heading',
    defaultMessage: 'Have you applied with advisa before?',
    description: 'Heading on the reapply slide',
  },
  subtitle: {
    id: 'returningCustomerSlide.subtitle',
    defaultMessage: 'If you are a returning customer we can fetch your previous details for you so you dont have to fill the form again',
    description: 'Subtitle for the reapply slide',
  },
  startReapplyButtonLabel: {
    id: 'returningCustomerSlide.startReapplyButtonLabel',
    defaultMessage: 'Sign with BankID and fetch details',
    description: 'Label for the start signing button',
  },
  startReapplyButton: {
    id: 'returningCustomerSlide.startReapplyButton',
    defaultMessage: 'Open bankid',
    description: 'Text for the start reapply button',
  },
  startReapplyButtonMobile: {
    id: 'returningCustomerSlide.startReapplyButtonMobile',
    defaultMessage: 'Open bankid on this device',
    description: 'Text for the start reapply button on mobile',
  },
  skipReapplyButton: {
    id: 'returningCustomerSlide.skipReapplyButton',
    defaultMessage: 'Proceed without fetching details',
    description: 'Text for skip reapply button',
  },
  signingTextTop: {
    id: 'signingInProgress.bankId.signingTextTop',
    defaultMessage: 'Verify your identity to fetch the details from your previous application',
    description: 'Text at the top for the signing page',
  },
});

const Wrapper = styled.div`
  && {
    max-width: 45rem;
    text-align: center;
    font-size: 1rem;
    font-family:  var(--font-family);

    h1 {
      font-family: ${(props) => props.fontFamilyTitle};
    }

    p {
      font-size: 1rem;
    }
  }
`;

const Container = styled.section`
  && {
    display: flex;
    position: relative;
    flex-direction: column;
    background: var(--white);
    border-color: var(--white);
    border-radius: ${sizes.defaultBorderRadius};
    font-size: 1rem;
    margin: 1.3rem auto 1rem;
    padding: 1em;
    & label {
      text-align: left;
    }
    &::before {
      position: absolute;
      top: -30px;
      left: 50%;
      width: 0;
      height: 0;
      border: 15px solid var(--transparent);
      border-bottom-color: inherit;
      content: ' ';
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
    }
  }
`;

const ErrorMessageBlock = styled.div`
  && {
    background: ${(props) => props.backgroundColor};
    margin: 2rem auto 2rem;
    border-radius: 0.3rem;
    padding: 1rem;
    color: var(--white);
    max-width: 80%;
    p {
      font-size: 1rem;
    }
    a {
      color: var(--white);
    }
  }
`;

const ReapplyText = styled.div`
  && {
    margin: auto;
  }
`;

const ContinueButtonHighlighted = styled(BankIdButton)`
  && {
    margin-top: 5rem;
    text-decoration: underline;
    cursor: pointer;
  }
`;

const ContinueButtonNonHighlighted = styled.a`
  && {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const SigningTextTop = styled.p`
  && {
    text-align: center;
  }
`;

const ReturningCustomerSlide = ({
  // eslint-disable-next-line react/prop-types
  intl: { formatMessage },
}) => {
  const currentApplicant = useSelector((state) => state.form.application.values.applicant);
  const nationalId = useSelector((state) => state.form.application.values.applicant.nationalId);
  const email = useSelector((state) => state.form.application.values.applicant.email);
  const phoneNumber = useSelector((state) => state.form.application.values.applicant.phoneNumber);
  const changeField = (...args) => change('application', ...args);
  const reApply = true;

  const error = useSelector((state) => state.authBankId.error);
  const token = useSelector((state) => state.authBankId.token);
  const qrCodeData = useSelector((state) => state.authBankId.qrCodeData);
  const cancelToken = useSelector((state) => state.authBankId.cancelToken);
  const loadingAuth = useSelector((state) => state.authBankId.logingWithBankid);
  const currentDeviceType = getDeviceType();

  const isCancel = error === SIGNING_ERRORS.USER_LOGIN_CANCEL;

  const dispatch = useDispatch();
  const {
    data, isSuccess, isError,
  } = useGetApplicationQuery(token === null ? skipToken : { t: token, src: 'reapply' });
  const onGoToSlide = (...args) => dispatch(goToSlide(...args));

  useEffect(() => {
    if (isSuccess) {
      sendEvent({
        event: 'system_login_success',
        content_id: 'reapply',
      });
      dispatch(changeField('applicant', { ...data.applicant, ...currentApplicant }));
      dispatch(changeField('coApplicant', data.coApplicant));
      onGoToSlide({ slideIndex: 2 });
    } else if (isError) {
      onGoToSlide({ slideIndex: 2 });
      dispatch(changeField('applicant', { ...currentApplicant, nationalId: '' }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, isError]);

  useEffect(() => {
    const {
      USER_NOT_FOUND,
      USER_LOGIN_CANCEL,
      STATUS_ERROR,
      START_ERROR,
    } = SIGNING_ERRORS;
    if (error !== START_ERROR) {
      Sentry.setTag('origin', 'reapply');
      let fingerprint;
      switch (error) {
        case USER_NOT_FOUND:
          sendEvent({
            event: 'system_noPreviousApp_failure',
            content_id: 'reapply',
          });
          fingerprint = ['reapply', 'noPreviousApplication'];
          dispatch(changeField('applicant', { ...currentApplicant, nationalId: '' }));
          onGoToSlide({ slideIndex: 2 });
          break;
        case USER_LOGIN_CANCEL:
          sendEvent({
            event: 'user_reapply_cancel',
            content_id: 'reapply',
          });
          fingerprint = ['reapply', 'userLoginCancel'];
          dispatch(changeField('applicant', { ...currentApplicant, nationalId: '' }));
          onGoToSlide({ slideIndex: 2 });
          break;
        case STATUS_ERROR:
          sendEvent({
            event: 'system_login_failure',
            content_id: 'reapply',
          });
          fingerprint = ['reapply', 'generalLoginError'];
          dispatch(changeField('applicant', { ...currentApplicant, nationalId: '' }));
          onGoToSlide({ slideIndex: 2 });
          break;
        case 'failed':
          sendEvent({
            event: 'system_login_failure',
            content_id: 'reapply',
          });
          fingerprint = ['reapply', 'generalLoginErrorFailed'];
          dispatch(changeField('applicant', { ...currentApplicant, nationalId: '' }));
          onGoToSlide({ slideIndex: 2 });
          break;
        default:
          // eslint-disable-next-line
          console.warn('Unhandled error: ', { error });
      }
      Sentry.captureException(error, {
        stacktrace: true,
        fingerprint,
        extra: {
          error,
          payload: {
            email,
            phoneNumber,
          },
        },
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, isError]);

  const openBankId = () => {
    const deviceType = getDeviceType();
    if (deviceType === 'ios') {
      window.location.href = `https://app.bankid.com/?autostarttoken=${token}&redirect=https://advisa.se/returning-application/`;
    }
    if (deviceType === 'ipad') {
      window.location.href = `https://app.bankid.com/?autostarttoken=${token}&redirect=https://advisa.se/returning-application/`;
    }
    if (deviceType === 'mobile') {
      window.location.href = `https://app.bankid.com/?autostarttoken=${token}&redirect=null`;
    }
    if (deviceType === 'tablet') {
      window.location.href = `https://app.bankid.com/?autostarttoken=${token}&redirect=null`;
    }
    if (deviceType === 'desktop') {
      window.location.href = `bankid:///?autostarttoken=${token}&redirect=null`;
    }
  };

  const onSubmit = async () => {
    sendEvent({
      event: 'user_login_click',
      content_id: 'reapply',
    });
    const input = {
      type: 'login',
    };
    openBankId();
    return loginSign({ input, dispatch });
  };

  const closeSignIn = () => {
    loginStop(dispatch, cancelToken);
  };

  const handleContinue = () => {
    sendEvent({
      event: 'user_continueWithoutReapply_click',
      content_id: 'reapply',
    });
    dispatch(changeField('applicant', { ...currentApplicant, nationalId: '' }));
    onGoToSlide({ slideIndex: 2 });
  };

  if (loadingAuth) {
    return (
      <>
        <SigningTextTop>
          {formatMessage(messages.signingTextTop)}
        </SigningTextTop>
        <SigningInProgressBlock
          fontFamily={getFontFamily(
            brand === 'sambla' ? 'samblaBeVietnamPro' : 'advisaRoboto',
          )}
          autoStartToken={token}
          qrData={qrCodeData}
          onClose={closeSignIn}
          marginTop="0"
          hasButtonLabel
        />
      </>
    );
  }

  return (
    <Wrapper
      fontFamilyTitle={getTitleFontFamily(
        brand === 'sambla' ? 'samblaBeVietnamPro' : 'advisaRoboto',
      )}
      fontFamily={getFontFamily(
        brand === 'sambla' ? 'samblaBeVietnamPro' : 'advisaRoboto',
      )}
    >
      <h4>{formatMessage(messages.title)}</h4>
      <p>
        {formatMessage(messages.subtitle)}
        {' '}
      </p>

      <Container>
        <p>{formatMessage(messages.startReapplyButtonLabel)}</p>
        <BankIdButton
          type="submit"
          disabled={
            (error !== 'USER_LOGIN_CANCEL' && error)
            || (nationalId === '')
            || (typeof window !== 'undefined' && nationalIdValidator(nationalId, reApply) !== undefined)
            }
          validatedBrandColor={style.returningCustomerBankIDButtonColor}
          textColor="white"
          onClick={onSubmit}
        >
          <ReapplyText>
            {
            (currentDeviceType === 'ios' || currentDeviceType === 'mobile')
              ? formatMessage(messages.startReapplyButtonMobile)
              : formatMessage(messages.startReapplyButton)
            }
          </ReapplyText>
        </BankIdButton>
      </Container>
      {error && (
        <ErrorMessageBlock
          backgroundColor={isCancel ? 'var(--green)' : 'var(--red)'}
        >
          <ErrorMessage error={error} formatMessage={formatMessage} />
        </ErrorMessageBlock>
      )}
      {
        (error !== 'USER_LOGIN_CANCEL' && error) ? (
          <ContinueButtonHighlighted
            onClick={handleContinue}
            disabled={false}
            validatedBrandColor="#00ffff"
            showLogo={false}
          >
            {formatMessage(messages.skipReapplyButton)}
          </ContinueButtonHighlighted>
        ) : (
          <ContinueButtonNonHighlighted
            onClick={handleContinue}
          >
            {formatMessage(messages.skipReapplyButton)}
          </ContinueButtonNonHighlighted>
        )
      }
    </Wrapper>
  );
};

ReturningCustomerSlide.propTypes = {

};

ReturningCustomerSlide.defaultProps = {

};

export default injectIntl(ReturningCustomerSlide);
