import { SubmissionError, stopSubmit } from 'redux-form';

import logger from './logger';

import messages from './messages';
import normalizeSyncErrors from './normalize-sync-errors';

/**
 * This function will receive the following arguments, however currently
 * we only need to make use of the first and third argument.
 *
 * @param {Object} syncErrors
 * @param {Function} dispatch
 * @param {Object|null} submissionError
 * @param {Object} props
 */
const onSubmitFail = (syncErrors, dispatch, submissionError, { form }) => {
  if (syncErrors && submissionError == null) {
    if (typeof window.hj === 'function') {
      window.hj('tagRecording', ['form-submit-error-frontend']);
    }

    const syncErrorsNormalized = normalizeSyncErrors(syncErrors);
    Object.keys(syncErrorsNormalized).forEach((field) => {
      const id = syncErrorsNormalized[field];
      logger.info('User tried submitting with validation error', {
        sentry: {
          id,
          field,
        },
      });
    });
  }

  if (submissionError
    && submissionError.name !== SubmissionError.name) {
    const errors = {
      _error: {
        message: messages.uncaughtSubmitError,
      },
    };
    const message = 'User could not submit application due to uncaught error';
    const payload = {
      error: submissionError,
      formWideError: errors._error, // eslint-disable-line no-underscore-dangle
    };
    logger.error(message, payload);
    // eslint-disable-next-line no-console
    console.error(message, payload);
    dispatch(stopSubmit(form, errors));
  }
};

export default onSubmitFail;
