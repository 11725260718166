import { defineMessages } from 'react-intl';

const messages = defineMessages({
  genericOnlyNumbers: {
    id: 'validators.genericOnlyNumbers',
    defaultMessage: 'Use numbers please.',
    description: 'Inform the customer/applicant that they are only allowed to use numbers',
  },
  mainApplicant: {
    id: 'applicantsForm.fieldset.applicant',
    defaultMessage: 'Main applicant',
    description: 'The main applicant of the loan',
  },
  coApplicant: {
    id: 'applicantsForm.fieldset.coApplicant',
    defaultMessage: 'Co-applicant',
    description: 'Fellow applicant/co-applicant to the main applicant',
  },
  confirmDeleteCoApplicant: {
    id: 'applicantsForm.alert.confirmDeleteCoApplicant',
    defaultMessage: 'The co-applicant will be removed from the application. Are you sure you want to proceed?',
    description: 'Confirm message asking the customer if they want to proceed with removing a co-applicant',
  },
  addCoApplicantLabel: {
    id: 'applicationForm.label.addCoApplicant',
    defaultMessage: 'Add a co-applicant',
    description: 'Add co-applicant option that the applicant can use if they wish to share their loan with a co-applicant',
  },
  emailSmartSuggestion: {
    id: 'email.smartSuggestion',
    defaultMessage: 'Did you mean',
    description: 'The pre-suggestion helptext at the email input field.',
  },
  applicationFormBetterTerms: {
    id: 'applicationForm.info.betterTerms',
    defaultMessage: 'Having a co-applicant often results in better conditions.',
  },
  applicationFormBetterTermsExperiment: {
    id: 'applicationForm.info.betterTermsExperiment',
    defaultMessage: 'Co-applicants increase the chance of good conditions by <strong>26%</strong>!',
  },
  sameNationalId: {
    id: 'validators.applicationForm.sameNationalId',
    defaultMessage: 'The main applicant and the co-applicant cannot be the same person.',
    description: 'Informing the customer that the applicant and co-applicant can not be the same person/use the same social security number',
  },
  sameEmail: {
    id: 'validators.applicationForm.sameEmail',
    defaultMessage: 'The main applicant and the co-applicant cannot have the same email.',
    description: 'Informing the customer that the applicant and co-applicant can not use the same e-mail',
  },
  samePhoneNumber: {
    id: 'validators.applicationForm.samePhoneNumber',
    defaultMessage: 'The main applicant and the co-applicant cannot have the same phone number.',
    description: 'Informing the customer/applicant that their co-applicant may not have an identical phone number as theirs',
  },
  generalSubmitError: {
    id: 'applicationForm.submitError.general',
    defaultMessage: 'Your application could not be submitted. Customer service is more than happy to help you through the process. Call: <a>{phoneNumber}</a> or click the button below and we will call you as soon as we can on <i>{customerPhoneNumber}</i>',
    description: 'Default-text för back-end fel i formulär.',
  },
  uncaughtSubmitError: {
    id: 'applicationForm.submitError.uncaught',
    defaultMessage: 'For the moment we have technical issues and your application could not be submitted. Please contact customer service for more help',
    description: 'Default-text for when a customer tries to submit their application but there was an error on the client side.',
  },
  generalValidationError: {
    id: 'applicationForm.submitError.generalValidation',
    defaultMessage: 'Some of the fields are not filled in correctly. Please correct these and try to submit again.',
    description: 'Default-text för fel i fält.',
  },
  fieldValidationError: {
    id: 'applicationForm.submitError.fieldValidation',
    defaultMessage: 'This field is not filled in correctly. Please correct it and try submitting again.',
    description: 'Default-text för fel i fält.',
  },
  fieldEmailAlreadyInUseError: {
    id: 'applicationForm.submitError.fieldEmailValidation',
    defaultMessage: 'This email address already belongs to an application.',
    description: 'Field error message informing the customer that the given e-mail is already in use in another application',
  },
  recentNationalIdError: {
    id: 'applicationForm.submitError.recentNationalId',
    defaultMessage: 'You have an ongoing application, to access it — click "Log in" in the menu. To make changes to your application, please call customer service on: <span>{phoneNumber}</span>',
    description: 'Default-text för back-end fel i formulär.',
  },
  emailAlreadyInUseError: {
    id: 'applicationForm.submitError.emailAlreadyInUse',
    defaultMessage: 'The email: {email} is already used in an active application. Please contact customer service.',
    description: 'Form wide error message that informs that a given e-mail already is already in use in another application',
  },
  updateApplicantsSubmitLabel: {
    id: 'applicantsForm.submitButton.label',
    defaultMessage: 'Save!',
    description: 'Default-text for applicants form save button',
  },
  confirmationWarning: {
    id: 'sambla.se.confrimationWarning',
    defaultMessage: 'Do you want to go back to the start?',
    description: 'warning when exiting ablication',
  },
  Yes: {
    id: 'sambla.se.yes',
    defaultMessage: 'Yes',
    description: 'yes',
  },
  No: {
    id: 'sambla.se.no',
    defaultMessage: 'No',
    description: 'No',
  },
  mortgageCarsZero: {
    id: 'mortgage.carsZero',
    defaultMessage: '0',
    description: 'Form button for no cars',
  },
  mortgageCarsOne: {
    id: 'mortgage.carsOne',
    defaultMessage: '1',
    description: 'Form button for one car',
  },
  mortgageCarsTwo: {
    id: 'mortgage.carsTwo',
    defaultMessage: '2',
    description: 'Form button for two cars',
  },
  mortgageCarsThree: {
    id: 'mortgage.carsThree',
    defaultMessage: '3',
    description: 'Form button for three cars',
  },
  mortgageCarsFour: {
    id: 'mortgage.carsFour',
    defaultMessage: '4',
    description: 'Form button for four cars',
  },
  mortgageCarsFive: {
    id: 'mortgage.carsFive',
    defaultMessage: '5+',
    description: 'Form button for five cars',
  },
  mortgageCreditorNameOther: {
    id: 'mortgage.creditorNameOther',
    defaultMessage: 'Other',
    description: 'Creditor name Other',
  },
  invalidEmailWarningMessage: {
    id: 'email.invalidEmailWarningMessage',
    defaultMessage: 'This email seems to be invalid, are you sure you entered the correct one?',
    description: 'The warning text that will show when UseBouncer interpret the email as invalid.',
  },
});

export default messages;
