// external
import { region } from '../localizations/current-locale';
import { dynamicBrand } from '../utils/brand';
import store from '../store';

// internal
import { valid, invalid } from '../utils/validators/resolutions';

let messages;
import(`../utils/text-resources/${dynamicBrand()}/${region}/messages`).then((m) => {
  messages = m.default;
});

export const validateRequiredButtonGroup = (input = '') => {
  if (!input && input !== false) {
    return invalid(messages.buttonGroupIsRequired);
  }

  return valid();
};

export const validateRequiredCheckbox = (checked) => {
  if (!checked) {
    return invalid(messages.checkBoxIsRequired);
  }
  return valid();
};

export const validateRequiredButtonGroupHouseLoanEmpty = (input = '') => {
  const state = store.getState();
  const value = state?.form?.application?.values?.applicant?.currentLoans?.houseLoan?.amount;
  if (!input && input !== false && value) {
    return invalid(messages.buttonGroupIsRequired);
  }
  return valid();
};

export const validateRequiredButtonGroupHouseLoanEmptyCoApp = (input = '') => {
  const state = store.getState();
  const value = state?.form?.application?.values?.coApplicant?.currentLoans?.houseLoan?.amount;
  if (!input && input !== false && value) {
    return invalid(messages.buttonGroupIsRequired);
  }
  return valid();
};

