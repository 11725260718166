import { invalid } from '../../utils/validators/resolutions';
import { normalizePhoneNumber as trimPhoneNumber } from '../../utils/normalize-phone-number';
import { validateEmploymentSinceMonth } from '../employedSince';
import { validateEmploymentToMonth } from '../employedTo';
import messages from '../../utils/messages';

const {
  sameNationalId,
  sameEmail,
  samePhoneNumber,
} = messages;

const validateEmploymentSince = (applicant) => (
  applicant.employmentSince
  && applicant.employmentSinceMonth
  && validateEmploymentSinceMonth(applicant.employmentSince, applicant.employmentSinceMonth)
);

const validateEmploymentTo = (applicant) => (
  applicant.employmentTo
  && applicant.employmentToMonth
  && validateEmploymentToMonth(applicant.employmentTo, applicant.employmentToMonth)
);

const normalizeNationalId = (nationalId) => {
  const normalizedOnlyNumbers = nationalId.replace(/[- ]/, '');
  if (normalizedOnlyNumbers.length > 10) {
    return normalizedOnlyNumbers.substring(2);
  }
  return normalizedOnlyNumbers;
};

const normalizePhoneNumber = (phoneNumber) => trimPhoneNumber(phoneNumber)
  .replace(/^((00|\+)?46)/, '0');

const applicationFormValidator = (values, { error }) => {
  const errors = {};
  const {
    applicant = {},
    coApplicant = {},
  } = values;
  const normalizedApplicantPhoneNumber = normalizePhoneNumber(applicant.phoneNumber || '');
  const normalizedCoApplicantPhoneNumber = normalizePhoneNumber(coApplicant.phoneNumber || '');
  const normalizedApplicantNationalId = normalizeNationalId(applicant.nationalId || '');
  const normalizedCoApplicantNationalId = normalizeNationalId(coApplicant.nationalId || '');

  if (error) {
    // eslint-disable-next-line no-underscore-dangle
    errors._error = error;
  }

  const applicantEmploymentSinceResolution = validateEmploymentSince(applicant);
  if (applicantEmploymentSinceResolution) {
    errors.applicant = {
      ...errors.applicant,
      employmentSinceMonth: applicantEmploymentSinceResolution,
    };
  }

  const coApplicantEmploymentSinceResolution = validateEmploymentSince(coApplicant);
  if (coApplicantEmploymentSinceResolution) {
    errors.coApplicant = {
      ...errors.coApplicant,
      employmentSinceMonth: coApplicantEmploymentSinceResolution,
    };
  }

  const applicantEmploymentToResolution = validateEmploymentTo(applicant);
  if (applicantEmploymentToResolution) {
    errors.applicant = {
      ...errors.applicant,
      employmentToMonth: applicantEmploymentToResolution,
    };
  }

  const coApplicantEmploymentToResolution = validateEmploymentTo(coApplicant);
  if (coApplicantEmploymentToResolution) {
    errors.coApplicant = {
      ...errors.coApplicant,
      employmentToMonth: coApplicantEmploymentToResolution,
    };
  }

  if (normalizedCoApplicantNationalId
    && normalizedApplicantNationalId
    && normalizedCoApplicantNationalId === normalizedApplicantNationalId) {
    errors.coApplicant = {
      ...errors.coApplicant,
      nationalId: invalid(sameNationalId),
    };
  }

  if (coApplicant.email && coApplicant.email === applicant.email) {
    errors.coApplicant = {
      ...errors.coApplicant,
      email: invalid(sameEmail),
    };
  }

  if (normalizedCoApplicantPhoneNumber
    && normalizedApplicantPhoneNumber
    && normalizedCoApplicantPhoneNumber === normalizedApplicantPhoneNumber) {
    errors.coApplicant = {
      ...errors.coApplicant,
      phoneNumber: invalid(samePhoneNumber),
    };
  }

  return errors;
};

export default applicationFormValidator;
