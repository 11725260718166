/* eslint-disable react/prop-types */
/**
 * Validated text-input field to be used in a <ValidatedForm>. Should be used as
 * <ValidatedForm.TextInput>, not imported directly.
 *
 * Usage:
 * ```js
 * <ValidatedForm ...>
 *   <ValidatedForm.TextInput
 *     name='firstName'
 *     validate=[required, number]
 *     warn={max(10)} />
 * </ValidatedForm>
 * ```
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import tooltipSchema from '../../../js/schemas/tooltip';
import track from '../../../js/utils/tracking';

import { addThousandSeparator } from '../../../js/utils/formatters/add-thousand-separator';
import Alert from '../../atoms/Alert';
import TextInput from '../../atoms/TextInput';

const ValidatedFormFeesInput = ({
  className,
  name,
  validate,
  warn,
  compact,
  suffix,
  validateOnRegister,
  onChange,
  tooltip,
  optional,
  convertToUpperCase,
  removeSuffix,
  data,
  ...rest
}) => (
  <Field
    name={data.name}
    className={className}
    // eslint-disable-next-line no-use-before-define
    component={ValidatedTextInput}
    validate={data.validate}
    warn={warn}
    onChange={onChange}
    compact={compact}
    suffix={suffix}
    hasValidateFunction={!!validate}
    validateOnRegister={validateOnRegister}
    optional={optional}
    tooltip={tooltip}
    convertToUpperCase={convertToUpperCase}
    removeSuffix={removeSuffix}
    format={(value) => (data.isNumber ? addThousandSeparator(value) : value)}
    {...rest}
    {...data}
  />
);

ValidatedFormFeesInput.propTypes = {
  className: PropTypes.string,
  /** Used as key in redux. */
  name: PropTypes.string,
  /** Validation function(s). Takes a value and returns an error string or undefined. */
  validate: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.func),
  ]),
  /** Validation function(s). Takes a value and returns a warning string or undefined. */
  warn: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.func),
  ]),
  compact: PropTypes.bool,
  suffix: PropTypes.string,
  validateOnRegister: PropTypes.bool,
  onChange: PropTypes.func,
  tooltip: tooltipSchema,
  optional: PropTypes.bool,
  convertToUpperCase: PropTypes.bool,
  removeSuffix: PropTypes.bool,
};

ValidatedFormFeesInput.defaultProps = {
  className: '',
  validate: null,
  warn: () => {},
  compact: false,
  suffix: '',
  validateOnRegister: false,
  onChange: () => {},
  tooltip: '',
  optional: false,
  convertToUpperCase: undefined,
  removeSuffix: false,
  name: '',
};

export default ValidatedFormFeesInput;

const ValidatedTextInput = ({
  className,
  wrapperClassName,
  input,
  meta: {
    valid,
    touched,
    error,
    warning,
  },
  label,
  optional,
  suffix,
  compact,
  hasValidateFunction,
  validateOnRegister,
  tooltip,
  convertToUpperCase,
  removeSuffix,
  children,
  showErrors,
  ...rest
}) => {
  const inputId = `validated-form-text-input--${input.name}`;
  const shouldValidate = valid || touched || validateOnRegister;
  const isOptional = optional && input.value === '';

  if (touched) {
    track(valid, input);
  }

  if (input.value === undefined) {
    input.value = ''; // eslint-disable-line no-param-reassign
  }

  return (
    <div className={wrapperClassName} data-formfield={input.name}>
      <TextInput
        {...input}
        {...rest}
        id={inputId}
        label={label}
        suffix={suffix}
        compact={compact}
        validated={hasValidateFunction}
        valid={(shouldValidate && !isOptional) ? valid : null}
        className={className}
        tooltip={tooltip}
        value={convertToUpperCase ? input.value.toUpperCase() : input.value}
        removeSuffix={removeSuffix}
      />
      {(shouldValidate || showErrors) && (
        (error && <Alert display={error} />)
        || (warning && <Alert type="warning" display={warning} />)
      )}
      {children}
    </div>

  );
};

export { ValidatedTextInput };
