/* eslint-disable max-classes-per-file */
export class AdvisaError extends Error {
  constructor(message) {
    super(message);
    this.name = this.constructor.name;
    this.stack = (new Error()).stack;
  }
}

export class MissingAuthTokenError extends AdvisaError {}
export class GenericError extends AdvisaError {}
export class PermissionDeniedError extends AdvisaError {}
export class FetchError extends AdvisaError {}
export class AcceptBidError extends AdvisaError {}
export class PpiSigningError extends AdvisaError {}
export class PpiSigningStatusError extends AdvisaError {}
export class CurrentLoansSaveError extends AdvisaError {}
export class LogAcceptedBidDataError extends AdvisaError {}
export class SkipOnHoldError extends AdvisaError {}
export class CompleteTinkError extends AdvisaError {}
