import React from 'react';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';

import defaultLocaleData from '../localizations/default-locale-data';
import Config from './config';

import getLocalization from './getLocalization';

import LanguageContext from '../../context/language';
import localeData, { INTL_FORMATS } from '../localizations/locale-data';
import isClient from './is-client';

Config.instantiate(localeData);
let language = process.env.REACT_APP_LANGUAGE;

if (isClient) {
  const path = window.location.pathname;

  if (path.match(/^\/[a-z]{2}($|\/)/)) {
    const lang = path.split('/')[1];
    language = lang;
  }
}

const LocalizationWrapper = ({ children }) => {
  const currentLocale = getLocalization(language);

  return (
    <LanguageContext.Provider
    // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        lang: currentLocale.lang,
      }}
    >
      <IntlProvider
        locale={currentLocale.lang}
        defaultLocale="sv"
        defaultFormats={defaultLocaleData.INTL_FORMATS}
        formats={INTL_FORMATS}
        messages={currentLocale.messages}
        textComponent="span"
      >
        {children}
      </IntlProvider>
    </LanguageContext.Provider>
  );
};

LocalizationWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

const wrapWithIntl = (Comp) => (props) => (
  <>
    <LocalizationWrapper>
      <Comp {...props} />
    </LocalizationWrapper>
  </>
);

export default wrapWithIntl;
