/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-inner-declarations */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import {
  clearSubmitErrors,
  stopSubmit,
} from 'redux-form';
import styled from 'styled-components';
import { usePageVisibility } from 'react-page-visibility';

import { brand } from '../../localizations/current-locale';
import { resolveStyleImport } from '../../utils/styleResolver';
import useDevice from '../../utils/use-device';
import style from './style';
import devices from '../../styles/Devices';
import intlShape from '../../schemas/intl';
import ValidatedForm from '../../../components/molecules/ValidatedForm';
import submitAdvancedCartAbandonment from '../../mappers/advancedCartAbandonment';
import logger from '../../utils/logger';
import useResizeObserver from '../../utils/use-resize-observer';

const baseUrl = process.env.REACT_APP_API_ROOT_PAPI || process.env.REACT_APP_API_ROOT;
/*
  TODO:
  When using redux-form's default `shouldError` function, the form is not
  validated after a react-router redirect, which results in the `syncErrors`
  object being deleted from the store and all fields become considered valid
  regardless of their values. The quick fix is to always validate the form every
  time something changes in the form store, but this decreases performance. This
  function should be improved to only return `true` when the form should be
  validated.
*/
const shouldError = () => true;

const ApplicationFormWrapper = styled.div`
  && {
    overflow-x: ${(props) => (props.overflowX ? 'hidden' : '')};;
  }
`;

const FormTitle = styled.h2`
  && {
    color: ${style.formTitleColor};
    padding: ${style.formTitlePadding};
    font-weight: 700;
    line-height: 1.125;
    text-align: ${style.titleTextAlign};
    font-family: ${style.titleFontFamily};

    @media ${devices.upFromTablet} {
      font-size: ${style.titleFontSize};
      margin-left: ${style.titleMarginLeft};
      margin-bottom: ${style.marginBottom};
      font-weight: ${style.desktopTitleFontWeight};

    }

    @media ${devices.downFromTablet} {
      text-align: ${style.mobileTitleAlign};
      font-size: ${style.mobileFontsize};
      margin-left: none;
    }

    @media ${devices.downFromMobile} {
      padding: ${style.formTitlePaddingDownTablet};
    }
  }
`;

const FormSubtitle = styled.h2`
  && {
    font-size: 1.2rem;
    line-height: 1.2;
    text-align: left;
    padding: ${style.subtitlePadding};
    color: ${style.subtitleColor};
    font-family: ${style.titleFontFamily};
    font-weight: var(--has-no-font-weight);

    @media ${devices.downFromTablet} {
      text-align: ${style.mobileTitleAlign};
      margin-left: none;
    }

    @media ${devices.upFromTablet} {
      font-size: ${style.subtitleFontSize};
      font-weight: ${style.subtitleFontWeight};
      margin: ${style.subtitleMargin};
      line-height: 1.25;
    }

    @media ${devices.downFromMobile} {
      padding: ${style.subtitlePaddingMobile};
    }
  }
`;

const PureApplicationFormBase = ({
  children,
  form,
  formState,
  initialValues,
  validate,
  onSubmit,
  submitSuccess,
  intl,
  defaultBreakpoints,
  elevated,
  rounded,
  customerIsPEP,
  onTermsClick,
  onIntegrityPolicyClick,
  prefilledApplicationForm,
  partnerForm,
  title,
  type,
  subtitle,
  ...rest
}) => {
  const isVisible = usePageVisibility();
  const currentSlide = useSelector((state) => state.form.genericFormValues.currentSlide);
  const currentFormState = useSelector((state) => state.form.application.values);
  const registeredFields = useSelector((state) => state.form.application.registeredFields);
  const syncErrors = useSelector((state) => state.form.application.syncErrors);
  const [hasRun, setHasRun] = useState(false);
  const [lastSubmittedValue, setLastSubmittedValue] = useState(null);
  const [ref, entry] = useResizeObserver();
  const { isTablet, isMobile, isLargeMobile } = useDevice();
  const isOmalaina = brand === 'omalaina';
  const isRahalaitos = brand === 'rahalaitos';

  useEffect(() => {
    resolveStyleImport(brand);
  });

  useEffect(() => {
    let pollerRetries = 600;

    const goPoller = setInterval(() => {
      clearInterval(goPoller);
      if (pollerRetries <= 0) {
        clearInterval(goPoller);
      } else {
        pollerRetries -= 1;
      }
    }, 100);
  }, []);

  useEffect(() => {
    if (
      typeof currentFormState === 'undefined'
      || !!currentFormState.applicant?.email === false
      || !!currentFormState.applicant?.phoneNumber === false
    ) return;

    const cartAbandonmentFields = {};
    for (const field in registeredFields) {
      let fieldValue = currentFormState;
      const fieldParts = field.split('.');

      for (const part of fieldParts) {
        fieldValue = fieldValue?.[part];
      }

      if (fieldValue === undefined) {
        fieldValue = null;
      }

      let inSyncErrors = false;
      let errorValue = syncErrors;

      for (const part of fieldParts) {
        errorValue = errorValue?.[part];
      }
      if (errorValue !== undefined) {
        inSyncErrors = true;
      }

      if (!inSyncErrors) {
        cartAbandonmentFields[field] = fieldValue;
      }
    }
    cartAbandonmentFields.acceptNewsletter = currentFormState.acceptNewsletter;

    if (!cartAbandonmentFields['applicant.email'] || !cartAbandonmentFields['applicant.phoneNumber']) return;
    let tempShouldRun = false;
    if (JSON.stringify(cartAbandonmentFields) !== lastSubmittedValue) {
      tempShouldRun = true;
    }
    if (isVisible === false
      && (hasRun === false || tempShouldRun === true)
    ) {
      function submitCartAbandonment() {
        fetch(`${baseUrl}/contacts/create-cart-abandonment-contact`, {
          keepalive: true,
          method: 'POST',
          headers: {
            'content-type': 'application/json',
          },
          body: JSON.stringify(submitAdvancedCartAbandonment(cartAbandonmentFields)),
        })
          .then((response) => {
            if (response.ok) {
              setHasRun(true);
              setLastSubmittedValue(JSON.stringify(cartAbandonmentFields));
            } else {
              throw new Error(`Error submitting cart abandonment: ${response.statusText}`);
            }
          })
          .catch((error) => {
            logger.error('An error occurred:', error.message);
          });
      }

      submitCartAbandonment();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  useEffect(() => {
    if (!entry.target) {
      return;
    }

    const breakpoints = entry.target.dataset.breakpoints
      ? JSON.parse(entry.target.dataset.breakpoints)
      : { ...defaultBreakpoints };

    Object.keys(breakpoints).forEach((breakpoint) => {
      const minWidth = breakpoints[breakpoint];

      if (entry.contentRect.width >= minWidth) {
        entry.target.classList.add(breakpoint);
      } else {
        entry.target.classList.remove(breakpoint);
      }
    });
  }, [entry, defaultBreakpoints]);

  return (
    <ApplicationFormWrapper
      overflowX={brand === 'sambla' || brand === 'lanaPengarna'}
    >
      {(currentSlide === 0 && title && ((isMobile || isTablet || isLargeMobile)
      || isOmalaina || isRahalaitos))
        && (
        <div>
          <FormTitle>{title}</FormTitle>
          {(subtitle !== '') && (
          <FormSubtitle>{subtitle}</FormSubtitle>
          )}
        </div>
        )}
      <>
        <ValidatedForm
          form={form}
          initialValues={initialValues}
          validate={validate}
          shouldError={shouldError}
          onSubmit={onSubmit}
          forwardRef={ref}
          onChange={(_, dispatch, props) => {
            // eslint-disable-next-line react/prop-types
            if (props.error) {
              dispatch(clearSubmitErrors(form));
            }
            // eslint-disable-next-line react/prop-types
            if (props.submitFailed) {
              dispatch(stopSubmit(form));
            }
          }}
          {...rest}
        >
          {children}

        </ValidatedForm>
      </>
    </ApplicationFormWrapper>
  );
};

PureApplicationFormBase.propTypes = {
  children: PropTypes.node.isRequired,
  form: PropTypes.string.isRequired,
  formState: PropTypes.shape(),
  initialValues: PropTypes.shape(),
  validate: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  submitSuccess: PropTypes.bool,
  intl: intlShape.isRequired,
  elevated: PropTypes.bool,
  rounded: PropTypes.bool,
  customerIsPEP: PropTypes.bool,
  onTermsClick: PropTypes.func,
  onIntegrityPolicyClick: PropTypes.func,
  defaultBreakpoints: PropTypes.shape().isRequired,
  prefilledApplicationForm: PropTypes.bool,
  partnerForm: PropTypes.bool,
  emailSubmissionState: PropTypes.shape({
    submitting: PropTypes.bool,
    submitSuccess: PropTypes.bool,
    submitted: PropTypes.bool,
  }),
  emailSubmissionSubmit: PropTypes.func,
  currentSlide: PropTypes.number,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  type: PropTypes.string,
};

PureApplicationFormBase.defaultProps = {
  formState: {},
  initialValues: {},
  validate: null,
  submitSuccess: false,
  elevated: true,
  rounded: true,
  customerIsPEP: false,
  onTermsClick: () => { },
  onIntegrityPolicyClick: () => { },
  emailSubmissionState: null,
  emailSubmissionSubmit: () => {},
  prefilledApplicationForm: false,
  partnerForm: false,
  currentSlide: 0,
  title: '',
  subtitle: '',
  type: '',
};

const PureApplicationFormBaseIntl = injectIntl(PureApplicationFormBase);

const ApplicationFormBase = (props) => (
  <PureApplicationFormBaseIntl {...props} />
);

export { PureApplicationFormBaseIntl as PureApplicationFormBase };

export default ApplicationFormBase;
