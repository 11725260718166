import React from 'react';
import PropTypes from 'prop-types';
import ValidatedForm from '../../../components/molecules/ValidatedForm';

const EmployerPhone = ({
  validateOnRegister,
  whitelist,
  data,
  showErrors,
}) => (
  <ValidatedForm.TextInput
    data={data}
    placeholder={data.placeholder}
    type="tel"
    validateOnRegister={validateOnRegister}
    whitelist={whitelist}
    showErrors={showErrors}
  />
);

EmployerPhone.propTypes = {
  validateOnRegister: PropTypes.bool,
  whitelist: PropTypes.bool,
  data: PropTypes.shape().isRequired,
  showErrors: PropTypes.bool,
};

EmployerPhone.defaultProps = {
  validateOnRegister: false,
  whitelist: false,
  showErrors: false,
};

export default EmployerPhone;
