// external
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import messageDescriptorSchema from '../../schemas/message-descriptor';
import intlShape from '../../schemas/intl';
import ValidatedForm from '../../../components/molecules/ValidatedForm';
import Config from '../../utils/config';
import { FormattedNumberWithFallback } from '../../utils/FormattedNumberWithFallback';
import LanguageContext from '../../../context/language';
import { addThousandSeparator } from '../../utils/formatters/add-thousand-separator';

// internal
import consolidationLoanValidator from '../../validators/consolidationLoan';

const messages = defineMessages({
  placeholderText: {
    id: 'consolidationLoanAmount.placeholder',
    defaultMessage: '0',
    description: 'Default text for "consolidate loan amount"-input',
  },
  labelText: {
    id: 'consolidationLoanAmount.label',
    defaultMessage: 'Loan amount consolidate loans',
    description: 'Label for "consolidate loan amount"-input',
  },
  tooltipText: {
    id: 'consolidationLoanAmount.tooltip',
    defaultMessage:
      "Enter {currency} how much loans/credits you have today, and we'll see if we can lower your existing loan costs.",
    description:
      'Tooltip for "consolidate loan amount"-input informing the applicant to fill in the amount of their current loans and see if we can lower their current costs',
  },
});

const validate = (value, allValues) => (
  consolidationLoanValidator(value, (allValues || {}).totalAmount)
);

const ConsolidationAmount = styled.div`
  && {
    display: flex;
    justify-content: space-between;
    color: var(--dark-teal);
    font-size: 0.85rem;
  }
`;

const ConsolidationAmountBox = styled.div`
  && {
    flex: 1 0 50%;
  }
`;

const SamblaStyleMergeLoanDisplay = styled.div`
  && {
    text-align: center;
    font-size: 1rem;

    strong {
      font-size: 2rem;
      display: block;
    }
  }
`;

const ConsolidationLoanAmountInput = ({
  intl,
  placeholderText,
  labelText,
  tooltipText,
  data,
}) => {
  const { CURRENCY, LOAN } = Config.getValues();
  const { lang } = useContext(LanguageContext);
  const totalAmount = useSelector((state) => state.form.application.values.totalAmount);
  const values = useSelector((state) => state.form.application.values.consolidationAmount);
  let consolidationAmount = values;

  if (consolidationAmount > totalAmount || consolidationAmount < LOAN.STEP) {
    consolidationAmount = totalAmount; // eslint-disable-line no-param-reassign
  }

  return (
    <>
      <ConsolidationAmount>
        <ConsolidationAmountBox>
          <FormattedNumberWithFallback
            value={parseInt(consolidationAmount, 10)}
            formatAs="currency"
          >
            {() => (
              <SamblaStyleMergeLoanDisplay>
                {intl.formatMessage(data.consolidationLabel, {
                  strong: (chunks) => (
                    <strong>
                      {addThousandSeparator(chunks)}
                      {' '}
                      {CURRENCY}
                    </strong>
                  ),
                  consolidationAmount,
                })}
              </SamblaStyleMergeLoanDisplay>
            )}

          </FormattedNumberWithFallback>
        </ConsolidationAmountBox>
      </ConsolidationAmount>
      <br />
      <ValidatedForm.Slider
        name={data.name}
        showButtons={data.showButtons}
        placeholder={intl.formatMessage(placeholderText)}
        label={intl.formatMessage(labelText)}
        suffix={CURRENCY}
        englishSuffix={lang === 'en'}
        type="tel"
        sliderConfig={{
          range: {
            min: LOAN.STEP,
            max: parseInt(totalAmount, 10) || LOAN.STEP,
          },
          step: LOAN.STEP,
          animate: true,
        }}
        validate={validate}
        tooltip={{
          text: intl.formatMessage(tooltipText, { currency: CURRENCY }),
          width: 'fill',
          trigger: 'focus',
        }}
      />
    </>
  );
};

ConsolidationLoanAmountInput.propTypes = {
  placeholderText: messageDescriptorSchema,
  labelText: messageDescriptorSchema,
  intl: intlShape.isRequired,
  tooltipText: messageDescriptorSchema,
  data: PropTypes.shape(),
  values: PropTypes.shape({}),
};

ConsolidationLoanAmountInput.defaultProps = {
  placeholderText: { ...messages.placeholderText },
  labelText: { ...messages.labelText },
  tooltipText: { ...messages.tooltipText },
  data: {},
  values: {},
};

const ConsolidationLoanAmountInputWithIntl = injectIntl(ConsolidationLoanAmountInput);

export { ConsolidationLoanAmountInputWithIntl as ConsolidationLoanAmountInput };

export default ConsolidationLoanAmountInputWithIntl;
