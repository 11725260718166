import * as Sentry from '@sentry/gatsby';
import { getEnvironment, isProductionOrStaging } from './src/js/utils/environment';
import { brand, language } from './src/js/localizations/current-locale';

const sentryDsn = 'https://06448ad0c60e4190a8bfc9f4295db0b4@o451393.ingest.sentry.io/4505516584665088';
const hostName = typeof window !== 'undefined' ? window.location.hostname : '';

const initLogging = (version) => {
  Sentry.init({
    dsn: sentryDsn,
    environment: getEnvironment(),
    release: version,
    normalizeDepth: 16,
    attachStacktrace: true,
    beforeBreadcrumb: (breadcrumb) => {
      if (isProductionOrStaging() && breadcrumb.category === 'console') {
        return null;
      }
      return breadcrumb;
    },
    beforeSend(event, hint) {
      const breadcrumbs = event.breadcrumbs || [];
      if (hint.originalException && hint.originalException.stack && (hint.originalException.stack.includes('webpack-internal'))) {
        return null;
      }
      const containsExcludedUrl = breadcrumbs.some((breadcrumb) => {
        if (breadcrumb.category === 'xhr' || breadcrumb.category === 'fetch') {
          const url = breadcrumb.data && breadcrumb.data.url;
          return url && url.includes('api.eu1.exponea.com'); // Check if the breadcrumb URL matches the one to exclude
        }
        return false;
      });

      // If any breadcrumb contains the excluded URL, drop the event
      if (containsExcludedUrl) {
        return null; // Prevent the event from being sent to Sentry
      }
      return event;
    },
    ignoreErrors: [
      /has a recent previous application/,
      /TypeError: showDetailCookieInfoBanner\(.*\)/,
      /Error: Window closed before response/,
      /TypeError (xs|Ls|ls)\.sendBulkWithBeacon\(exponea\)/,
      /sendBulkWithBeacon(exponea)/,
      /sendBulkWithBeacon/,
      /Object Not Found Matching Id:/,
      /ChunkLoadError/,
      /exponea/,
      /staging/,
      // https://sambla-group.sentry.io/issues/5480690430/?query=is%3Aunresolved%20issue.priority%3A%5Bhigh%2C%20medium%5D&referrer=issue-stream&statsPeriod=90d&stream_index=0
      /null/,
      // https://sambla-group.sentry.io/issues/5499113801/?query=is%3Aunresolved%20issue.priority%3A%5Bhigh%2C%20medium%5D&referrer=issue-stream&statsPeriod=90d&stream_index=1
      /failed/,
      // https://sambla-group.sentry.io/issues/5542292166/?query=is%3Aunresolved%20issue.priority%3A%5Bhigh%2C%20medium%5D&referrer=issue-stream&statsPeriod=90d&stream_index=2
      /USER_NOT_FOUND/,
      // https://sambla-group.sentry.io/issues/5934678694/?query=is%3Aunresolved%20issue.priority%3A%5Bhigh%2C%20medium%5D&referrer=issue-stream&statsPeriod=90d&stream_index=7
      /t.toLowerCase is not a function. (In 't.toLowerCase()', 't.toLowerCase' is undefined)/,
      // https://sambla-group.sentry.io/issues/5703655491/?query=is%3Aunresolved%20issue.priority%3A%5Bhigh%2C%20medium%5D&referrer=issue-stream&statsPeriod=90d&stream_index=2
      /Root did not complete. This is a bug in React./,
    ],
    tracesSampler: (samplingContext) => {
      if (samplingContext.transactionContext.name === '/partners/bilweb-auto/') {
        return 0;
      }
      return 1;
    },
  });

  Sentry.setTag('hostname', hostName);
  Sentry.setTag('brand', brand);
  Sentry.setTag('language', language);
};

export default initLogging;
