/* eslint-disable react/prop-types */
/**
 * Validated select to be used in a <ValidatedForm>. Should be used as
 * <ValidatedForm.Select>, not imported directly.
 *
 * Usage:
 * ```js
 * <ValidatedForm ...>
 *   <ValidatedForm.Select
 *     name='preference'
 *     options={[{
 *       value: 1,
 *       label: 'option 1',
 *     }]}
 *     validate=[required]
 *     warn={not(2)} />
 * </ValidatedForm>
 * ```
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import messageDescriptorSchema from '../../../js/schemas/message-descriptor';
import tooltipSchema from '../../../js/schemas/tooltip';
import Alert from '../../atoms/Alert';
import Select from '../../atoms/Select';

const ValidatedFormSelect = ({
  className,
  name,
  validate,
  warn,
  compact,
  validateOnRegister,
  options,
  onChange,
  placeholder,
  tooltip,
  data,
  ...rest
}) => {
  if (placeholder) {
    // eslint-disable-next-line no-param-reassign
    options = [{
      label: placeholder,
      value: '',
      disabled: true,
    }].concat(options);
  }

  return (
    <Field
      name={data.name}
      className={className}
      // eslint-disable-next-line no-use-before-define
      component={ValidatedSelect}
      options={options}
      validate={data.validate}
      warn={warn}
      onChange={onChange}
      compact={compact}
      hasValidateFunction={!!validate}
      validateOnRegister={validateOnRegister}
      tooltip={tooltip}
      {...rest}
      {...data}
    />
  );
};

ValidatedFormSelect.propTypes = {
  className: PropTypes.string,
  /** Used as key in Redux. */
  name: PropTypes.string.isRequired,
  /** Validation function(s). Takes a value and returns an error string or undefined. */
  validate: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.func),
  ]),
  /** Validation function(s). Takes a value and returns a warning string or undefined. */
  warn: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.func),
  ]),
  compact: PropTypes.bool,
  validateOnRegister: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.objectOf).isRequired,
  onChange: PropTypes.func,
  placeholder: PropTypes.oneOfType([
    messageDescriptorSchema,
    PropTypes.string,
  ]),
  tooltip: tooltipSchema,
};

ValidatedFormSelect.defaultProps = {
  className: '',
  validate: null,
  warn: () => {},
  compact: false,
  validateOnRegister: false,
  onChange: () => {},
  placeholder: null,
  tooltip: '',
};

export default ValidatedFormSelect;

const ValidatedSelect = ({
  className,
  input,
  options,
  meta: {
    valid,
    touched,
    error,
    warning,
  },
  label,
  placeholder,
  compact,
  sorted,
  hasValidateFunction,
  validateOnRegister,
  tooltip,
  showErrors,
  ...rest
}) => {
  if (placeholder) {
    // eslint-disable-next-line no-param-reassign
    options = [{
      label: placeholder,
      value: '',
      disabled: true,
    }].concat(options);
  }

  const selectId = `validated-form-select--${input.name}`;
  const shouldValidate = valid || touched || validateOnRegister;

  return (
    <div data-formfield={input.name}>
      <Select
        {...input}
        {...rest}
        id={selectId}
        label={label}
        compact={compact}
        sorted={sorted}
        validated={hasValidateFunction}
        valid={shouldValidate ? valid : null}
        options={options}
        className={className}
        tooltip={tooltip}
      />
      {(shouldValidate || showErrors) && (
        (error && <Alert display={error} />)
        || (warning && <Alert type="warning" display={warning} />)
      )}
    </div>
  );
};

export { ValidatedSelect };
