import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages } from 'react-intl';
import SIGNING_ERRORS from '../../js/constants/ErrorsConstants';

import localeData from '../../js/localizations/locale-data';

const errorMessages = defineMessages({
  loginUserNotFound: {
    id: 'errorMessage.login.userNotFound',
    defaultMessage: 'No previous application has been found on your national ID number. Do you want to send in a new application? Go to our <a>homepage</a> or contact our <b>customer support</b>.',
    description:
      'Error message if use tries to login with credentials which does not found on any active application',
  },
  loginError: {
    id: 'errorMessage.login.loginError',
    defaultMessage: 'Something went wrong when you tried to sign in. Try again or make a <a>new application</a>',
    description: 'Text for the page that is shown if the user were not able to login due to a unknown server error',
  },
  generalError: {
    id: 'errorMessage.general.error',
    defaultMessage: 'A technical error has occurred. Please reload the page or contact customer service.',
    description: 'A general error message that will be used for all unknown system errors that could occur.',
  },
  userSigningCancel: {
    id: 'errorMessage.cancel.error',
    defaultMessage: 'User canceled <a>redirect</a>.',
    description: 'User canceled.',
  },
});

const ErrorMessage = ({ error, formatMessage }) => {
  if (!error) {
    return null;
  }
  const customerSupportLink = localeData.CUSTOMERSUPPORTLINK;

  const WebsiteLink = (chunks) => <a href={localeData.WEBSITE}>{chunks}</a>;
  const WebsiteLink2 = (chunks) => <a href={customerSupportLink}>{chunks}</a>;
  const IndexLink = (chunks) => <a href={localeData.WEBSITE}>{chunks}</a>;

  let message;
  switch (error) {
    case SIGNING_ERRORS.USER_NOT_FOUND:
      message = formatMessage(errorMessages.loginUserNotFound, {
        a: WebsiteLink,
        b: WebsiteLink2,
      });
      break;
    case SIGNING_ERRORS.START_ERROR:
      message = formatMessage(errorMessages.generalError);
      break;
    case SIGNING_ERRORS.STATUS_ERROR:
      message = formatMessage(errorMessages.loginError, {
        a: IndexLink,
      });
      break;
    case SIGNING_ERRORS.USER_LOGIN_CANCEL:
      message = formatMessage(errorMessages.userSigningCancel, {
        a: IndexLink,
      });
      break;
    default:
      message = formatMessage(errorMessages.generalError);
  }
/* eslint-disable */
  return (
    <div state="error">
      <p>{message}</p>
    </div>
  );
/* eslint-enable */
};

ErrorMessage.propTypes = {
  error: PropTypes.string,
  formatMessage: PropTypes.func.isRequired,
};

ErrorMessage.defaultProps = {
  error: '',
};

export default ErrorMessage;
