/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import tooltipSchema from '../../js/schemas/tooltip';
import tooltipToObject from '../../js/helpers/tooltipToObject';
import Alert from './Alert';

const Wrapper = styled.div`
  display: flex;
  margin-bottom: 1rem;
`;

const FieldWrapper = styled.div`
  display: flex;
  position: relative;
  flex-grow: 1;
  border-bottom-right-radius: 0;
  border-right: 0;
  border-top-right-radius: 0;
  flex-direction: column;
  background: var(--off-white);
  border: 2px solid var(--off-white);
  border-radius: 5px;

  ${(props) => props.validated && `
    border-color: ${props.localValid === true ? 'var(--teal)' : 'var(--error-bg)'};
  `}
`;

const LabelText = styled.div`
  text-overflow: unset;
  white-space: normal;
  margin-right: 2rem;
  overflow: hidden;
  padding: 0.5rem 0.5rem 0;
`;
const InputWrapper = styled.div`
  justify-content: space-between;
  height: 3em;
  background: var(--off-white);
  border: 0;
  border-radius: 5px;
  cursor: text;
  box-sizing: border-box;
  align-items: center;
  display: flex;
`;
const InputElement = styled.input`
  flex: 1 1;
  width: 100%;
  height: 100%;
  background: var(--off-white);
  border: 0;
  outline: none;
  overflow: hidden;
  padding: 0 0.5rem;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Button = styled.button`
  width: 6em;
  border: 0;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  color: var(--white);
  cursor: pointer;
  font-weight: bold;
  outline: none;
  background-color: var(--light-grey);
  ${(props) => (props.validated && props.localValid === true) && `
    background-color: var(--teal) !important;
  `}
  ${(props) => (props.validated && props.localValid === false) && `
    background-color:  var(--error-bg) !important;
  `}
`;

const ButtonText = styled.span`
  color: var(--white);
  cursor: pointer;
  font-weight: bold;
  display: ${(props) => (props.hideMobile ? 'block' : 'inline')};
`;

const InputWithButton = ({
  id,
  name,
  inputLabel,
  buttonLabel,
  buttonType,
  validated,
  valid,
  shouldCustomValidate,
  customValid,
  customMessage,
  className,
  tooltip,
  whitelist,
  onClick,
  ...rest
}) => {
  const inputId = id || `input--${name || ''}--${inputLabel.replace(/\s/, '-')}`;
  const tooltipObject = tooltipToObject(tooltip);
  const localValid = shouldCustomValidate && customValid !== null ? customValid : valid;

  const disabledButton = !validated
    || (validated && valid !== true)
    || customValid === true
    || customValid === false;

  return (
    <>
      <Wrapper
        className={className}
        data-tooltip={tooltipObject.text}
        data-tooltip-width={tooltipObject.width}
        data-tooltip-position={tooltipObject.position}
        data-tooltip-position-desktop={tooltipObject.positionDesktop}
        data-tooltip-trigger={tooltipObject.trigger}
      >
        <FieldWrapper
          className={className}
        >
          {inputLabel && (
            <LabelText htmlFor={inputId}>
              {inputLabel}
            </LabelText>
          )}
          <InputWrapper className={className}>
            <InputElement
              id={inputId}
              name={name}
              className={className}
              data-hj-whitelist={whitelist || null}
              {...rest}
            />
          </InputWrapper>
        </FieldWrapper>
        <Button
          validated={validated}
          localValid={localValid}
          disabled={disabledButton}
          type={buttonType} // eslint-disable-line
          onClick={onClick}
        >
          <ButtonText hideMobile={validated && localValid !== null}>
            {buttonLabel}
          </ButtonText>
        </Button>
      </Wrapper>
      {customMessage && (
        <Alert
          display={customMessage}
          type={customValid === true ? 'neutral' : 'error'}
        />
      )}
    </>
  );
};

InputWithButton.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  inputLabel: PropTypes.string,
  buttonLabel: PropTypes.string.isRequired,
  buttonType: PropTypes.string,
  validated: PropTypes.bool,
  valid: PropTypes.bool,
  shouldCustomValidate: PropTypes.bool,
  customValid: PropTypes.bool,
  customMessage: PropTypes.string,
  className: PropTypes.string,
  tooltip: tooltipSchema,
  whitelist: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

InputWithButton.defaultProps = {
  buttonType: 'button',
  id: null,
  name: null,
  inputLabel: '',
  validated: false,
  valid: null,
  shouldCustomValidate: false,
  customValid: undefined,
  customMessage: undefined,
  className: '',
  tooltip: '',
  whitelist: false,
};

export default InputWithButton;
