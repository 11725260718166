export default {
  totalAmountMarginTop: '3rem',
  loanCalculatorBorderWidth: '1px',
  loanCalculatorValueColor: 'var(--green)',
  summaryButtonContent: '✓ ',
  summaryButtonSelectedBackground: 'var(--white)',
  summaryButtonSelectedColor: 'var(--teal)',
  userAgreementMarginTop: '.5rem',

};
