import React from 'react';
import PropTypes from 'prop-types';
import TrustBox from './TrustBox';

const TrustpilotBlock = ({
  data,
}) => (
  <TrustBox
    data={data}
  />
);

TrustpilotBlock.propTypes = {
  data: PropTypes.shape(),
};

TrustpilotBlock.defaultProps = {
  data: {},
};

export default TrustpilotBlock;
