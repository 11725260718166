// external
import { INTEREST } from '../localizations/locale-data';
import { region } from '../localizations/current-locale';
import { dynamicBrand } from '../utils/brand';

// internal
import { valid, invalid } from '../utils/validators/resolutions';

let messages;
import(`../utils/text-resources/${dynamicBrand()}/${region}/messages`).then((m) => {
  messages = m.default;
});

const validateInterestRate = (input) => {
  if (/^\./.test(input)) {
    return invalid(messages.nothingBeforeDotInInterestRate);
  }

  if (/(\..*){2,}/.test(input)) {
    return invalid(messages.interestRateHasTwoDots);
  }

  if (!/^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/.test(input)) {
    return invalid(messages.interestRateUseOnlyNumbers);
  }

  if (/\.\d\d\d/.test(input)) {
    return invalid(messages.tooManyDecimalsInInterestRate);
  }

  const maxAllowedAmount = INTEREST.UP_TO;
  const minAllowedAmount = INTEREST.FROM;
  const value = parseFloat(input);

  if (value < minAllowedAmount) {
    return invalid(messages.tooLowInterestRate, { minAllowedAmount });
  }

  if (value > maxAllowedAmount) {
    return invalid(messages.tooHighInterestRate, { maxAllowedAmount });
  }

  return valid();
};

export default validateInterestRate;
