// external
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import noUiSlider from 'nouislider';
import styled from 'styled-components';
import CircleBtn from '../atoms/CircleBtn';
import intlShape from '../../js/schemas/intl';
import { region } from '../../js/localizations/current-locale';

const SliderWrapper = styled.div`
  && {
    position: relative;
    transform: ${(props) => (props.showButtons ? 'scale(0.8)' : 'none')};
    border: none !important;
    box-shadow: none !important;
  }
`;
const SliderHandle = styled.div`
  && {
    box-shadow: 0 2px 7px 0 rgb(0 0 0 / 25%);
    border-color: var(--white);
    height: 0.25rem !important;
  }
`;

const SliderTouchArea = styled.div`
  && {
    background: var(--white);
  }
`;

const LabelWrapper = styled.div`
  && {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    gap: 0.5rem;
  }
`;

const Label = styled.label`
  && {
    position: relative;
    font-size: 1rem;
    z-index: 1;
    padding: 0 0 0.625rem;
    color: var(--digi-blue-700);
    font-weight: 600;
  }
`;

const StepperButtonWrap = styled.div`
  position: absolute;
  width: calc(100% + 6rem);
  display: flex;
  justify-content: space-between;
  top: -3px;
  left: -3rem;
`;

const Slider = ({
  interestRate,
  className,
  showButtons,
  onChange,
  intl,
  label,
  animate,
  behaviour,
  connect,
  format,
  start,
  showLabel,
  range,
  step,
}) => {
  const sliderRef = useRef();
  const [sliding, setSliding] = useState(false);
  const [formLoaded, setFormLoaded] = useState(false);
  const { merge } = useSelector((state) => state.form.application.values);
  // eslint-disable-next-line

  const valueOnChange = (e, handle, rate) => {
    if (rate === true && e[handle] === '0') {
      return e[handle];
    }
    // eslint-disable-next-line no-nested-ternary
    return e[handle] === '0' ? e[handle] : ((e && e[handle]) ? e[handle] : e);
  };

  useEffect(() => {
    noUiSlider.create(sliderRef.current, {
      interestRate,
      animate,
      behaviour,
      connect,
      format,
      start,
      range,
      step,
    });

    const currentRef = sliderRef.current.noUiSlider;

    currentRef.on('start', () => {
      setSliding(true);
    });

    currentRef.on('end', () => {
      setSliding(false);
    });

    currentRef.on('slide', (e, handle) => {
      onChange(valueOnChange(e, handle));
    });

    setFormLoaded(true);

    return () => currentRef.off();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const currentRef = sliderRef.current.noUiSlider;

    if (!sliding && parseInt(start, 10) !== parseInt(currentRef.get(), 10)) {
      currentRef.set(start);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start]);

  useEffect(() => {
    const currentRef = sliderRef.current.noUiSlider;

    currentRef.off('slide');
    currentRef.on('slide', (e, handle) => {
      onChange(valueOnChange(e, handle, interestRate));
    });
    sliderRef.current.noUiSlider.updateOptions({ range: { min: range.min, max: region === 'NO' && !merge && merge !== null && range.max === 15 ? 5 : range.max } });
  });

  useEffect(() => {
    // eslint-disable-next-line no-nested-ternary
    const setStart = start < range.min ? range.min : (start > range.max ? range.max : start);

    sliderRef.current.noUiSlider.updateOptions({
      interestRate,
      animate,
      behaviour,
      connect,
      format,
      range: {
        min: range.min,
        max: range.max,
      },
      step,
    }, false);

    if (parseInt(start, 10) !== parseInt(setStart, 10)) {
      onChange(setStart);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animate, behaviour, connect, format, range.min, range.max, step]);

  const incClick = () => {
    if ((start + step) <= range.max) {
      onChange(start + step);
    }
  };
  const decClick = () => {
    if ((start - step) >= range.min) {
      onChange(start - step);
    }
  };

  return (
    <>
      {label && showLabel && (
      <LabelWrapper>
        <Label>
          {intl.formatMessage(label)}
        </Label>
      </LabelWrapper>
      )}

      <SliderWrapper
        showButtons={showButtons}
        ref={sliderRef}
        className={`
        c-slider
        ${className}
      `}
      >
        <div
          className="c-slider__target"
        >
          {!formLoaded && (
          <SliderHandle className="c-slider__handle noUi-handle">
            <SliderTouchArea className="noUi-touch-area" />
          </SliderHandle>
          )}
        </div>
        { showButtons
        && (
        <StepperButtonWrap>
          <CircleBtn callBack={decClick}>−</CircleBtn>
          <CircleBtn callBack={incClick}>+</CircleBtn>
        </StepperButtonWrap>
        )}
      </SliderWrapper>
    </>
  );
};

Slider.propTypes = {
  interestRate: PropTypes.bool,
  intl: intlShape.isRequired,
  label: PropTypes.shape(),
  showLabel: PropTypes.bool,
  className: PropTypes.string,
  onChange: PropTypes.func,
  animate: PropTypes.bool,
  showButtons: PropTypes.bool,
  behaviour: PropTypes.string,
  connect: PropTypes.string,
  format: PropTypes.shape({
    to: PropTypes.func,
    from: PropTypes.func,
  }),
  start: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
    ),
  ]),
  range: PropTypes.shape({
    min: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    max: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  }),
  step: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

Slider.defaultProps = {
  label: {},
  showLabel: false,
  interestRate: false,
  className: '',
  onChange: () => {},
  animate: false,
  showButtons: false,
  behaviour: 'snap',
  connect: 'lower',
  format: {
    to: (value) => (
      Number(value.toFixed(2))
    ),
    from: (value) => (
      parseInt(value, 10)
    ),
  },
  start: 0,
  range: {
    min: 0,
    max: 1,
  },
  step: 1,
};

const SliderWithIntl = injectIntl(Slider);

export { SliderWithIntl as Slider };

export default SliderWithIntl;
