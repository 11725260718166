const safeInterval = (method, time) => {
  let timeout;

  const loop = () => {
    timeout = setTimeout(() => {
      method();
      loop();
    }, time);
  };

  loop();

  return {
    cancel() {
      clearTimeout(timeout);
    },
  };
};

export default safeInterval;
