const normalizeSyncErrors = (syncErrors, prefix) => {
  const errors = Object.keys(syncErrors).reduce((seq, key) => {
    if (syncErrors[key].message) {
      const newKey = prefix ? `${prefix}.${key}` : key;
      return { ...seq, [newKey]: syncErrors[key].message.id };
    }

    if (['applicant', 'coApplicant'].includes(key)) {
      const nestedErrors = normalizeSyncErrors(syncErrors[key], key);
      return { ...seq, ...nestedErrors };
    }

    return seq;
  }, {});

  return errors;
};

export default normalizeSyncErrors;
