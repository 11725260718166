// external
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import intlShape from '../../schemas/intl';
import Config from '../../utils/config';
import { FormattedNumberWithFallback } from '../../utils/FormattedNumberWithFallback';
import { addThousandSeparator } from '../../utils/formatters/add-thousand-separator';

const Wrapper = styled.div`
  && {
    display: flex;
    justify-content: space-between;
  }
`;

const AmountDisplay = styled.div`
  && {
    font-size: 1rem;
    font-weight: 600;

    strong {
      font-size: 0.8rem;
      display: block;
      color: var(--sg-grey-700);
    }
  }
`;

const TotalAmountDisplay = ({
  intl,
  data,
}) => {
  const { CURRENCY } = Config.getValues();
  const values = useSelector((state) => state.form.application.values.totalAmount);
  const totalAmount = values;

  return (
    <>
      <Wrapper>
        <FormattedNumberWithFallback
          value={parseInt(totalAmount, 10)}
          formatAs="currency"
        >
          {() => (
            <AmountDisplay>
              {intl.formatMessage(data.totalAmountLabel, {
                strong: (chunks) => (
                  <strong>
                    {addThousandSeparator(chunks)}
                    {' '}
                    {CURRENCY}
                  </strong>
                ),
                totalAmount,
              })}
            </AmountDisplay>
          )}

        </FormattedNumberWithFallback>
      </Wrapper>
    </>
  );
};

TotalAmountDisplay.propTypes = {
  intl: intlShape.isRequired,
  data: PropTypes.shape(),
  values: PropTypes.shape({}),
};

TotalAmountDisplay.defaultProps = {
  data: {},
  values: {},
};

const TotalAmountDisplayWithIntl = injectIntl(TotalAmountDisplay);

export { TotalAmountDisplayWithIntl as TotalAmountDisplay };

export default TotalAmountDisplayWithIntl;
