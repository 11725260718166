// external
import { defineMessages } from 'react-intl';
// internal
import { valid, invalid } from '../utils/validators/resolutions';
import stripDelimiter from '../utils/strip-delimiter-from-currency';

const messages = defineMessages({
  genericNumbersZeroAndEmpty: {
    id: 'validators.genericNumbersZeroAndEmpty',
    defaultMessage: 'Use numbers please, 0, except as first digit or empty field.',
    description: 'Inform the customer/applicant that they are only allowed to use numbers',
  },
});

const numbersZeroAndEmpty = (input = '') => {
  const cleanedInput = stripDelimiter(input);
  if (!cleanedInput) {
    return invalid(messages.genericNumbersZeroAndEmpty);
  }
  if (!/^[0-9]*$/.test(cleanedInput)) {
    return invalid(messages.genericNumbersZeroAndEmpty);
  }
  if (/[a-zA-Z]/.test(cleanedInput)) {
    return invalid(messages.genericNumbersZeroAndEmpty);
  }

  return valid();
};

export default numbersZeroAndEmpty;
