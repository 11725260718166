/* eslint-disable react/prop-types */
/**
 * Validated input with button field to be used in a <ValidatedForm>. Should be used as
 * <ValidatedForm.InputWithButton>, not imported directly.
 *
 * Usage:
 * ```js
 * <ValidatedForm ...>
 *   <ValidatedForm.InputWithButton
 *     validate=[required, number]
 *     warn={max(10)} />
 *     inputLabel='Partner code'
 *     buttonLabel='Activate'
 * </ValidatedForm>
 * ```
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import tooltipSchema from '../../../js/schemas/tooltip';
import Alert from '../../atoms/Alert';
import InputWithButton from '../../atoms/InputWithButton';

const ValidatedFormInputWithButton = ({
  className,
  name,
  inputLabel,
  buttonLabel,
  buttonType,
  shouldCustomValidate,
  customValid,
  customMessage,
  validate,
  warn,
  validateOnRegister,
  onChange,
  onClick,
  tooltip,
  submitting,
  ...rest
}) => (
  <Field
    name={name}
    className={className}
    inputLabel={inputLabel}
    buttonLabel={buttonLabel}
    buttonType={buttonType}
    shouldCustomValidate={shouldCustomValidate}
    customValid={customValid}
    customMessage={customMessage}
    // eslint-disable-next-line no-use-before-define
    component={ValidatedInputWithButton}
    validate={validate}
    warn={warn}
    onChange={onChange}
    onClick={onClick}
    hasValidateFunction={!!validate}
    validateOnRegister={validateOnRegister}
    tooltip={tooltip}
    submitting={submitting}
    {...rest}
  />
);

ValidatedFormInputWithButton.propTypes = {
  className: PropTypes.string,
  /** Used as key in redux. */
  name: PropTypes.string.isRequired,
  inputLabel: PropTypes.string,
  buttonLabel: PropTypes.string.isRequired,
  buttonType: PropTypes.string,
  shouldCustomValidate: PropTypes.bool,
  customValid: PropTypes.bool,
  customMessage: PropTypes.string,
  /** Validation function(s). Takes a value and returns an error string or undefined. */
  validate: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.func),
  ]),
  /** Validation function(s). Takes a value and returns a warning string or undefined. */
  warn: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.func),
  ]),
  validateOnRegister: PropTypes.bool,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  tooltip: tooltipSchema,
};

ValidatedFormInputWithButton.defaultProps = {
  className: '',
  inputLabel: '',
  buttonType: undefined,
  shouldCustomValidate: false,
  customValid: undefined,
  customMessage: undefined,
  validate: null,
  warn: () => {},
  validateOnRegister: false,
  onChange: () => {},
  onClick: () => {},
  tooltip: '',
};

export default ValidatedFormInputWithButton;

const ValidatedInputWithButton = ({
  className,
  input,
  meta: {
    valid,
    touched,
    error,
    warning,
  },
  inputLabel,
  buttonLabel,
  buttonType,
  shouldCustomValidate,
  customValid,
  customMessage,
  onClick,
  hasValidateFunction,
  validateOnRegister,
  tooltip,
  ...rest
}) => {
  const inputId = `validated-form-input-with-button--${input.name}`;
  const shouldValidate = valid || touched || validateOnRegister;

  return (
    <>
      <InputWithButton
        {...input}
        {...rest}
        id={inputId}
        inputLabel={inputLabel}
        buttonLabel={buttonLabel}
        buttonType={buttonType}
        shouldCustomValidate={shouldCustomValidate}
        customValid={customValid}
        customMessage={customMessage}
        onClick={onClick}
        validated={hasValidateFunction}
        valid={shouldValidate ? valid : null}
        className={className}
        tooltip={tooltip}
      />
      {shouldValidate && (
        (error && <Alert display={error} />)
        || (warning && <Alert type="warning" display={warning} />)
      )}
    </>
  );
};

export { ValidatedInputWithButton };
