import { region } from '../localizations/current-locale';
import { dynamicBrand } from '../utils/brand';
import { valid, invalid } from '../utils/validators/resolutions';

let messages;
import(`../utils/text-resources/${dynamicBrand()}/${region}/messages`).then((m) => {
  messages = m.default;
});

const validatePresence = () => (value) => {
  if (!/\S+/.test(value || '')) {
    return invalid(messages.mortgageValidatePresence);
  }

  return valid();
};

export default validatePresence;
