import { region } from '../localizations/current-locale';
import { sendEvent } from './gtm';

let isThrottled = false;
const throttleDuration = 3000; // ms

const getDatalayerValueByKey = (key) => {
  let result = null;
  /* eslint-disable */
  if (window.dataLayer) {
    window.dataLayer.push(function () { // eslint-disable-line
      const value = this.get(key);
      if (value) {
        result = value;
      }
    });
  }
  /* eslint-enable */
  return result || 0;
};

const track = (valid, input, error) => {
  if (!isThrottled) {
    isThrottled = true;
    setTimeout(() => { isThrottled = false; }, throttleDuration);

    if (valid) {
      // edit event
      // sendEvent({
      //   event: 'Edit Form',
      //   eventCategory: region + 'PrivateLoan',
      //   eventAction: 'Input',
      //   eventLabel: input.name
      //  });
    } else {
      // error event
      sendEvent({
        event: 'Error',
        eventCategory: `${region} PrivateLoan`,
        eventAction: 'Error',
        eventLabel: input.name,
        errorMessage: error?.message?.defaultMessage,
        currentSlide: getDatalayerValueByKey('currentSlide'),
        formType: getDatalayerValueByKey('formType') || 'mergeLoan',
      });
    }
  }
};
export default track;
