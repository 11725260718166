const getDeviceType = () => {
  if (typeof window === 'undefined' || typeof navigator === 'undefined') {
    return 'unknown';
  }

  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return 'tablet';
  }
  if (/(tablet|ipad)/i.test(ua)) {
    return 'ipad';
  }
  if (/Mobile|Android|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
    return 'mobile';
  }
  if (/Mobile|iP(hone|od)/.test(ua)) {
    return 'ios';
  }
  return 'desktop';
};

export default getDeviceType;
