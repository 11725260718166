export default {
  number: '+46770175050',
  display: '0770 - 17 50 50',
  currency: 'SEK',
  tracking: {
    googleAnalyticsId: 'UA-49055014-1',
    hotjarId: 85237,
    facebookIds: [
      '892300370916273', // GENUIN
      '388498551330997', // N365
    ],
  },
};
