import React from 'react';

import EmailSubmissionFormBase from '../EmailSubmissionFormBase';

const EmailSubmissionForm = (props) => (
  <EmailSubmissionFormBase
    {...props}
  />
);

export default EmailSubmissionForm;
