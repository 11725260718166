import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';
import styled from 'styled-components';
import localeData, { INTEREST } from '../../localizations/locale-data';
import intlShape from '../../schemas/intl';
import ValidatedForm from '../../../components/molecules/ValidatedForm';
import interestRateValidator from '../../validators/interestRate';

const MessageWrapper = styled.div`
  && {
    margin-bottom: 2rem;
    text-align: center;
    font-size: 0.9rem;
  }
`;

const messages = defineMessages({
  interestRateDisclaimer: {
    id: 'interestRateDisclaimer',
    defaultMessage: "<strong>NOTE:</strong> The selected interest rate here is for calculation example only and can't be guaranteed.",
    description: 'Disclaimer for interest rate field',
  },
});

const InterestRateInput = ({
  whitelist,
  data,
  intl: { formatMessage },
  showErrors,
  formType,
}) => (
  <>
    <ValidatedForm.Slider
      interestRate
      name={data.name}
      placeholder={data.placeholder}
      label={data.label}
      suffix={data.suffix}
      type="tel"
      parse={(inputValue) => inputValue}
      maxLength={String(INTEREST.UP_TO).length + 3} // Take space between numbers into account
      validate={interestRateValidator}
      showErrors={showErrors}
      formType={formType}
      totalAmountField
      sliderConfig={{
        range: {
          min: INTEREST.FROM,
          max: INTEREST.UP_TO,
        },
        step: INTEREST.STEP,
      }}
      withTextInput
      labelOutside
      tooltip={data.tooltip}
      tooltipLink={localeData.EFFECTIVEINTERESTRATE}
      whitelist={whitelist}
      fieldStyle={data.fieldStyle}
      loanCalculatorStyle={data.loanCalculatorStyle}
    />
    <MessageWrapper>
      {formatMessage(messages.interestRateDisclaimer, {
        strong: (chunks) => <strong>{chunks}</strong>,
      })}
    </MessageWrapper>
  </>
);

InterestRateInput.propTypes = {
  data: PropTypes.shape(),
  whitelist: PropTypes.bool,
  intl: intlShape.isRequired,
  showErrors: PropTypes.bool,
  formType: PropTypes.string,
};

InterestRateInput.defaultProps = {
  data: {},
  whitelist: false,
  showErrors: false,
  formType: '',
};

export default injectIntl(InterestRateInput);
