import React from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash-es/debounce';

import useResizeObserver from '../js/utils/use-resize-observer';

const FloatingFormContext = React.createContext();

const comparePositions = (compareElement, formElement) => {
  if (!formElement || !compareElement) return false;
  const comRefRect = compareElement.getBoundingClientRect();
  const formElementRect = formElement.getBoundingClientRect();
  return (comRefRect.top < formElementRect.bottom
    && comRefRect.bottom > formElementRect.top);
};

const getIntersectHeight = (compareElement, formElement) => {
  if (!formElement) return 0;
  const comRefRect = compareElement.getBoundingClientRect();
  const formElementRect = formElement.getBoundingClientRect();
  if (comRefRect.top < formElementRect.bottom
    && comRefRect.bottom > formElementRect.top) {
    return formElementRect.bottom - comRefRect.top;
  }
  return 0;
};

const updateFormState = ({
  element,
  setFloatingFormState,
}) => () => {
  const elementRect = element.getBoundingClientRect();
  setFloatingFormState({
    dimension: {
      height: elementRect.height,
    },
    element,
    offset: {
      bottom: elementRect.bottom,
      top: elementRect.top,
    },
  });
};

const FloatingFormProvider = ({ children }) => {
  const [ref, entry] = useResizeObserver();
  const [floatingFormState, setFloatingFormState] = React.useState({
    dimension: {},
    element: undefined,
    offset: {},
  });
  const [hasInteractedWithForm, setHasInteractedWithForm] = React.useState(false);

  React.useEffect(() => {
    if (!entry.target) {
      return;
    }

    const element = entry.target;
    const updateFormStateFn = updateFormState({
      element,
      setFloatingFormState,
    });
    const updateFormStateThrottled = throttle(updateFormStateFn, 300);

    updateFormStateFn();
    window.addEventListener('scroll', updateFormStateThrottled);

    // eslint-disable-next-line consistent-return
    return () => window.removeEventListener('scroll', updateFormStateThrottled);
  }, [entry]);

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const providerProps = {
    comparePositions: (compareElement) => (
      comparePositions(compareElement, floatingFormState.element)
    ),
    floatingFormState,
    getIntersectHeight: (compareElement) => (
      getIntersectHeight(compareElement, floatingFormState.element)
    ),
    hasInteractedWithForm,
    forwardRef: ref,
    setHasInteractedWithForm,
    updateFormState: () => updateFormState({
      element: floatingFormState.element,
      setFloatingFormState,
    }),
  };

  return (
    <FloatingFormContext.Provider value={providerProps}>
      {children}
    </FloatingFormContext.Provider>
  );
};

FloatingFormProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useFloatingForm = () => React.useContext(FloatingFormContext);

export { FloatingFormContext };
export { useFloatingForm };
export { FloatingFormProvider };
export default FloatingFormProvider;
