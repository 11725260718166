export const normalizePhoneNumber = (input) => (input || '').replace(/[- ()]/g, '');

export const normalizePhone = (input) => {
  let normalizedInput = normalizePhoneNumber(input);
  if (!/^((00|\+)?46)/.test(normalizedInput)) {
    normalizedInput = normalizePhoneNumber(input).replace(normalizedInput.charAt(0), '46');
  } else {
    normalizedInput = normalizePhoneNumber(input).replace(/^((00|\+)?46)/, '46');
  }

  return normalizedInput;
};
