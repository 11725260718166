// external
import { region } from '../localizations/current-locale';
import { dynamicBrand } from '../utils/brand';

// internal
import Config from '../utils/config';
import { valid, invalid } from '../utils/validators/resolutions';

let messages;
import(`../utils/text-resources/${dynamicBrand()}/${region}/messages`).then((m) => {
  messages = m.default;
});

const validateMergeLoan = (input, otherInput) => {
  if (typeof input !== 'boolean') {
    return invalid(messages.mergeLoanIsNotABoolean);
  }

  // checking for numbers [0-9]
  if (!/^(\d+)$/.test(otherInput.consolidationAmount)) {
    return invalid(messages.mergeLoanUseOnlyNumbers);
  }

  const config = Config.getValues();
  const otherValue = otherInput.consolidationAmount
    ? parseInt(otherInput.consolidationAmount, 10) : 0;
  const maxAllowedAmount = config.LOAN.UP_TO - otherValue.consolidationAmount;
  const minAllowedAmount = config.LOAN.FROM - otherValue.consolidationAmount;
  const value = parseInt(otherValue.consolidationAmount, 10);

  if (value < minAllowedAmount) {
    return invalid(messages.tooLowMergeLoan, { minAllowedAmount });
  }

  if (value > maxAllowedAmount) {
    return invalid(messages.tooHighMergeLoan, { maxAllowedAmount });
  }

  return valid();
};

export default validateMergeLoan;
