/**
 * This function will return the position of supplied element,
 * no matter if it's positioned or static,
 * to be accurate when we scroll to that specific element
 */
const getElementOffsetPosition = (element) => {
  let elementPosition;

  try {
    elementPosition = element.getBoundingClientRect();
  } catch (e) {
    /* eslint-disable-next-line no-console */
    console.warn('Not a valid HTMLElement for scroll', e);

    return {
      top: 0,
      left: 0,
    };
  }

  const { body } = document;
  const docElement = document.documentElement;

  const scrollTop = window.pageYOffset || docElement.scrollTop || body.scrollTop;
  const scrollLeft = window.pageXOffset || docElement.scrollLeft || body.scrollLeft;

  const top = elementPosition.top + scrollTop;
  const left = elementPosition.left + scrollLeft;

  return {
    top: Math.round(top),
    left: Math.round(left),
  };
};

export default getElementOffsetPosition;
