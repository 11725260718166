// external
import { region } from '../localizations/current-locale';
import { dynamicBrand } from '../utils/brand';

// internal
import { valid, invalid } from '../utils/validators/resolutions';

let messages;
import(`../utils/text-resources/${dynamicBrand()}/${region}/messages`).then((m) => {
  messages = m.default;
});

const validateDependants = (input = '') => {
  const isStringOrNumber = ['string', 'number'].includes(typeof input);

  if (!isStringOrNumber || String(input).length === 0) {
    return invalid(messages.requiredDependants);
  }

  if (!/^(\d+)$/.test(String(input))) {
    return invalid(messages.dependantsUseOnlyNumbers);
  }

  const value = parseInt(input, 10);

  if (value < 0) {
    return invalid(messages.tooLowDependants);
  }

  if (value > 9) {
    return invalid(messages.tooHighDependants);
  }

  return valid();
};

export default validateDependants;
