import React, { useContext } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import ValidatedForm from '../../../components/molecules/ValidatedForm';
import getMonthsForLocale from '../../utils/months';

import LanguageContext from '../../../context/language';

const EmploymentSinceInputMonth = ({
  validateOnRegister,
  data,
  showErrors,

}) => {
  const { lang } = useContext(LanguageContext);
  const monthOptions = [
    ...getMonthsForLocale(lang).map((month, i) => ({
      value: i,
      label: month,
    })),
  ];

  return (
    <ValidatedForm.Select
      data={data}
      options={monthOptions}
      validateOnRegister={validateOnRegister}
      showErrors={showErrors}
    />
  );
};

EmploymentSinceInputMonth.propTypes = {
  validateOnRegister: PropTypes.bool,
  data: PropTypes.shape().isRequired,
  showErrors: PropTypes.bool,
};

EmploymentSinceInputMonth.defaultProps = {
  validateOnRegister: false,
  showErrors: false,
};

export default injectIntl(EmploymentSinceInputMonth);
