import { addThousandSeparator } from './formatters/add-thousand-separator';

const getConsolidatonAmount = (totalAmount) => {
  const CONSOLIDATIONLOANAMOUNT = [];
  let i;
  if (totalAmount > 3000) {
    for (i = 100; i <= 3000; i += 100) {
      CONSOLIDATIONLOANAMOUNT.push({ label: `${addThousandSeparator(parseInt(i, 10))} €`, value: i });
    }
  } else {
    for (i = 100; i <= totalAmount; i += 100) {
      CONSOLIDATIONLOANAMOUNT.push({ label: `${addThousandSeparator(parseInt(i, 10))} €`, value: i });
    }
  }
  if (totalAmount > 5500) {
    for (i = 3500; i <= 5500; i += 500) {
      CONSOLIDATIONLOANAMOUNT.push({ label: `${addThousandSeparator(parseInt(i, 10))} €`, value: i });
    }
  } else {
    for (i = 3500; i <= totalAmount; i += 500) {
      CONSOLIDATIONLOANAMOUNT.push({ label: `${addThousandSeparator(parseInt(i, 10))} €`, value: i });
    }
  }
  if (totalAmount > 6000) {
    for (i = 6000; i <= totalAmount; i += 1000) {
      CONSOLIDATIONLOANAMOUNT.push({ label: `${addThousandSeparator(parseInt(i, 10))} €`, value: i });
    }
  } else {
    for (i = 6000; i <= totalAmount; i += 1000) {
      CONSOLIDATIONLOANAMOUNT.push({ label: `${addThousandSeparator(parseInt(i, 10))} €`, value: i });
    }
  }
  return CONSOLIDATIONLOANAMOUNT;
};

export default getConsolidatonAmount;
