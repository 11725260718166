import { createSlice } from '@reduxjs/toolkit';
import SIGNING_ERRORS from '../../constants/ErrorsConstants';

export const initialState = {
  error: null,
  loggingIn: false,
  autoStartToken: null,
  logingWithBankid: false,
  token: null,
  cancelToken: null,
  qrCodeData: null,
};

export const auth = createSlice({
  name: 'authBankId',
  initialState,
  reducers: {
    SIGN_LOGIN: (state, action) => ({
      ...state,
      loggingIn: true,
      autoStartToken: action?.payload?.autoStartToken,
      cancelToken: action?.payload?.cancelToken,
      error: SIGNING_ERRORS.START_ERROR,
      logingWithBankid: true,
    }),
    SIGN_LOGIN_FAILED: (state, action) => ({
      ...state,
      error: action.payload.error,
      loggingIn: false,
      logingWithBankid: false,
    }),
    SIGN_LOGIN_STOP: (state) => ({
      ...state,
      loggingIn: false,
      logingWithBankid: false,
    }),
    SIGN_LOGIN_CANCEL: (state) => ({
      ...state,
      error: SIGNING_ERRORS.USER_LOGIN_CANCEL,
      loggingIn: false,
      logingWithBankid: false,
    }),
    SIGN_LOGIN_SUCCESS: (state, action) => ({
      ...state,
      loggingIn: false,
      token: action?.payload,
    }),
    SIGN_SET_QR_DATA: (state, action) => ({
      ...state,
      qrCodeData: action?.payload?.qrData,
    }),
  },
});

export const {
  SIGN_LOGIN,
  SIGN_LOGIN_FAILED,
  SIGN_LOGIN_STOP,
  SIGN_LOGIN_CANCEL,
  SIGN_LOGIN_SUCCESS,
  SIGN_SET_QR_DATA,
} = auth.actions;

export default auth.reducer;
