const getOwnPropertyNames = (obj) => {
  const newObj = JSON.parse(JSON.stringify(obj, Object.getOwnPropertyNames(obj)));

  Object.getOwnPropertyNames(newObj).forEach((key) => {
    if (newObj[key] instanceof Object) {
      newObj[key] = getOwnPropertyNames(obj[key]);
    }
  });

  return newObj;
};

const expose = (error, opts) => ({
  ...(opts.getOwnCustomProperties && getOwnPropertyNames(error)),
  ...(JSON.parse(JSON.stringify(error, opts.whitelist))),
});

const loggerExposeError = (incomingPayload) => {
  const options = {
    getOwnCustomProperties: true,
    whitelist: ['message', 'name', 'stack'],
  };
  let payload = incomingPayload;

  if (payload instanceof Error) {
    payload = expose(payload, options);
    return payload;
  }

  if (payload && payload.error instanceof Object) {
    payload.error = expose(payload.error, options);
  }

  return payload;
};

export default loggerExposeError;
