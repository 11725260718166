import { brand } from '../localizations/current-locale';
import * as Errors from './Errors';

const API_ROOT = process.env.REACT_APP_API_ROOT_PAPI || process.env.REACT_APP_API_ROOT;

export const START_SIGNING = {
  createUrl: (params) => `${API_ROOT}/sign/start/${params.type}/?brand=${brand}`,
  error: Errors.GenericError,
};

export const SIGNING_STATUS = {
  createUrl: (params) => `${API_ROOT}/sign/status/${params.token}`,
  error: Errors.GenericError,
};

export const SIGNING_CANCEL = {
  createUrl: (params) => `${API_ROOT}/sign/cancel/${params.token}`,
  error: Errors.GenericError,
};

