import React from 'react';
import PropTypes from 'prop-types';
import { stopSubmit } from 'redux-form';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import ValidatedForm from '../../components/molecules/ValidatedForm';
import Alert from '../../components/atoms/Alert';
import { email } from '../validators';

const EmailSubmissionFormBase = ({
  form,
  formState,
  initialValues,
  inputPlaceholder,
  inputLabel,
  buttonLabel,
  successMessage,
  mobile,
  isTablet,
  errorMessage,
  onSubmit,
  ...rest
}) => {
  const value = useSelector((state) => state.form.emailSubmission?.values);

  const {
    isSuccess,
    isError: submitFailed,
  } = formState;
  const onStopSubmit = stopSubmit;

  let customValid = isSuccess === false ? null : isSuccess;
  customValid = submitFailed ? false : customValid;

  return (
    <>
      <ValidatedForm
        className={classNames({
          LG: !isTablet,
        })}
        form={form}
        initialValues={initialValues}
        onSubmit={() => onSubmit(value)}
        {...rest}
      >
        <ValidatedForm.InputWithButton
          buttonLabel={buttonLabel}
          buttonType="submit"
          customValid={customValid}
          name="email"
          inputLabel={inputLabel}
          validate={email}
          placeholder={inputPlaceholder}
          shouldCustomValidate
          onChange={() => onStopSubmit(form)}
        />
        {submitFailed && formState.error && (
          <Alert
            display={errorMessage}
          />
        )}
        {isSuccess && successMessage && (
          <Alert
            emailSubmissionAlert
            display={successMessage}
            type="info"
          />
        )}
      </ValidatedForm>
    </>
  );
};

EmailSubmissionFormBase.propTypes = {
  form: PropTypes.string.isRequired,
  formState: PropTypes.shape(),
  initialValues: PropTypes.shape(),
  inputPlaceholder: PropTypes.string,
  inputLabel: PropTypes.string,
  buttonLabel: PropTypes.string,
  successMessage: PropTypes.string,
  errorMessage: PropTypes.string,
  mobile: PropTypes.bool,
  isTablet: PropTypes.bool,
  onSubmit: PropTypes.func,
  onStopSubmit: PropTypes.func.isRequired,
};

EmailSubmissionFormBase.defaultProps = {
  formState: {},
  initialValues: {},
  inputPlaceholder: null,
  inputLabel: null,
  buttonLabel: null,
  errorMessage: null,
  successMessage: null,
  mobile: false,
  isTablet: true,
  onSubmit: () => {},
};

export default EmailSubmissionFormBase;
