import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import style from './style';

const Breaker = styled.div`
  && {
    position: relative;
    left: 0;
    right: 0;
    width: 100%;
    height: ${style.breakHeight};
    transform: ${(props) => (props.shade === 'down' ? 'scaleX(2)' : 'scaleX(2) translateY(-1rem)')};
    margin-top: ${(props) => (props.shade === 'down' ? '2rem' : '0')};
    border: ${style.breakBorder};
    background-image: ${(props) => (props.shade === 'down' ? 'linear-gradient(0deg,hsla(0,0%,100%,0),rgba(0,0,0,.03))' : 'linear-gradient(0deg,rgba(0,0,0,.03),hsla(0,0%,100%,0))')};
  }
`;

const Break = ({
  data,
}) => (
  <Breaker shade={data.shade} />
);

Break.propTypes = {
  data: PropTypes.shape(),
};

Break.defaultProps = {
  data: {},
};

export default Break;
