import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import devices from '../../../styles/Devices';
import style from './style';

const MainContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 0 1rem;
  margin-bottom: 3rem;
  margin-left: -1rem;

  @media ${devices.downFromTablet} {
    margin-left: none;
  }
`;

const StepContainer = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;

  :before {
    content: '';
    position: absolute;
    background: ${style.stepContainerBackground};
    height: 1.5rem;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 2rem;
    left: 0;
  }

  :after {
    content: '';
    position: absolute;
    background: ${style.stepContainerBackgroundAfter};
    height: 1.5rem;
    border-radius: 2rem;
    width: ${({ width }) => width};
    top: 50%;
    transition: 0.4s ease;
    transform: translateY(-50%);
    left: 0;
  }
`;

const StepWrapper = styled.div`
  position: relative;
  z-index: 1;
`;

const StepStyle = styled.div`
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid ${style.stepContainerBackground};
  background-color: ${style.stepContainerBackground};

  ${(props) => props.completed && css`
    background-color: ${style.stepContainerBackgroundAfter};
    border: 3px solid ${style.stepContainerBackgroundAfter};
  `}
`;

const StepCount = styled.span`
  font-size: 1.5rem;
  color: var(--digi-blue-400);
  font-family: var(--font-family);
  font-weight: 600;

  @media (max-width: 600px) {
    font-size: 1rem;
  }

  ${(props) => props.completed && css`
    color: var(--white);
  `}
`;

const ProgressBarStepper = (
  {
    step,
    steps,
  },
) => {
  const activeStep = step;

  const STEP = [];

  for (let i = 0; i < steps.length; i += 1) {
    STEP.push({ label: i, value: i });
  }

  const totalSteps = STEP.length;

  const width = `${(100 / (totalSteps - 1)) * (activeStep)}%`;

  return (
    <MainContainer>
      <StepContainer width={width}>
        {STEP.map(({ value }) => (
          <StepWrapper key={value}>
            <StepStyle completed={value <= activeStep}>
              <StepCount completed={value <= activeStep}>{value + 1}</StepCount>
            </StepStyle>
          </StepWrapper>
        ))}
      </StepContainer>
    </MainContainer>
  );
};

ProgressBarStepper.propTypes = {
  step: PropTypes.shape(),
  steps: PropTypes.shape(),
};

ProgressBarStepper.defaultProps = {
  step: {},
  steps: {},
};

export default ProgressBarStepper;
