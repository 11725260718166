const existingLanguages = require('./language-region');

const region = process.env.REACT_APP_REGION || 'SE';
const language = process.env.REACT_APP_LANGUAGE || 'sv';
const brand = process.env.REACT_APP_BRAND || 'advisa';
const token = process.env.REACT_APP_BLOOMREACH_TOKEN || null;
const credentials = process.env.REACT_APP_GROWTHBOOK_CREDENTIALS || null;
const locale = `${language}-${region}`;

module.exports.region = region;
module.exports.language = language;
module.exports.brand = brand;
module.exports.token = token;
module.exports.credentials = credentials;

module.exports.currentLocale = {
  ...existingLanguages[language],
  lang: language,
  region,
  brand,
  locale,
  token,
  credentials,
};
