export const isStaging = () => process.env.REACT_APP_ENV === 'staging';
export const isProduction = () => process.env.NODE_ENV === 'production';
export const isProductionOrStaging = () => isStaging() || isProduction();
export const isDevelopment = () => !isProductionOrStaging();
export const isDevelopmentOrStaging = () => isDevelopment() || isStaging();
export const isTest = () => process.env.NODE_ENV === 'test';
export const isCircleCI = () => !!process.env.CIRCLECI;
export const getEnvironment = () => (
  process.env.REACT_APP_ENV || process.env.NODE_ENV
).toLocaleLowerCase();

export default () => process.env;
