/* eslint-disable max-len */
import React, { useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { addThousandSeparator } from '../../utils/formatters/add-thousand-separator';
import devices from '../../styles/Devices';

const Table = styled.div`
  && {
    position: fixed;
    align-items: center;
    overflow: scroll;
    top: 0;
    right: 0;
    width: 100% !important;
    height: 100%;
    z-index: 99999;
    background: var(--bg-summary-table);
    margin-top: 4rem;

    @media ${devices.downFromTablet} {
      margin-top: 3rem;
    }
  }
`;

const TableHeader = styled.div`
  && {
    display: inline-table;
    position: fixed;
    top: 0;
    height: 5vh;
    background: var(--bg-summary-table);
    box-shadow: 0 3px 2px -2px gray;
    padding: 0 1rem;
    margin-top: 4rem;

    @media ${devices.downFromTablet} {
      margin-top: 3rem;
    }

    @media ${devices.downFromSmallTablet} {
      width: 100%;
      left: 0;
    }
    @media ${devices.upFromSmallTablet} {
      width: calc(100% - 30px);
      left: 15px;
    }
  }
`;

const TableIntro = styled.div`
  && {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 5px;
    background: var(--bg-summary-table);
  }
`;

const TableSecondIntro = styled.div`
  && {
    padding-bottom: 3px;
  }
`;

const CloseButton = styled.button`
  && {
    border: none;
    border-radius: 50%;
    height: 1.5em;
    width: 1.5em;
    background: var(--bg-summary-close-btn);
    color: var(--summary-white);
    cursor: pointer;
  }
`;

const TableResultContainer = styled.div`
  && {
    display: flex;
    justify-content: space-between;
    background: var(--bg-summary-table);
  }
`;

const TableContent = styled.table`
  && {
    margin: 6.2rem 1rem 1rem 1rem;
    overflow: scroll;
    background: var(--bg-summary-table);

    @media ${devices.downFromSmallTablet} {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }

    @media ${devices.upFromSmallTablet} {
      width: calc(100% - 30px);
    }
  }
`;

const ResultSpan = styled.span`
  && {
    color: var(--summary-res);
    text-decoration: underline;
    text-decoration-color: var(--summary-res);
  }
`;

const TableTh = styled.th`
  && {
    position: sticky;
    top: 99px;
    text-align: center;
    padding: 5px;
    background: var(--summary-white);
    border: 1px solid var(--summary-border);
    border-left: none;
    min-width: 100px;
  }
`;

const TableTd = styled.td`
  && {
    padding: 5px 20px;
    text-align: right;
    box-sizing: border-box;
    border-right: 1px solid var(--summary-border);
  }
`;

const TableTr = styled.tr`
  && {
    &:nth-child(2n+1) {
      background: var(--summary-grey);
    }
  }
`;

const TableTbody = styled.tbody`
  && {
    tr &:nth-child(2n+1) {
      background: var(--bg-summary-table);
    }

    td &:nth-child(2n+1) {
      background: var(--bg-summary-table);
    }
  }
`;

const SummaryTable = ({
  effectiveInterest,
  monthlyPayments,
  totalCostsForLoan,
  setShowTable,
  setUpFee,
  currencySuffix,
  loanCalculatorSummaryTablePageDescription,
  loanCalculatorEffectiveInterest,
  loanCalculatorSummaryTableColumnMonth,
  loanCalculatorSummaryTableColumnDebtBeforeAmortization,
  loanCalculatorSummaryTableColumnAmortization,
  loanCalculatorSummaryTableColumnInterestCost,
  loanCalculatorSummaryTableColumnFeeCost,
  loanCalculatorSummaryTableColumnToPay,
  loanCalculatorTotalCostsForLoan,
}) => {
  const headers = [
    loanCalculatorSummaryTableColumnMonth,
    loanCalculatorSummaryTableColumnDebtBeforeAmortization,
    loanCalculatorSummaryTableColumnAmortization,
    loanCalculatorSummaryTableColumnInterestCost,
    loanCalculatorSummaryTableColumnFeeCost,
    loanCalculatorSummaryTableColumnToPay,
  ];

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => { document.body.style.overflow = 'unset'; };
  }, []);

  return (
    <Table>
      <TableHeader>
        <TableIntro>
          <span>{loanCalculatorSummaryTablePageDescription}</span>
          <CloseButton onClick={() => setShowTable(false)}>X</CloseButton>
        </TableIntro>
        <TableSecondIntro>
          <TableResultContainer>
            <span>{loanCalculatorEffectiveInterest}</span>
            <ResultSpan>
              {effectiveInterest}
              %
            </ResultSpan>
          </TableResultContainer>
          <TableResultContainer>
            <span>{loanCalculatorTotalCostsForLoan}</span>
            <ResultSpan>
              {addThousandSeparator(totalCostsForLoan)}
              {' '}
              {currencySuffix}
            </ResultSpan>
          </TableResultContainer>
        </TableSecondIntro>
      </TableHeader>
      <TableContent>
        <TableTbody>
          <TableTr>
            {
              headers.map((header, i) => <TableTh key={i}>{header}</TableTh>)
            }
          </TableTr>
          {setUpFee > 0 && (
            <TableTr key={0}>
              <TableTd>0</TableTd>
              <TableTd>0</TableTd>
              <TableTd>0</TableTd>
              <TableTd>0</TableTd>
              <TableTd>{setUpFee}</TableTd>
              <TableTd>{setUpFee}</TableTd>
            </TableTr>
          )}
          {
            monthlyPayments.map((month, i) => (
              <TableTr key={i + 1}>
                <TableTd>{i + 1}</TableTd>
                <TableTd>{addThousandSeparator(Math.round(month.debtBeforeAmortization))}</TableTd>
                <TableTd>{addThousandSeparator(Math.round(month.amortization))}</TableTd>
                <TableTd>{addThousandSeparator(Math.round(month.interestCost))}</TableTd>
                <TableTd>{addThousandSeparator(month.fees)}</TableTd>
                <TableTd>{addThousandSeparator(Math.round(month.amortization + month.interestCost + month.fees))}</TableTd>
              </TableTr>
            ))
          }
        </TableTbody>
      </TableContent>
    </Table>
  );
};

SummaryTable.propTypes = {
  effectiveInterest: PropTypes.string,
  monthlyPayments: PropTypes.arrayOf(PropTypes.shape()),
  totalCostsForLoan: PropTypes.string,
  setShowTable: PropTypes.func,
  setUpFee: PropTypes.number,
  currencySuffix: PropTypes.string,
  loanCalculatorSummaryTablePageDescription: PropTypes.string,
  loanCalculatorEffectiveInterest: PropTypes.string,
  loanCalculatorSummaryTableColumnMonth: PropTypes.string,
  loanCalculatorSummaryTableColumnDebtBeforeAmortization: PropTypes.string,
  loanCalculatorSummaryTableColumnAmortization: PropTypes.string,
  loanCalculatorSummaryTableColumnInterestCost: PropTypes.string,
  loanCalculatorSummaryTableColumnFeeCost: PropTypes.string,
  loanCalculatorSummaryTableColumnToPay: PropTypes.string,
  loanCalculatorTotalCostsForLoan: PropTypes.string,
};

SummaryTable.defaultProps = {
  effectiveInterest: '',
  monthlyPayments: [],
  totalCostsForLoan: '',
  setShowTable: () => { },
  setUpFee: 0,
  currencySuffix: '',
  loanCalculatorSummaryTablePageDescription: '',
  loanCalculatorEffectiveInterest: '',
  loanCalculatorSummaryTableColumnMonth: '',
  loanCalculatorSummaryTableColumnDebtBeforeAmortization: '',
  loanCalculatorSummaryTableColumnAmortization: '',
  loanCalculatorSummaryTableColumnInterestCost: '',
  loanCalculatorSummaryTableColumnFeeCost: '',
  loanCalculatorSummaryTableColumnToPay: '',
  loanCalculatorTotalCostsForLoan: '',
};

export default SummaryTable;
